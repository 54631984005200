import React from 'react';
import { Button, Checkbox, Grid, Responsive } from 'semantic-ui-react';
import { NewUser } from '../../models/newUser';
import RegisterProfileHeader from '../register-profile-header/RegisterProfileHeader';
import RegisterCertifications from './RegisterCertifications';
import RegisterDetails from './RegisterDetails';
import RegisterLicenses from './RegisterLicenses';
import RegisterResumes from './RegisterResumes';
import RegisterSkills from './RegisterSkills';

interface Props {
  errors: string[];
  progress: number;
  step: number;
  registerUser: () => void;
  previousStep: () => void;
  changeProgressValue: (value: number) => void;
  newUser: NewUser;
}

interface State {
  submitRef: any;
  infoAccurate: boolean;
}

class RegisterProfile extends React.Component<Props, State> {
  public state = {
    submitRef: '',
    infoAccurate: false,
  };

  public handleNextButton = () => {
    this.state.submitRef.current.ref.current.click();
  };

  public getSubmitRef = (ref: any) => {
    this.setState({ submitRef: ref });
  };

  public handleCheckbox = (e: any) => {
    this.setState({ infoAccurate: !this.state.infoAccurate });
  };

  public handlePrevious = () => {
    this.setState({ infoAccurate: false });
    this.props.previousStep();
  };

  public componentDidUpdate = (prevProps: Props) => {
    if (prevProps.step !== this.props.step) {
      if (prevProps.step > this.props.step) {
        const obj = {
          3: () => this.props.changeProgressValue(5),
          4: () => this.props.changeProgressValue(this.props.progress - 11),
          5: () => {
            if (this.props.newUser.licenses.length !== 0) {
              this.props.changeProgressValue(this.props.progress - 11);
            }
          },
          6: () => {
            if (this.props.newUser.certifications.length !== 0) {
              this.props.changeProgressValue(this.props.progress - 10);
            }
          },
        };
        obj[this.props.step]();
      }
    }
  };

  public showStep = () => {
    const obj = {
      3: () => RegisterDetails,
      4: () => RegisterSkills,
      5: () => RegisterLicenses,
      6: () => RegisterCertifications,
      7: () => RegisterResumes,
    };
    const res = obj[this.props.step];
    if (res) {
      const Component = res();
      return <Component getSubmitRef={this.getSubmitRef} />;
    }
  };

  public render() {
    return (
      <React.Fragment>
        <Grid className="register-grid">
          <Grid.Row className="header-row">
            <RegisterProfileHeader progress={this.props.progress} step={this.props.step} />
          </Grid.Row>
          <Grid.Row>{this.showStep()}</Grid.Row>
        </Grid>

        <Responsive minWidth={1080}>
          <div className="register-buttons-footer">
            <Button secondary={true} className="step-button cancel" onClick={this.handlePrevious}>
              Back
            </Button>
            {this.props.step !== 7 ? (
              <Button primary={true} className="step-button" onClick={this.handleNextButton}>
                Next
              </Button>
            ) : (
              <div>
                <Checkbox
                  label="I acknowledge that all the information provided is accurate."
                  onChange={this.handleCheckbox}
                  checked={this.state.infoAccurate}
                  style={{ marginRight: '30px' }}
                />
                <Button
                  primary={true}
                  disabled={!this.state.infoAccurate}
                  className="step-button"
                  onClick={() => {
                    this.handleNextButton();
                    this.props.registerUser();
                  }}
                >
                  Register
                </Button>
              </div>
            )}
          </div>
        </Responsive>

        <Responsive maxWidth={1079}>
          <div className="register-buttons-footer">
            {this.props.step === 7 && (
              <Checkbox
                label="I acknowledge that all the information provided is accurate."
                checked={this.state.infoAccurate}
                onChange={this.handleCheckbox}
              />
            )}

            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <Button
                secondary={true}
                className="step-button cancel"
                onClick={() => this.props.previousStep()}
              >
                Back
              </Button>
              {this.props.step !== 7 ? (
                <Button
                  primary={true}
                  className="step-button"
                  onClick={() => this.handleNextButton()}
                >
                  Next
                </Button>
              ) : (
                <Button
                  primary={true}
                  disabled={!this.state.infoAccurate}
                  className="step-button"
                  onClick={() => {
                    this.handleNextButton();
                    this.props.registerUser();
                  }}
                >
                  Register
                </Button>
              )}
            </div>
          </div>
        </Responsive>
      </React.Fragment>
    );
  }
}

export default RegisterProfile;
