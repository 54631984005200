import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Dropdown, Icon, Image, Menu, MenuItemProps, Responsive, Search } from 'semantic-ui-react';
import searchIcon from '../../assets/images/Blue/icn-search-blue.svg';
import dropdownIcon from '../../assets/images/btn-section-expand.svg';
import '../../assets/styles/Staff.css';
import { standbyActions } from '../../features/standby';

interface Props {
  getStandbyList: typeof standbyActions.getStandbyList;
  setStandbyTab: typeof standbyActions.setStandbyTab;
  setStandbySort: typeof standbyActions.setStandbySort;
  setStandbySearch: typeof standbyActions.setStandbySearch;
  editStandbyList: typeof standbyActions.editStandbyList;
  setActivePage: typeof standbyActions.setActivePage;
  activeTab: string;
  sort: string;
  search: string;
}

interface State {
  showSection: boolean;
}

const sortOptions = [
  {
    key: 'Creation Date',
    text: 'Creation Date',
    value: 'createdAt,DESC',
  },
  {
    key: 'Title Asc',
    text: 'Title',
    value: 'title,ASC',
  },
];

class StandByHeader extends React.Component<Props, State> {
  public state = {
    showSection: false,
  };

  public componentDidMount = () => {
    this.props.getStandbyList({ page: 0, pageSize: 10 });
  };

  public componentDidUpdate = (prevProps: Props) => {
    if (prevProps.activeTab !== this.props.activeTab) {
      this.props.getStandbyList({ page: 0, pageSize: 10 });
      this.setState({ showSection: false });
    }
    if (prevProps.sort !== this.props.sort) {
      this.props.getStandbyList({ page: 0, pageSize: 10 });
    }
    if (prevProps.search !== this.props.search) {
      this.props.getStandbyList({ page: 0, pageSize: 10 });
    }
  };

  public handleItemClick = (e: React.MouseEvent<HTMLAnchorElement>, { name }: MenuItemProps) => {
    this.props.setStandbyTab(name);
    this.props.setActivePage(1);
  };

  public handleSort = (e: any, data: any) => {
    this.props.setStandbySort(data.value);
    this.props.setActivePage(1);
  };

  public handleSearch = (e: any, data: any) => {
    this.props.setStandbySearch(data.value);
    this.props.setActivePage(1);
  };

  public render() {
    const { activeTab } = this.props;

    return (
      <>
        <Responsive minWidth={768}>
          <div className="adjusters-list-header sbl-details-table-header">
            <Menu text={true} className="tabs-menu">
              <Menu.Item
                name="Invited"
                active={activeTab === 'Invited'}
                onClick={this.handleItemClick}
              >
                Invited
              </Menu.Item>
              <Menu.Item
                name="Applied"
                active={activeTab === 'Applied'}
                onClick={this.handleItemClick}
              >
                Applied
              </Menu.Item>
              <Menu.Item
                name="All Active"
                active={activeTab === 'All Active'}
                onClick={this.handleItemClick}
              >
                All Active
              </Menu.Item>
              <Menu.Item
                name="Completed"
                active={activeTab === 'Completed'}
                onClick={this.handleItemClick}
              >
                Completed
              </Menu.Item>
            </Menu>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span className="sort-field">
                Sort by:{' '}
                <Dropdown
                  inline={true}
                  options={sortOptions}
                  value={this.props.sort}
                  onChange={this.handleSort}
                />
              </span>
              <Search
                input={{ iconPosition: 'left' }}
                icon={
                  <Icon>
                    <Image src={searchIcon} />
                  </Icon>
                }
                placeholder="Search..."
                onSearchChange={this.handleSearch}
                showNoResults={false}
              />
            </div>
          </div>
        </Responsive>

        <Responsive maxWidth={768} className="standby-list-mobile">
          <div
            className="active-tabs-mobile-item"
            onClick={() => this.setState({ showSection: !this.state.showSection })}
          >
            {activeTab}
            <Image src={dropdownIcon} className={this.state.showSection ? 'rotated' : ''} />
          </div>

          <div
            className={
              this.state.showSection
                ? 'register-adjuster-tabs stand-by-tabs active'
                : 'register-adjuster-tabs stand-by-tabs'
            }
          >
            <Menu text={true} className="tabs-menu" stackable={true}>
              <Menu.Item
                name="Invited"
                active={activeTab === 'Invited'}
                onClick={this.handleItemClick}
              >
                Invited
              </Menu.Item>
              <Menu.Item
                name="Applied"
                active={activeTab === 'Applied'}
                onClick={this.handleItemClick}
              >
                Applied
              </Menu.Item>
              <Menu.Item
                name="All Active"
                active={activeTab === 'All Active'}
                onClick={this.handleItemClick}
              >
                All Active
              </Menu.Item>
              <Menu.Item
                name="Completed"
                active={activeTab === 'Completed'}
                onClick={this.handleItemClick}
              >
                Completed
              </Menu.Item>
            </Menu>
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}
          >
            <span className="sort-field">
              Sort by:{' '}
              <Dropdown
                inline={true}
                options={sortOptions}
                value={this.props.sort}
                onChange={this.handleSort}
              />
            </span>
            <Search
              input={{ iconPosition: 'left' }}
              icon={
                <Icon>
                  <Image src={searchIcon} />
                </Icon>
              }
              placeholder="Search..."
              onSearchChange={this.handleSearch}
              showNoResults={false}
            />
          </div>
        </Responsive>
      </>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  activeTab: state.standby.standby.activeTab,
  sort: state.standby.standby.standbySort,
  search: state.standby.standby.standbySearch,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getStandbyList: standbyActions.getStandbyList,
      setStandbyTab: standbyActions.setStandbyTab,
      setStandbySort: standbyActions.setStandbySort,
      setStandbySearch: standbyActions.setStandbySearch,
      editStandbyList: standbyActions.editStandbyList,
      setActivePage: standbyActions.setActivePage,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StandByHeader);
