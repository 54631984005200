export const GET_STANDBY_LIST = 'standby/GET_STANDBY_LIST';
export const GET_SELECTED_ADJUSTERS = 'standby/GET_SELECTED_ADJUSTERS';

export const SUBMIT_STANDBY_LIST = 'standby/SUBMIT_STANDBY_LIST';
export const EDIT_STANDBY_LIST = 'standby/EDIT_STANDBY_LIST';

export const SET_STANDBY_TAB = 'standby/SET_STANDBY_TAB';
export const SET_STANDBY_SORT = 'standby/SET_STANDBY_SORT';
export const SET_STANDBY_SEARCH = 'standby/SET_STANDBY_SEARCH';

export const SET_ACTIVE_STANDBY = 'standby/SET_ACTIVE_STANDBY';
export const SET_ACTIVE_STANDBY_RELOADED = 'standby/SET_ACTIVE_STANDBY_RELOADED';
export const SUBMIT_ACTIVE_STANDBY_RELOADED = 'standby/SUBMIT_ACTIVE_STANDBY_RELOADED';

export const SHOW_INVITE_MESSAGE = 'standby/SHOW_INVITE_MESSAGE';
export const SHOW_INVITE_MODAL = 'standby/SHOW_INVITE_MODAL';

export const SET_SELECTED_ID = 'standby/SET_SELECTED_ID';

export const SET_ACTIVE_PAGE = 'standby/SET_ACTIVE_PAGE';

export const SET_REDIRECT_STANDBY = 'sandby/SET_REDIRECT_STANDBY';
