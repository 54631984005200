import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Image, Responsive, Segment } from 'semantic-ui-react';
import sblIcon from '../../assets/images/Other/icn-stdby-list.svg';
import services from '../../services';

interface Props {
  item: object;
  updateList: () => void;
  setActive: (id: any) => void;
}

interface State {}

class SBListItem extends React.Component<Props, State> {
  public state = {};

  public handleDetailsClick = () => {
    this.props.setActive(this.props.item.id);
    services.router.goto(`/stand-by-lists/${this.props.item.id}`);
  };

  public render() {
    const { item } = this.props;
    return (
      <>
        <Responsive minWidth={1051}>
          <Segment className="sbl-item-container stbl-adjuster-item">
            <div className="sbl-item-title-container">
              <div>
                <Image src={sblIcon} />
              </div>
              <div className="sbl-item-title">
                <a
                  style={{ fontWeight: 700, fontSize: '16px', cursor: 'pointer' }}
                  onClick={this.handleDetailsClick}
                >
                  {item.title}
                </a>
                <div style={{ fontSize: '13px' }}>{item.location ? item.location : ''}</div>
              </div>
            </div>

            <div className="sbl-item-info-container">
              <div className="sbl-item-title">
                <div style={{ fontWeight: 700, fontSize: '16px', alignSelf: 'flex-start' }}>
                  {item.appliedAt
                    ? moment(item.appliedAt).format('MM/DD/YYYY, hh:mm A ')
                    : 'No Application'}
                </div>
                <div style={{ fontSize: '13px', textAlign: 'right' }}>
                  {item.appliedAt ? 'Applied' : ' '}
                </div>
              </div>
              <div className="sbl-item-dates-section">
                {item.closedAt ? (
                  <div>Closed On: {moment(item.closedAt).format('MM/DD/YYYY, hh:mm A ')}</div>
                ) : (
                  <div>Expires On: {moment(item.expiresAt).format('MM/DD/YYYY')}</div>
                )}
                <div>Created On: {moment(item.createdAt).format('MM/DD/YYYY, hh:mm A ')}</div>
              </div>
              <Button
                primary={true}
                className="adjuster-standby-item-button"
                onClick={this.handleDetailsClick}
              >
                {item.appliedAt || (item.closedAt !== '' && item.closedAt)
                  ? 'View'
                  : 'Submit answers'}
              </Button>
            </div>
          </Segment>
        </Responsive>

        <Responsive maxWidth={1050}>
          <Segment className="sbl-item-container stbl-adjuster-item">
            <div>
              <Image src={sblIcon} />
            </div>
            <div className="standby-mobile-item-info">
              <div className="sbl-item-title">
                <div style={{ fontWeight: 700, fontSize: '16px' }}>{item.title}</div>
                <div style={{ fontSize: '13px' }}>
                  {item.location ? item.location : 'No Location'}
                </div>
              </div>
              <div style={{ display: 'flex', flexWrap: 'wrap' }} className="standby-dates-info">
                <div className="sbl-item-dates-section">
                  {item.closedAt ? (
                    <div>Closed On: {moment(item.closedAt).format('MM/DD/YYYY, hh:mm A ')}</div>
                  ) : (
                    <div>Expires On: {moment(item.expiresAt).format('MM/DD/YYYY')}</div>
                  )}
                  <div>Created On: {moment(item.createdAt).format('MM/DD/YYYY, hh:mm A ')}</div>
                </div>
                <div className="sbl-item-title standby-applied-block">
                  <div style={{ fontWeight: 700, fontSize: '16px', alignSelf: 'flex-start' }}>
                    {item.appliedAt
                      ? moment(item.appliedAt).format('MM/DD/YYYY, hh:mm A ')
                      : 'No Application'}
                  </div>
                  <div
                    style={{ fontSize: '13px', textAlign: 'right' }}
                    className="applied-block-info"
                  >
                    {item.appliedAt ? 'Applied' : ' '}
                  </div>
                </div>
              </div>
              <Button
                primary={true}
                className="adjuster-standby-item-button"
                onClick={this.handleDetailsClick}
              >
                {item.appliedAt || (item.closedAt !== '' && item.closedAt)
                  ? 'View'
                  : 'Submit answers'}
              </Button>
            </div>
          </Segment>
        </Responsive>
      </>
    );
  }
}

export default SBListItem;
