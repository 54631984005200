import { Form } from 'formsy-semantic-ui-react';
import MyTypes from 'MyTypes';
import React from 'react';
import { trackPromise } from 'react-promise-tracker';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Grid } from 'semantic-ui-react';
import { accountActions } from '../../features/account';
import { IUser } from '../../models';
import services from '../../services';
import * as datesHelper from '../../utils/datesHelper';
import { LoadingSpiner } from './../loading-spinner/LoadingSpinner';
import AddLicenseModal from './../register-layout/AddLicenseModal';
import EditLicenseModal from './../register-layout/EditLicenseModal';
import LicenseItem from './../register-layout/LicenseItem';

interface Props {
  checkAuthentication: typeof accountActions.checkAuthentication;
  getSubmitRef: (ref: any) => void;
  user: IUser;
  download: (file: any) => void;
}

interface State {
  certifications: object[];
  certificationId: number | undefined;
  showEditModal: boolean;
}

class ProfileCertifications extends React.Component<Props, State> {
  public state = {
    certifications: [],
    certificationId: 0,
    showEditModal: false,
  };

  public sumbitRef = React.createRef();

  public getCertifications = async (item: object, id?: number) => {
    item.issuedDate = datesHelper.parseDateToDateStringWithFormat(item.issuedDate);
    item.expirationDate = datesHelper.parseDateToDateStringWithFormat(item.expirationDate);

    if (item.file.fileType === '') {
      const addCertification = await trackPromise(
        services.api.user.addCertification({
          type: item.type,
          certification: item.certification,
          issuedDate: item.issuedDate,
          expirationDate: item.expirationDate,
        }),
        'certificates-area'
      );
      if (addCertification.isSuccess) {
        this.showEditCertification(false);
      } else {
        console.error(addCertification);
      }
    } else {
      const resp = await services.api.account.getSignedUrl({
        name: item.file.fileName,
        type: item.file.fileType,
        size: item.file.fileSize,
        public: true,
      });
      if (resp.isSuccess) {
        const data = resp.data.url.fields;
        const obj = Object.assign({}, data, {
          file: item.file.fileInitial,
        });
        const formData = new FormData();
        for (const key in obj) {
          if (undefined === obj[key]) {
            continue;
          }
          formData.append(key, obj[key]);
        }
        const postFile = await trackPromise(
          services.api.file.postFile(resp.data.url.url, formData),
          'certificates-area'
        );
        if (postFile.status === 204) {
          await services.api.user.addCertification({
            type: item.type,
            certification: item.certification,
            issuedDate: item.issuedDate,
            expirationDate: item.expirationDate,
            fileObjectKey: resp.data.url.fields.key,
          });
        } else {
          console.error(postFile);
        }
      } else {
        console.error(resp);
      }
    }

    this.listCertifications();
  };

  public listCertifications = async () => {
    this.props.checkAuthentication();
    const response = await trackPromise(
      services.api.user.listUserCertifications(),
      'certificates-area'
    );
    if (response.isSuccess) {
      for (const item of response.data) {
        if (item.file) {
          const avatarUrl = await services.api.file.getDownloadFileUrl(item.file.key);
          item.file.file = avatarUrl;
        }
      }
      this.setState({
        certifications: response.data,
      });
    } else {
      console.log(response);
    }
  };

  public deleteLicense = async (id: number) => {
    await services.api.user.removeCertification(id);
    const { certifications } = this.state;
    const index = certifications.findIndex((el: any) => el.id === id);
    certifications.splice(index, 1);
    this.setState({ certifications }, this.listCertifications);
  };

  public editCertification = async (id: any, item: any) => {
    if (item.file.fileType === '') {
      const updateCertifications = await trackPromise(
        services.api.user.updateCertifications(id, {
          type: item.type,
          certification: item.certification,
          issuedDate: item.issuedDate,
          expirationDate: item.expirationDate,
        }),
        'certificates-area'
      );

      if (updateCertifications.isSuccess) {
        this.showEditCertification(false);
      }
    } else if (item.file.fileKey && !item.file.fileInitial) {
      const updateCertifications = await trackPromise(
        services.api.user.updateCertifications(id, {
          type: item.type,
          certification: item.certification,
          issuedDate: item.issuedDate,
          expirationDate: item.expirationDate,
          fileObjectKey: item.file.fileKey,
        }),
        'certificates-area'
      );
      if (updateCertifications.isSuccess) {
        this.showEditCertification(false);
      }
    } else if (item.file.fileName && item.file.fileType && item.file.fileSize) {
      const resp = await trackPromise(
        services.api.account.getSignedUrl({
          name: item.file.fileName,
          type: item.file.fileType,
          size: item.file.fileSize,
          public: true,
        }),
        'certificates-area'
      );

      if (resp.isSuccess) {
        const data = resp.data.url.fields;
        const obj = Object.assign({}, data, {
          file: item.file.fileInitial,
        });
        const formData = new FormData();
        for (const key in obj) {
          if (undefined === obj[key]) {
            continue;
          }
          formData.append(key, obj[key]);
        }
        const postFile = await trackPromise(
          services.api.file.postFile(resp.data.url.url, formData),
          'certificates-area'
        );

        if (postFile.status === 204) {
          const updateCertifications = await services.api.user.updateCertifications(id, {
            type: item.type,
            certification: item.certification,
            issuedDate: item.issuedDate,
            expirationDate: item.expirationDate,
            fileObjectKey: resp.data.url.fields.key,
          });
          if (updateCertifications.isSuccess) {
            this.showEditCertification(false);
          }
        }
      }
    }

    this.listCertifications();
  };

  public showEditCertification = (show: boolean, id?: any) => {
    id !== null
      ? this.setState({ showEditModal: show, certificationId: id })
      : this.setState({ showEditModal: show });
  };

  public componentDidMount = async () => {
    this.listCertifications();
  };

  public render() {
    return (
      <>
        <Grid stackable={true} style={{ width: '100%' }} className="licenses-grid">
          <LoadingSpiner area="certificates-area" />
          <Grid.Row columns={1} only="computer">
            <Grid.Column className="licenses-column">
              <Form>
                {this.state.certifications.length === 0 ? (
                  <Form.Input
                    name="certifications"
                    placeholder="Nothing Added"
                    instantValidation={false}
                    className="form-input readonly-input"
                    readOnly={true}
                  />
                ) : (
                  <div>
                    {this.state.certifications.map((el: any, index: number) => (
                      <LicenseItem
                        item={el}
                        key={index}
                        id={index}
                        editLicense={this.showEditCertification}
                        deleteLicense={this.deleteLicense}
                        mode="cert"
                        download={this.props.download}
                      />
                    ))}
                  </div>
                )}
                <Form.Input
                  name="license_file"
                  hidden={true}
                  style={{ display: 'none' }}
                  readOnly={true}
                />
                <Button ref={this.sumbitRef} type="submit" style={{ display: 'none' }} />
              </Form>
              <div style={{ width: '100%' }}>
                <AddLicenseModal getLicense={this.getCertifications} mode="cert" />
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={16} only="tablet mobile">
            <Grid.Column className="licenses-column">
              <Form>
                {this.state.certifications.length === 0 ? (
                  <Form.Input
                    name="certifications"
                    placeholder="Nothing Added"
                    instantValidation={false}
                    className="form-input readonly-input"
                    readOnly={true}
                  />
                ) : (
                  <div>
                    {this.state.certifications.map((el: any, index: number) => (
                      <LicenseItem
                        item={el}
                        key={index}
                        id={index}
                        editLicense={this.showEditCertification}
                        deleteLicense={this.deleteLicense}
                        mode="cert"
                        download={this.props.download}
                      />
                    ))}
                  </div>
                )}
                <Form.Input
                  name="license_file"
                  hidden={true}
                  style={{ display: 'none' }}
                  readOnly={true}
                />
                <Button ref={this.sumbitRef} type="submit" style={{ display: 'none' }} />
              </Form>
              <div style={{ width: '100%' }}>
                <AddLicenseModal getLicense={this.getCertifications} mode="cert" />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <EditLicenseModal
          show={this.state.showEditModal}
          editLicense={this.editCertification}
          showModal={this.showEditCertification}
          item={this.state.certifications[this.state.certificationId]}
          mode="cert"
        />
      </>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  newUser: state.account.register.newUser,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      checkAuthentication: accountActions.checkAuthentication,
      changeRegisterStore: accountActions.changeRegisterStore,
      changeProgressValue: accountActions.changeProgressValue,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileCertifications);
