import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Grid, Responsive } from 'semantic-ui-react';
import { accountActions } from '../../features/account';
import { NewUser } from '../../models/newUser';
import AvatarInput from '../avatar-input/AvatarInput';
import RegisterDetailsForm from '../register-form/RegisterDetailsForm';

interface Props {
  changeRegisterStore: typeof accountActions.changeRegisterStore;
  changeProgressValue: typeof accountActions.changeProgressValue;
  getSubmitRef: (ref: any) => void;
  errors: string[];
  newUser: NewUser;
  progress: any;
}

interface State {
  avatar: string | object;
  fieldsUrl: string;
  formData: FormData | {};
  avatarEdited: boolean;
}

class RegisterDetails extends React.Component<Props, State> {
  public state = {
    avatar: '',
    fieldsUrl: '',
    formData: {},
    avatarEdited: false,
  };

  public getAvatar = (file: any, avatarEdited: boolean) => {
    this.setState({ avatar: file });
  };

  public getAvatatFields = (url: string, data: FormData | {}) => {
    this.setState({ fieldsUrl: url, formData: data });
  };

  public componentDidMount() {
    this.setState({
      avatar: this.props.newUser.avatar ? this.props.newUser.avatar : '',
    });
  }

  public render() {
    return (
      <>
        <Responsive minWidth={769} style={{ width: '100%' }}>
          <Grid stackable={true} columns={2} className="register-details-grid">
            <Grid.Column width={2} id="avatar-input-column">
              <AvatarInput
                registerPage={true}
                getAvatar={this.getAvatar}
                getAvatarFields={this.getAvatatFields}
                noEdit={false}
                hasToken={false}
                avatar={
                  this.props.newUser.avatar && this.props.newUser.avatar.file
                    ? this.props.newUser.avatar.file.file
                    : this.props.newUser.avatar
                    ? this.props.newUser.avatar
                    : ''
                }
              />
            </Grid.Column>
            <Grid.Column width={14} id="content-register-input-column">
              <RegisterDetailsForm
                getSubmitRef={this.props.getSubmitRef}
                newUser={this.props.newUser}
                avatar={this.state.avatar}
                changeRegisterStore={this.props.changeRegisterStore}
                changeProgressValue={this.props.changeProgressValue}
              />
            </Grid.Column>
          </Grid>
        </Responsive>

        <Responsive maxWidth={768}>
          <Grid stackable={true} className="register-details-grid">
            <Grid.Column width={16} only="tablet mobile">
              <AvatarInput
                registerPage={true}
                getAvatar={this.getAvatar}
                getAvatarFields={this.getAvatatFields}
                noEdit={false}
                hasToken={false}
                avatar={
                  this.props.newUser.avatar && this.props.newUser.avatar.file
                    ? this.props.newUser.avatar.file.file
                    : this.props.newUser.avatar
                    ? this.props.newUser.avatar
                    : ''
                }
              />
            </Grid.Column>
            <Grid.Column width={16} only="tablet mobile">
              <RegisterDetailsForm
                getSubmitRef={this.props.getSubmitRef}
                newUser={this.props.newUser}
                avatar={this.state.avatar}
                changeRegisterStore={this.props.changeRegisterStore}
                changeProgressValue={this.props.changeProgressValue}
                progress={this.props.progress}
              />
            </Grid.Column>
          </Grid>
        </Responsive>
      </>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  errors: state.account.register.errors,
  isLoading: state.loading.loading.isVisible,
  step: state.account.register.step,
  newUser: state.account.register.newUser,
  progress: state.account.register.progress,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      changeRegisterStore: accountActions.changeRegisterStore,
      changeProgressValue: accountActions.changeProgressValue,
      nextStep: accountActions.registerNextStep,
      prevStep: accountActions.registerPrevStep,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterDetails);
