import React from 'react';
import { Button, Image, Popup } from 'semantic-ui-react';

const CustomIconicButton = (props: any) => {
  const { onPress, popup, image } = props;
  return (
    <Popup
      trigger={
        <Button type="button" className="iconic-button-custom" onClick={onPress}>
          <Image src={image} />
        </Button>
      }
      basic={true}
      size="mini"
      content={popup}
    />
  );
};

export default CustomIconicButton;
