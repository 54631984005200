import React from 'react';
import { Button, Dropdown, Grid, Image, Popup, Responsive } from 'semantic-ui-react';
import logo from '../../assets/images/compass-logo-vector.svg';
import noAvatar from '../../assets/images/no-avatar.svg';
import arrowIcon from '../../assets/images/White/arrow-l.svg';
import '../../assets/styles/Header.css';
import { IUser } from '../../models';
import services from '../../services';
import RegisterProgress from '../register-profile-header/RegisterProgress';

const userPicStyle = {
  // overflow: 'hidden',
};

interface Props {
  user: IUser | undefined;
  logout: () => void;
  pathname: string;
}

interface State {
  headerTitle: string;
}

class AccountLayoutHeader extends React.Component<Props, State> {
  public state = {
    headerTitle: '',
  };

  public chooseTitle = (location: string) => {
    switch (location) {
      case '/':
        return 'Adjuster Details';
      case '/stand-by-lists':
        return 'Standby Lists';
      case '/stand-by-lists/:id':
        return 'Standby List Details';
      case '/training-center':
        return 'Training Center';
      default:
        return '';
    }
  };

  public componentDidMount = () => {
    this.setState({ headerTitle: this.chooseTitle(this.props.pathname) });
    // this.getScore()
  };

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.pathname !== this.props.pathname) {
      this.setState({ headerTitle: this.chooseTitle(this.props.pathname) });
    }
  }

  public render() {
    const { user } = this.props;
    const trigger = (
      <div style={userPicStyle}>
        <Image
          circular={true}
          src={!user || user.avatar === '' ? noAvatar : user.avatar}
          className="header-user-avatar"
        />
      </div>
    );

    return (
      <>
        <Responsive minWidth={769}>
          <div>
            <div className="account-header">
              <Grid style={{ width: '100%' }}>
                <Grid.Row style={{ margin: '0 8px' }}>
                  <Grid.Column className="account-header-logo">
                    <div onClick={() => services.router.goto('/')}>
                      <Image style={{ height: '87px' }} src={logo} />
                    </div>
                  </Grid.Column>
                  <Grid.Column className="account-header-title-container">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {this.props.pathname === '/stand-by-lists/:id' && (
                        <Popup
                          trigger={
                            <Button
                              primary={true}
                              onClick={() => services.router.goto('/stand-by-lists')}
                              className="back-button"
                            >
                              <Image src={arrowIcon} />
                            </Button>
                          }
                          basic={true}
                          size="mini"
                          content="Return"
                        />
                      )}
                      <div className="account-header-title" id="account-header-title">{this.state.headerTitle}</div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '220px' }}>
                      {this.props.pathname !== '/stand-by-lists' && (
                        <RegisterProgress
                          progress={user ? Number(user.profileScore).toFixed() : 0}
                        />
                      )}
                      <Dropdown trigger={trigger} icon={false} className="profile-dropdown">
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => this.props.logout()}>
                            <span>Logout</span>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </div>
        </Responsive>

        <Responsive maxWidth={768}>
          <div>
            <div className="account-header">
              <Grid style={{ width: '100%' }}>
                <Grid.Row style={{ margin: '0 8px' }}>
                  <Grid.Column className="account-header-logo">
                    <div onClick={() => services.router.goto('/')}>
                      <Image style={{ height: '45px' }} src={logo} />
                    </div>
                  </Grid.Column>
                  <Grid.Column className="account-header-title-container">
                    <Dropdown trigger={trigger} icon={false} className="profile-dropdown">
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => this.props.logout()}>
                          <span>Logout</span>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </div>
        </Responsive>
      </>
    );
  }
}

export default AccountLayoutHeader;
