import { request } from './base';

export const login = (email: string, password: string, redirect: { url: string, token: string }) => {
  return request({
    method: 'post',
    url: 'account/adjuster/login',
    data: {
      email,
      password,
      redirect,
    },
  });
};

export const register = (model: any) => {
  return request({
    method: 'post',
    url: 'account/adjuster/register',
    data: {
      ...model,
    },
  });
};

export const recover = (email: string) => {
  return request({
    method: 'post',
    url: 'account/adjuster/forgot-password',
    data: {
      email,
    },
  });
};

export const reset = (model: {}) => {
  return request({
    method: 'post',
    url: 'account/adjuster/reset-password',
    data: {
      ...model,
    },
  });
};

export const checkEmail = () => {
  return request({
    method: 'get',
    url: 'user/list',
  });
};

export const emailVerificationConfirm = (userId: string, verificationToken: string) => {
  return request({
    method: 'post',
    url: 'account/adjuster/email-address-verify',
    data: {
      userId,
      verificationToken,
    },
  });
};

export const getSignedUrl = (data: object, token?: string) => {
  return request({
    method: 'post',
    headers: {
      Authorization: `JWT ${token}`,
    },
    url: '/file',
    data,
  });
};

export const getSettingsData = (type: string) => {
  return request({
    method: 'get',
    url: `/public/ability/${type}?noPagination=1`,
  });
};

export const downloadFile = (key: string) => {
  return request({
    method: 'post',
    url: `file/download`,
    data: {
      fileObjectKey: key,
    },
  });
};

export const phoneVerificationRequest = (phone: any) => {
  return request({
    method: 'post',
    url: 'adjuster/me/phone-verification-request',
    data: {
      phone,
    },
  });
};

export const verifyPhone = (verificationCode: any) => {
  return request({
    method: 'post',
    url: 'adjuster/me/verify-phone',
    data: {
      verificationCode,
    },
  });
};

export const checkEmailIsValidForRegistration = (email: string) => {
  return request({
    method: 'GET',
    url: 'account/email-check',
    params: {
      email,
    },
  });
};

export const sendActivationMail = (email: string) => {
  return request({
    method: 'POST',
    url: '/account/adjuster/resend-email-verification',
    data: {
      email,
    },
  });
};

export const sendActivationMailWithToken = (token: string) => {
  return request({
    method: 'POST',
    url: '/adjuster/me/email-verification-request',
    headers: {
      Authorization: `JWT ${token}`,
    },
  });
};

export const standbyListQuickSignUp = (model: any) => {
  return request({
    method: 'POST',
    url: 'account/standbylist/register',
    data: {
      ...model
    }
  });
};
