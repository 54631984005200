import React from 'react';
import { Button, Image, Menu, Responsive } from 'semantic-ui-react';
import dropdownIcon from '../../assets/images/btn-section-expand.svg';

interface Props {
  getTabName: (name: string) => void;
  submitRef: any;
  activeTab: string;
}

interface State {
  activeTab: string;
  showSection: boolean;
}

class SettingsHeader extends React.Component<Props, State> {
  public state = {
    activeTab: '',
    showSection: false,
  };

  public componentDidMount = () => {
    this.setState({ activeTab: this.props.activeTab });
  };

  public handleItemClick = (e: any, { name }: any) => {
    this.props.getTabName(name);
    this.setState({ activeTab: name, showSection: false });
  };

  public render() {
    const { activeTab } = this.props;
    return (
      <>
        <Responsive minWidth={769}>
          <div className="settings-header-container">
            <Menu text={true} className="tabs-menu" style={{ margin: 0, width: '100%' }}>
              <Menu.Item
                name="Profile"
                active={activeTab === 'Profile'}
                onClick={this.handleItemClick}
              >
                Profile
              </Menu.Item>
              <Menu.Item
                name="Professional Skills"
                active={activeTab === 'Professional Skills'}
                onClick={this.handleItemClick}
              >
                Professional Skills
              </Menu.Item>
              <Menu.Item
                name="Licenses"
                active={activeTab === 'Licenses'}
                onClick={this.handleItemClick}
              >
                Licenses
              </Menu.Item>
              <Menu.Item
                name="Certifications"
                active={activeTab === 'Certifications'}
                onClick={this.handleItemClick}
              >
                Certifications
              </Menu.Item>
              <Menu.Item
                name="Resumes"
                active={activeTab === 'Resumes'}
                onClick={this.handleItemClick}
              >
                Resumes
              </Menu.Item>
              <Menu.Item
                name="Paperwork"
                active={activeTab === 'Paperwork'}
                onClick={this.handleItemClick}
              >
                Paperwork
              </Menu.Item>
              <Menu.Item
                name="Finances"
                active={activeTab === 'Finances'}
                onClick={this.handleItemClick}
              >
                Finances
              </Menu.Item>
              {(activeTab === 'Profile' || activeTab === 'Professional Skills') && (
                <Menu.Item className="add-admin-button-container">
                  <Button
                    className="add-admin-button"
                    onClick={() => {
                      this.props.submitRef.current.ref.current.click();
                    }}
                  >
                    Update
                  </Button>
                </Menu.Item>
              )}
            </Menu>
          </div>
        </Responsive>

        <Responsive maxWidth={768}>
          <div
            className="active-tabs-mobile-item"
            onClick={() => this.setState({ showSection: !this.state.showSection })}
          >
            {activeTab}
            <Image src={dropdownIcon} className={this.state.showSection ? 'rotated' : ''} />
          </div>

          <div
            className={
              this.state.showSection ? 'register-adjuster-tabs active' : 'register-adjuster-tabs'
            }
          >
            <Menu text={true} className="tabs-menu" stackable={true}>
              <Menu.Item
                name="Profile"
                active={activeTab === 'Profile'}
                onClick={this.handleItemClick}
              >
                Profile
              </Menu.Item>
              <Menu.Item
                name="Professional Skills"
                active={activeTab === 'Professional Skills'}
                onClick={this.handleItemClick}
              >
                Professional Skills
              </Menu.Item>
              <Menu.Item
                name="Licenses"
                active={activeTab === 'Licenses'}
                onClick={this.handleItemClick}
              >
                Licenses
              </Menu.Item>
              <Menu.Item
                name="Certifications"
                active={activeTab === 'Certifications'}
                onClick={this.handleItemClick}
              >
                Certifications
              </Menu.Item>
              <Menu.Item
                name="Resumes"
                active={activeTab === 'Resumes'}
                onClick={this.handleItemClick}
              >
                Resumes
              </Menu.Item>
              <Menu.Item
                name="Paperwork"
                active={activeTab === 'Paperwork'}
                onClick={this.handleItemClick}
              >
                Paperwork
              </Menu.Item>
              <Menu.Item
                name="Finances"
                active={activeTab === 'Finances'}
                onClick={this.handleItemClick}
              >
                Finances
              </Menu.Item>
            </Menu>
          </div>
          {(activeTab === 'Profile' || activeTab === 'Professional Skills') && (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                className="add-admin-button"
                onClick={() => this.props.submitRef.current.ref.current.click()}
              >
                Update
              </Button>
            </div>
          )}
        </Responsive>
      </>
    );
  }
}

export default SettingsHeader;
