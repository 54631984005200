import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Dropdown, Icon, Image, Menu, Popup, Search } from 'semantic-ui-react';
import { trainingCenterActions } from '../../../features/training-center';
import { IUser } from '../../../models';
import downloadIcon from '../../../assets/images/White/download.svg';
import './MaterialActionMenu.css';
import checkIconW from '../../../assets/images/White/check.svg';
import checkIconB from '../../../assets/images/Blue/check.svg';
import { MaterialModel, PaginationInfo } from '../../../features/training-center/reducer';
import services from '../../../services';
import { trackPromise } from 'react-promise-tracker';
import { debounce } from 'lodash';
import searchIcon from '../../../assets/images/Blue/icn-search-blue.svg';

interface Props {
  user: IUser;
  pagingInfo: PaginationInfo;
  selectedMaterial: number[];
  allMaterials: MaterialModel[];
  setSelectedMaterial: typeof trainingCenterActions.setSelectedMaterial;
  removeSelectedMaterials: typeof trainingCenterActions.removeSelectedMaterials;
  searchMaterials: typeof trainingCenterActions.searchMaterial;
}

interface State {
  filtersOpen: boolean;
  filters: { sort: string; fileType: string; search: string };
}

class MaterialActionMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      filtersOpen: false,
      filters: {
        sort: 'createdAt,DESC',
        fileType: 'all',
        search: '',
      },
    };

    this.onSearchChange = debounce(this.onSearchChange, 350);
  }

  public isSelectAll() {
    const { allMaterials, selectedMaterial } = this.props;
    if (
      selectedMaterial.length === 0 ||
      allMaterials.length === 0 ||
      selectedMaterial.length !== allMaterials.length
    ) {
      return false;
    }

    for (const selectedId of selectedMaterial) {
      const foundRecord = allMaterials.find(t => t.id === selectedId);
      if (!foundRecord) {
        return false;
      }
    }
    return true;
  }

  public selectAll = async () => {
    if (!this.isSelectAll()) {
      this.props.setSelectedMaterial(this.props.allMaterials.map(t => t.id));
      return;
    }
    this.props.removeSelectedMaterials(this.props.allMaterials.map(t => t.id));
  };

  public toggleFilters = () => {
    this.setState({ filtersOpen: !this.state.filtersOpen });
  };

  public handleSortChange = (e: any, data: any) => {
    this.setState(
      {
        filters: { ...this.state.filters, sort: data.value },
      },
      () => {
        this.props.searchMaterials({
          ...this.props.pagingInfo,
          filter: {
            ...this.props.pagingInfo.filter,
            sort: data.value,
          },
        });
      }
    );
  };

  public handleFileTypeChange = (e: any, data: any) => {
    this.setState(
      {
        filters: { ...this.state.filters, fileType: data.value },
      },
      () => {
        this.props.searchMaterials({
          ...this.props.pagingInfo,
          activePage: 1,
          filter: {
            ...this.props.pagingInfo.filter,
            fileType: data.value,
          },
        });
      }
    );
  };

  public handleSearchChange = (e: any, data: any) => {
    this.setState(
      {
        filters: { ...this.state.filters, search: data.value },
      },
      () => this.onSearchChange(data.value)
    );
  };

  public onSearchChange = (search: string) => {
    this.props.searchMaterials({
      ...this.props.pagingInfo,
      activePage: 1,
      filter: {
        ...this.props.pagingInfo.filter,
        search,
      },
    });
  };

  public renderBulkActionMenu() {
    const { allMaterials, selectedMaterial } = this.props;
    const isAllSelected = this.isSelectAll();
    const checkboxIcon = isAllSelected ? checkIconW : checkIconB;
    return (
      <Menu style={{ margin: '0em -0.5em' }} text={true} className="tabs-menu">
        <Menu.Item>
          <Popup
            trigger={
              <Button
                type="button"
                className={
                  isAllSelected
                    ? 'check-button active iconic-button-custom'
                    : 'check-button iconic-button-custom'
                }
                onClick={this.selectAll}
              >
                <Image src={checkboxIcon} disabled={allMaterials.length === 0} />
              </Button>
            }
            basic={true}
            size="mini"
            content={isAllSelected ? 'Deselect All' : 'Select All'}
          />
        </Menu.Item>
        <Menu.Item>
          <Popup
            trigger={
              <Button
                primary={true}
                disabled={selectedMaterial.length === 0}
                className="material-action-button"
                onClick={() => this.bulkDownloadMaterial(this.props.selectedMaterial)}
              >
                <Image src={downloadIcon} />
              </Button>
            }
            basic={true}
            size="mini"
            content={'Download files'}
          />
        </Menu.Item>
      </Menu>
    );
  }

  public renderFilters() {
    return (
      <div className="material-actions-header__filters">
        <div className="filter">
          <Dropdown
            inline={true}
            selection={true}
            options={[
              {
                key: 'Creation Date',
                text: 'Creation Date',
                value: 'createdAt,DESC',
              },
              {
                key: 'Last Updated Date',
                text: 'Last Updated Date',
                value: 'updatedAt,DESC',
              },
            ]}
            value={this.state.filters.sort}
            onChange={this.handleSortChange}
          />
        </div>
        <div className="filter">
          <Dropdown
            inline={true}
            selection={true}
            options={[
              {
                key: 'All File Types',
                text: 'All File Types',
                value: 'all',
              },
              {
                key: 'Document',
                text: 'Document',
                value: 'docx',
              },
              {
                key: 'PDF',
                text: 'PDF',
                value: 'pdf',
              },
              {
                key: 'Excel',
                text: 'Excel',
                value: 'xlsx',
              },
              {
                key: 'PowerPoint',
                text: 'PowerPoint',
                value: 'pptx',
              },
              {
                key: 'Video',
                text: 'Video',
                value: 'video',
              },
              {
                key: 'Image',
                text: 'Image',
                value: 'image',
              },
            ]}
            value={this.state.filters.fileType}
            onChange={this.handleFileTypeChange}
          />
        </div>
        <div className="filter">
          <Search
            input={{ iconPosition: 'left' }}
            icon={
              <Icon>
                <Image src={searchIcon} />
              </Icon>
            }
            placeholder="Search..."
            onSearchChange={this.handleSearchChange}
            value={this.state.filters.search}
            showNoResults={false}
          />
        </div>
      </div>
    );
  }

  public async bulkDownloadMaterial(ids: number[]) {
    let fileKey: any = '';
    let downloadUrl;
    for (const material of this.props.allMaterials) {
      if (!material.fileKey) {
        continue;
      }
      ids
        ? ids.map(async id => {
            if (id === material.id) {
              fileKey = material.fileKey;

              downloadUrl = await trackPromise(
                services.api.file.getDownloadFileUrl(fileKey),
                'material-download'
              );
              if (downloadUrl) {
                services.api.file.downloadFile(downloadUrl, material.fileName, material.name);
              }
            }
          })
        : null;
    }
  }

  public render() {
    return (
      <React.Fragment>
        <div className="material-actions-header">
          {this.renderBulkActionMenu()}
          <div className="material-filter-upload-actions">
            <Button primary={true} onClick={this.toggleFilters}>
              Filter
            </Button>
          </div>
        </div>
        {this.state.filtersOpen && this.renderFilters()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  pagingInfo: state.trainingCenter.material.pagingInfo,
  selectedMaterial: state.trainingCenter.material.pagingInfo.selected,
  allMaterials: state.trainingCenter.material.pagingInfo.allRecords,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      searchMaterials: trainingCenterActions.searchMaterial,
      setSelectedMaterial: trainingCenterActions.setSelectedMaterial,
      removeSelectedMaterials: trainingCenterActions.removeSelectedMaterials,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MaterialActionMenu);
