import React from 'react';
import '../../assets/styles/TrainingCenter.css';
import TrainingCenter from '../../components/training-center-layout/TrainingCenter';

export default function TrainingCenterPage() {
  return (
    <div className="training-center-page">
      <TrainingCenter />
    </div>
  )
}
