import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { accountActions } from '.';
import { User } from '../../models';
import services from '../../services';
import * as datesHelper from '../../utils/datesHelper';
import * as loadingActions from '../loading/actions';
import { profileActions } from '../profile';
import { standbyActions } from '../standby';
import * as actions from './actions';
import * as constants from './constants';

export default function*() {
  yield takeEvery(constants.ADMIN_LOGIN, adminLogin);
  yield takeEvery(constants.CHECK_AUTHENTICATION, checkAuthentication);
  yield takeEvery(constants.LOGIN_ATTEMPT, loginAttempt);
  yield takeEvery(constants.SOCIAL_LOGIN_ATTEMPT, socialLoginAttempt);
  yield takeEvery(constants.LOGIN_SUCCESS, loginSuccess);
  yield takeEvery(constants.RECOVER_ATTEMPT, recoverAttempt);
  yield takeEvery(constants.RESET_PASSWORD_ATTEMPT, resetPasswordAttempt);
  yield takeEvery(constants.POST_REGISTER_FILES, postRegisterFiles);
  yield takeEvery(constants.REGISTER_ATTEMPT, registerAttempt);
  yield takeEvery(constants.CHANGE_REGISTER_STORE, changeRegisterStore);
  yield takeEvery(constants.REGISTER_SUCCESS, registerSuccess);
  yield takeEvery(constants.LOGOUT, logout);
  yield takeEvery(constants.EMAIL_VERIFICATION_ATTEMPT, emailVerificationAttempt);
  yield takeEvery(constants.PHONE_CONFIRM, phoneConfirm);
  yield takeEvery(constants.PHONE_VERIFY, phoneVerify);
  yield takeEvery(constants.DOWNLOAD_FILE, downloadFile);
  yield takeEvery(constants.DOWNLOAD_PAPERWORK, downloadPaperwork);
  yield takeEvery(constants.LOGIN_SUCCESS, loginSuccess);
  yield takeEvery(constants.STANDBY_LIST_QUICK_SIGN_UP, standbyListQuickSignUp);
}

export function* adminLogin(action: ActionType<typeof actions.adminLogin>) {
  services.localStorage.save('accessToken', action.payload.accessToken);
}

export function* checkAuthentication(action: ActionType<typeof actions.checkAuthentication>) {
  const accessToken: string = services.localStorage.get('accessToken') as string;

  if (accessToken) {
    yield put(loadingActions.show());
    services.localStorage.save('accessTokenRegistration', accessToken);
    const userRequest = yield call(services.api.user.getCurrentUser);
    const skillsRequest = yield call(services.api.user.getCurrentUserSkills);
    if (userRequest.isSuccess && skillsRequest.isSuccess) {
      const user = userRequest.data;
      if (user.avatar === '' || !user.avatar) {
        user.avatar = '';
        const obj = Object.assign({}, user, user.profile, skillsRequest.data);
        delete obj.profile;
        yield put(actions.loginSuccess(accessToken, User.create(obj)));
      } else {
        const avatarCall = yield call(services.api.account.downloadFile, user.avatar);
        if (avatarCall.isSuccess) {
          user.avatar = avatarCall.data.downloadURL;
          const obj = Object.assign({}, user, user.profile, skillsRequest.data);
          delete obj.profile;
          yield put(actions.loginSuccess(accessToken, User.create(obj)));
        } else {
          user.avatar = '';
          const obj = Object.assign({}, user, user.profile, skillsRequest.data);
          delete obj.profile;
          yield put(actions.loginSuccess(accessToken, User.create(obj)));
        }
      }
    } else if (userRequest.status === 401 || skillsRequest.status === 401) {
      const errorRequest = userRequest.status === 401 ? userRequest : skillsRequest;
      if (window.location.pathname.includes('/admin-login')) {
        if (errorRequest.data && errorRequest.data.message === 'Phone is not verified yet.') {
          yield put(loadingActions.show());
          services.localStorage.remove('accessToken');
          yield put(accountActions.checkAuthentication());
          services.router.goto(services.router.url.phoneConfirmation);
          toast.dismiss();
          toast.error(errorRequest.data ? errorRequest.data.message : 'Error 401');
          yield put(loadingActions.hide());
        } else {
          if (
            errorRequest.data &&
            errorRequest.data.message === 'This email is not activated yet.'
          ) {
            toast.dismiss();
            toast.error(
              'This email is not activated yet. Please click here to resend the activation email.',
              {
                onClick: () => {
                  try {
                    services.api.account.sendActivationMailWithToken(accessToken);
                  } catch (err) {
                    console.log(err);
                  }
                },
              }
            );
            services.localStorage.remove('accessToken');
            yield put(accountActions.checkAuthentication());
            services.router.goto(services.router.url.login);
            yield put(loadingActions.hide());
          } else {
            toast.dismiss();
            toast.error(errorRequest.data ? errorRequest.data.message : 'Error 401');
          }
        }
      } else {
        yield put(loadingActions.show());
        services.localStorage.remove('accessToken');
        errorRequest.data && errorRequest.data.message === 'Phone is not verified yet.'
          ? services.router.goto(services.router.url.phoneConfirmation)
          : services.router.goto(services.router.url.login);

        if (errorRequest.data && errorRequest.data.message === 'This email is not activated yet.') {
          toast.dismiss();
          toast.error(
            'This email is not activated yet. Please click here to resend the activation email.',
            {
              onClick: () => {
                try {
                  services.api.account.sendActivationMailWithToken(accessToken);
                } catch (err) {
                  console.log(err);
                }
              },
            }
          );
          yield put(loadingActions.hide());
        } else {
          toast.dismiss();
          toast.error(errorRequest.data ? errorRequest.data.message : 'Error 401');
        }
        // services.localStorage.remove('accessToken');
        yield put(accountActions.checkAuthentication());
        yield put(loadingActions.hide());
      }
    } else {
      if (userRequest.isServerError()) {
        location.reload();
      } else {
        yield put(actions.logout());
      }
    }
  } else {
    yield put(actions.checkAuthenticationFinished());
  }
}

export function* logout(action: ActionType<typeof actions.logout>) {
  yield put(loadingActions.show());
  services.localStorage.remove('accessToken');
  services.router.goto(services.router.url.login);
  yield put(loadingActions.hide());
}

export function* loginAttempt(action: ActionType<typeof actions.loginAttempt>) {
  const setRedrirect = yield select(state => state.standby.standby.redirectStandby);
  yield put(loadingActions.show());

  const tokenRequest = yield call(
    services.api.account.login,
    action.payload.email,
    action.payload.password,
    action.payload.redirect,
  );
  if (tokenRequest.isError) {
    if (
      tokenRequest.data.errors &&
      tokenRequest.data.errors[0].msg === 'This email is not activated yet.'
    ) {
      toast.dismiss();
      toast.error(
        'This email is not activated yet. Please click here to resend the activation email.',
        {
          onClick: () => {
            try {
              services.api.account.sendActivationMail(tokenRequest.data.errors[0].value);
            } catch (err) {
              console.log(err);
            }
          },
        }
      );
      yield put(loadingActions.hide());
    } else {
      toast.dismiss();
      toast.error(
        tokenRequest.data.errors ? tokenRequest.data.errors[0].msg : `Error ${tokenRequest.status}`
      );
      yield put(loadingActions.hide());
    }
  } else if (tokenRequest.data.token && !tokenRequest.data.phoneVerified) {
    services.localStorage.save('accessToken', tokenRequest.get('token'));
    services.localStorage.save('accessTokenRegistration', tokenRequest.get('token'));
    services.router.goto(services.router.url.phoneConfirmation);
    yield put(loadingActions.hide());
  } else {
    services.localStorage.save('accessToken', tokenRequest.get('token'));
    const userRequest = yield call(services.api.user.getCurrentUser);
    if (userRequest.isSuccess) {
      const obj = Object.assign({}, userRequest.data, userRequest.data.profile);
      delete obj.profile;
      yield put(actions.loginSuccess(tokenRequest.get('token'), User.create(obj)));
      yield put(profileActions.setActiveTab('Profile'));

      if (tokenRequest.data.redirectUrl) {
        window.location.href = tokenRequest.data.redirectUrl;
      } else {
        setRedrirect && setRedrirect.includes('/stand-by-lists/')
          ? services.router.goto(setRedrirect)
          : services.router.goto(services.router.url.homepage);
      }
    } else {
      yield put(actions.logout());
    }
  }
}

export function* recoverAttempt(action: ActionType<typeof actions.recoverAttempt>) {
  yield put(loadingActions.show());

  const tokenRequest = yield call(services.api.account.recover, action.payload.email);

  if (tokenRequest.isError) {
    toast.dismiss();
    toast.error('Error! ' + tokenRequest.data.errors[0].msg);
    yield put(loadingActions.hide());
  } else {
    toast.dismiss();
    toast.success('Password reset email was successfully sent!');
    yield put(loadingActions.hide());
  }
}

export function* resetPasswordAttempt(action: ActionType<typeof actions.resetPasswordAttempt>) {
  yield put(loadingActions.show());

  const request = yield call(services.api.account.reset, action.payload);
  if (request.isError) {
    toast.error(request.data.errors[0].msg);
    yield put(loadingActions.hide());
  } else if (!request.data.phoneVerified) {
    // services.localStorage.save('accessToken', request.data.token);
    services.localStorage.save('accessTokenRegistration', request.data.token);
    services.router.goto(services.router.url.phoneConfirmation);
    yield put(loadingActions.hide());
  } else {
    services.localStorage.save('accessToken', request.get('token'));

    const userRequest = yield call(services.api.user.getCurrentUser);

    if (userRequest.isSuccess) {
      const user = userRequest.data;
      const avatarCall = yield call(services.api.account.downloadFile, userRequest.data.avatar);

      if (avatarCall.isSuccess) {
        user.avatar = avatarCall.data.downloadURL;
        yield put(loadingActions.hide());
        yield put(actions.loginSuccess(request.get('token'), User.create(user)));
        services.router.goto(services.router.url.homepage);
      } else {
        user.avatar = '';
        yield put(loadingActions.hide());
        yield put(actions.loginSuccess(request.get('token'), User.create(user)));
        services.router.goto(services.router.url.homepage);
      }
    } else if (userRequest.status === 401) {
      yield put(loadingActions.hide());
      toast.dismiss();
      toast.error(userRequest.data.message ? userRequest.data.message : `Error 401`);
    } else {
      yield put(loadingActions.hide());
      yield put(actions.logout());
    }
  }
}

export function* socialLoginAttempt(action: ActionType<typeof actions.socialLoginAttempt>) {
  if (action.payload.token && !action.payload.phoneVerified) {
    services.localStorage.remove('accessToken');
    services.localStorage.save('accessToken', action.payload.token);
    services.localStorage.save('accessTokenRegistration', action.payload.token);
    services.router.goto(services.router.url.phoneConfirmation);
    yield put(loadingActions.hide());
  } else {
    services.localStorage.remove('accessToken');
    services.localStorage.save('accessToken', action.payload.token);
    services.localStorage.save('accessTokenRegistration', action.payload.token);
    const userRequest = yield call(services.api.user.getCurrentUser);
    if (userRequest.status === 401) {
      services.localStorage.remove('accessToken');
      yield put(loadingActions.hide());
      if (
        userRequest.data.errors &&
        userRequest.data.errors[0].msg === 'This email is not activated yet.'
      ) {
        toast.dismiss();
        toast.error(
          'This email is not activated yet. Please click here to resend the activation email.',
          {
            onClick: () => {
              try {
                services.api.account.sendActivationMailWithToken(action.payload.token);
              } catch (err) {
                console.log(err);
              }
            },
          }
        );
        yield put(loadingActions.hide());
      } else {
        toast.dismiss();
        toast.error(userRequest.data.message ? userRequest.data.message : `Error 401`);
      }
    } else if (userRequest.isSuccess) {
      yield put(actions.loginSuccess(action.payload.token, User.create(userRequest.data)));
      services.router.goto(services.router.url.homepage);
    } else {
      yield put(actions.logout());
    }
  }
}

export function* loginSuccess(action: ActionType<typeof actions.loginSuccess>) {
  yield put(standbyActions.setRedirectStandby(undefined));
  yield put(loadingActions.hide());
}

export function* registerAttempt(action: ActionType<typeof actions.registerAttempt>) {
  yield put(loadingActions.show());
  const registerData = Object.assign({}, action.payload);
  registerData.birthday = datesHelper.parseDateToDateStringWithFormat(registerData.birthday);

  if (registerData.catExpirationDate) {
    registerData.catExpirationDate = datesHelper.parseDateToDateStringWithFormat(
      registerData.catExpirationDate
    );
  }

  registerData.expertise = registerData.expertise.map(el => {
    delete el.id;
    return el;
  });

  delete registerData.licenses;
  delete registerData.certifications;
  delete registerData.resumes;
  delete registerData.avatar;

  for (const key in registerData) {
    if (
      registerData[key] === undefined ||
      registerData[key] === null ||
      registerData[key] === '' ||
      (Array.isArray(registerData[key]) && registerData[key].length === 0)
    ) {
      delete registerData[key];
    }
  }

  const tokenRequest = yield call(services.api.account.register, registerData);
  if (tokenRequest.isError) {
    toast.dismiss();
    toast.error(tokenRequest.data.errors[0].msg);
    yield put(loadingActions.hide());
  } else {
    const filesObj = Object.assign(
      {},
      { licenses: action.payload.licenses },
      { certifications: action.payload.certifications },
      { resumes: action.payload.resumes },
      { avatar: action.payload.avatar }
    );
    const tokenVar = tokenRequest.get('token');
    services.localStorage.save('accessTokenRegistration', tokenRequest.get('token'));
    for (const file in filesObj) {
      if (file === 'avatar' && filesObj.avatar.file) {
        const getUrl = yield call(
          services.api.file.uploadRegAvatar,
          {
            name: filesObj.avatar.file.fileName,
            type: filesObj.avatar.file.fileType,
            size: filesObj.avatar.file.fileSize,
          },
          tokenVar
        );
        if (getUrl.isSuccess) {
          const data = getUrl.data.url.fields;
          const fileObj = Object.assign({}, data, {
            file: filesObj.avatar.file.file,
          });
          const formData = new FormData();
          for (const key in fileObj) {
            if (undefined === fileObj[key]) {
              continue;
            }
            formData.append(key, fileObj[key]);
          }
          const postFile = yield call(services.api.file.postFile, getUrl.data.url.url, formData);
          if (postFile.status === 204) {
            const avatarObject = Object.assign(
              {},
              { ...registerData },
              { avatar: getUrl.data.url.fields.key }
            );
            const addAvatar = yield call(services.api.file.addAvatar, avatarObject, tokenVar);
            if (addAvatar.isError) {
              console.log(addAvatar);
            }
          }
        } else {
          toast.dismiss();
          toast.error(getUrl.data.errors ? getUrl.data.errors[0].msg : `Error ${getUrl.status}`);
          yield put(loadingActions.hide());
        }
      } else {
        for (const item of filesObj[file]) {
          if (item.file && item.file.fileName !== '') {
            const getUrl = yield call(
              services.api.file.postRegisterFiles,
              {
                name: item.file.fileName,
                type: item.file.fileType,
                size: item.file.fileSize,
                public: true,
              },
              tokenVar
            );
            if (getUrl.isSuccess) {
              const data = getUrl.data.url.fields;
              const fileObj = Object.assign({}, data, {
                file: item.file.fileInitial,
              });
              const formData = new FormData();
              for (const key in fileObj) {
                if (undefined === fileObj[key]) {
                  continue;
                }
                formData.append(key, fileObj[key]);
              }
              const postFile = yield call(
                services.api.file.postFile,
                getUrl.data.url.url,
                formData
              );
              if (postFile.status === 204) {
                if (file === 'licenses') {
                  const addLicesne = yield call(
                    services.api.file.addLicense,
                    {
                      state: item.state,
                      license: item.license,
                      issuedDate: item.issuedDate,
                      expirationDate: item.expirationDate,
                      fileObjectKey: getUrl.data.url.fields.key,
                    },
                    tokenVar
                  );
                  if (addLicesne.isError) {
                    console.log(addLicesne);
                    toast.error(addLicesne.data.errors[0].msg);
                  }
                } else if (file === 'certifications') {
                  const addCert = yield call(
                    services.api.file.addCertification,
                    {
                      type: item.type,
                      certification: item.certification,
                      issuedDate: item.issuedDate,
                      expirationDate: item.expirationDate,
                      fileObjectKey: getUrl.data.url.fields.key,
                    },
                    tokenVar
                  );
                  if (addCert.isError) {
                    console.log(addCert);
                    toast.error(addCert.data.errors[0].msg);
                  }
                } else if (file === 'resumes') {
                  const addResume = yield call(
                    services.api.file.addResume,
                    {
                      name: item.name,
                      fileObjectKey: getUrl.data.url.fields.key,
                    },
                    tokenVar
                  );
                  if (addResume.isError) {
                    yield put(loadingActions.hide());
                    console.log(addResume);
                    toast.error(addResume.data.errors[0].msg);
                  }
                }
              }
            } else {
              toast.dismiss();
              toast.error(getUrl.data.errors[0].msg);
              yield put(loadingActions.hide());
            }
          } else {
            if (file === 'licenses') {
              const addLicesne = yield call(
                services.api.file.addLicense,
                {
                  state: item.state,
                  license: item.license,
                  issuedDate: item.issuedDate,
                  expirationDate: item.expirationDate,
                },
                tokenVar
              );
              if (addLicesne.isError) {
                console.log(addLicesne);
              }
            } else if (file === 'certifications') {
              const addCert = yield call(
                services.api.file.addCertification,
                {
                  type: item.type,
                  certification: item.certification,
                  issuedDate: item.issuedDate,
                  expirationDate: item.expirationDate,
                },
                tokenVar
              );
              if (addCert.isError) {
                console.log(addCert);
              }
            }
          }
        }
      }
    }
    services.router.goto(services.router.url.phoneConfirmation);
    yield put(loadingActions.hide());
    toast.success('Email with activation details was sent. Please activate your account!');
  }
}

export function* postRegisterFiles(action: ActionType<typeof actions.postRegisterFiles>) {
  const getUrl = yield call(
    services.api.file.postRegisterFiles,
    {
      name: action.payload.item.fileName,
      type: action.payload.item.fileType,
      size: action.payload.item.fileSize,
      public: true,
    },
    action.payload.token
  );
}

export function* phoneConfirm(action: ActionType<typeof actions.phoneConfirm>) {
  const tokenAuth = services.localStorage.get('accessTokenRegistration');
  const response = yield call(
    services.api.file.phoneVerificationRequest,
    Number(action.payload.phone),
    tokenAuth
  );
  if (response.isSuccess) {
    toast.dismiss();
    toast.success('Verification code was sent to your phone number successfully!');
  } else {
    toast.dismiss();
    toast.error(response.data ? response.data.errors[0].msg : `Error ${response.status}`);
  }
}
export function* phoneVerify(action: ActionType<typeof actions.phoneVerify>) {
  const tokenAuth = services.localStorage.get('accessTokenRegistration');
  const response = yield call(services.api.file.phoneVerification, action.payload.code, tokenAuth);
  if (response.isSuccess) {
    services.router.goto('/thank-you');
    // services.localStorage.remove('accessTokenRegistration');
  } else {
    toast.dismiss();
    toast.error(response.data ? response.data.errors[0].msg : `Error ${response.status}`);
  }
}

export function* newPassword(action: ActionType<typeof actions.NewPassword>) {
  yield put(actions.registerNextStep());
}

export function* registerSuccess(action: ActionType<typeof actions.registerSuccess>) {
  yield put(actions.loginSuccess(action.payload.accessToken, action.payload.user));
}

export function* emailVerificationAttempt(
  action: ActionType<typeof actions.emailVerificationAttempt>
) {
  yield put(loadingActions.show());

  const tokenRequest = yield call(
    services.api.account.emailVerificationConfirm,
    action.payload.userId,
    action.payload.verificationToken
  );

  if (tokenRequest.isError) {
    toast.dismiss();
    toast.error(tokenRequest.data.errors[0].msg);
    services.router.goto(services.router.url.homepage);
    yield put(loadingActions.hide());
  } else {
    services.localStorage.save('accessToken', tokenRequest.get('token'));

    const userRequest = yield call(services.api.user.getCurrentUser);
    if (userRequest.isSuccess) {
      yield put(actions.loginSuccess(tokenRequest.get('token'), User.create(userRequest.data)));
      tokenRequest.data.phoneVerified
        ? services.router.goto(services.router.url.homepage)
        : services.router.goto(services.router.url.phoneConfirmation);
      toast.dismiss();
      toast.success('Email was successfully verified.');
    } else if (userRequest.status === 401) {
      if (userRequest.data && userRequest.data.message === 'Phone is not verified yet.') {
        yield put(loadingActions.show());
        services.localStorage.remove('accessToken');
        yield put(accountActions.checkAuthentication());
        services.router.goto(services.router.url.phoneConfirmation);
        yield put(loadingActions.hide());
        toast.dismiss();
        toast.success('Email was successfully verified.');
      } else {
        services.router.goto(services.router.url.login);
      }
    } else {
      yield put(actions.logout());
    }
  }
}

export function* changeRegisterStore(action: ActionType<typeof actions.changeRegisterStore>) {
  const registerState = yield select(state => state.account.register.newUser);
  for (const key of Object.keys(action.payload)) {
    registerState[key] = action.payload[key];
  }
  yield put(actions.setNewUserData(registerState));
}

function* downloadFile(action: ActionType<typeof actions.downloadFile>) {
  const { file } = action.payload;

  try {
    const fileUrl = yield call(services.api.file.getDownloadFileUrl, file.key);
    if (!fileUrl) {
      toast.error(`Failed to get file download url for "${file.name}"!`);
      return;
    }
    services.api.file.downloadFile(fileUrl, file.name);
  } catch (err) {
    console.error('downloadFinanceSaga err', err);
    toast.error(`Failed to download file "${file.name}"!`);
  }
}

function* downloadPaperwork(action: ActionType<typeof actions.downloadPaperwork>) {
  const download = yield call(
    services.api.file.downloadPaperwork,
    action.payload.file.id,
    action.payload.file.name
  );
  if (download.error) {
    toast.dismiss();
    toast.error('Error on download attempt!');
  }
}

function* standbyListQuickSignUp(action: ActionType<typeof actions.standbyListQuickSignUp>) {
  yield put(loadingActions.show());

  try {
    const { payload } = action;

    const response: object = yield call(services.api.account.standbyListQuickSignUp, payload);
    console.log('response', response);
    toast.dismiss();

    if (response.isError) {
      if (response.data && response.data.errors) {
        toast.error(response.data ? response.data.errors[0].msg : `Error ${response.status}`);
      }
    } else {
      toast.success(`Successfully signed up for ${response.data.name}`);
      const { signupId } = response.data;
      let page = services.router.url.standbyListQuickSignUp.replace(':publicId', payload.publicId) + `?quicksignup=${signupId}`;
      services.router.goto(page);
      window.location.reload();
    }
  } catch (error) {
    console.log(error);
  }

  yield put(loadingActions.hide());
}