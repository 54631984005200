import classnames from 'classnames';
// @ts-ignore
import { withFormsy } from 'formsy-react';
import React from 'react';
import { Dropdown } from 'semantic-ui-react';

import './styles.css';

interface Props {
  getValue: () => any;
  setValue: (val: any) => void;
  className: string;
  options: object[];
}

interface State {}

class SortBySelectionInput extends React.Component<Props, State> {
  public handleChange = (event: any, data: any) => {
    const { value } = data;

    this.props.setValue(value);
  };

  public render() {
    const { className, options } = this.props;

    return (
      <div className={classnames('sort-by-selection-container', className)}>
        <Dropdown
          inline={true}
          selection={true}
          options={options}
          defaultValue={this.props.getValue()}
          text={`Sort by: ${this.getSelectedOption() ? this.getSelectedOption().text : 'Date'}`}
          onChange={this.handleChange}
        />
      </div>
    );
  }

  private getSelectedOption(): any {
    const { options } = this.props;
    const value = this.props.getValue();
    return options.find((opt: any) => opt.value === value);
  }
}

export default withFormsy(SortBySelectionInput);
