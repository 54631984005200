import React from 'react';
import './FolderTags.css';

// "application/x-zip-compressed",
//       "application/zip-compressed"

export default function DocumentIcon(props: { type: string }) {
  let mineTypeMapper = null;
  switch (props.type) {
    case 'application/pdf':
      mineTypeMapper = 'PDF';
      break;
    case 'application/zip':
      mineTypeMapper = 'ZIP';
      break;
    case 'application/x-zip-compressed':
      mineTypeMapper = 'ZIP';
      break;
    case 'application/zip-compressed':
      mineTypeMapper = 'ZIP';
      break;
    case 'application/vnd.ms-excel':
      mineTypeMapper = 'XLSX';
      break;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      mineTypeMapper = 'XLSX';
      break;
    case 'application/msword':
      mineTypeMapper = 'DOC';
      break;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      mineTypeMapper = 'DOC';
      break;
    case 'application/vnd.ms-powerpoint':
      mineTypeMapper = 'PPTX';
      break;
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      mineTypeMapper = 'PPTX';
      break;
  }

  if (!mineTypeMapper) {
    return null;
  }

  return <img width={160} height={180} src={`/${mineTypeMapper.toLocaleLowerCase()}-icon.png`} />;
}
