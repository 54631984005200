import { withFormsy } from 'formsy-react';
import * as Formsy from 'formsy-react';
import { Form } from 'formsy-semantic-ui-react';
import React from 'react';
import { Button, Icon, Image, Popup } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import services from '../../services';
import ErrorLabel from '../error-label/ErrorLabel';

interface Props {
  item: object;
  id: number;
  field: string;
  delete: (id: number) => void;
  edit: (id: number, name: any, value: any) => void;
  allItems: object[];
}

interface State {
  experienceOptions: object[];
}

Formsy.addValidationRule('hasSameSkills', (values: any, value: any) => {
  const array = [];
  for (const key in values) {
    if (key.includes('skills') && values[key] === value && value !== undefined) {
      array.push(key);
    }
  }
  if (array.length > 1) {
    return false;
  } else {
    return true;
  }
});

Formsy.addValidationRule('hasSameExperience', (values: any, value: any) => {
  const array = [];
  for (const key in values) {
    if (key.includes('experience') && values[key] === value && value !== undefined) {
      array.push(key);
    }
  }
  if (array.length > 1) {
    return false;
  } else {
    return true;
  }
});

Formsy.addValidationRule('hasSameLoss', (values: any, value: any) => {
  const array = [];
  for (const key in values) {
    if (key.includes('type') && values[key] === value && value !== undefined) {
      array.push(key);
    }
  }
  if (array.length > 1) {
    return false;
  } else {
    return true;
  }
});

class ExperienceItem extends React.Component<Props, State> {
  public state = {
    experienceOptions: [],
  };

  public componentDidMount = async () => {
    if (this.props.field === 'experience') {
      const response = await services.api.account.getSettingsData('experience');
      if (response.isSuccess) {
        const experienceOptions = response.data.list.map((el: any, index: number) => {
          return { value: el.name, text: el.name };
        });
        this.setState({ experienceOptions });
      } else {
        console.log(response);
      }
    } else if (this.props.field === 'skills') {
      const response = await services.api.account.getSettingsData('softwareSkill');
      if (response.isSuccess) {
        const experienceOptions = response.data.list.map((el: any, index: number) => {
          return { value: el.name, text: el.name };
        });
        this.setState({ experienceOptions });
      } else {
        console.log(response);
      }
    }
  };

  public render() {
    const { item, id, field } = this.props;
    const errorLabel = <ErrorLabel />;
    return (
      <div className="experience-block">
        {field === 'experience' ? (
          <Form.Dropdown
            search={true}
            selectOnBlur={false}
            name={`${field}-field-${id}`}
            options={this.state.experienceOptions}
            placeholder="Please Select"
            instantValidation={false}
            className="form-input-dropdown long"
            required={true}
            onChange={(e: any, data: any) => this.props.edit(item.id, 'name', data.value)}
            value={item.name}
            validations={{ hasSameExperience: true }}
            validationErrors={{
              isDefaultRequiredValue: 'Experience is required field.',
              hasSameExperience: 'Experience values should be unique.',
            }}
            errorLabel={errorLabel}
          />
        ) : (
          <Form.Dropdown
            search={true}
            selectOnBlur={false}
            name={`${field}-field-${id}`}
            options={this.state.experienceOptions}
            placeholder="Please Select"
            instantValidation={false}
            className="form-input-dropdown long"
            required={true}
            onChange={(e: any, data: any) => this.props.edit(item.id, 'name', data.value)}
            value={item.name}
            validations={{ hasSameSkills: true }}
            validationErrors={{
              isDefaultRequiredValue: 'Software Skills is required field.',
              hasSameSkills: 'Software Skills values should be unique.',
            }}
            errorLabel={errorLabel}
          />
        )}
        <Form.Input
          name={`${field}-years-${id}`}
          icon={<Icon>YEARS</Icon>}
          type="number"
          min={0}
          value={item.years}
          placeholder="..."
          instantValidation={false}
          className="form-input-years"
          onChange={(e: any, data: any) => this.props.edit(item.id, 'years', data.value)}
          required={true}
          validationErrors={{
            isDefaultRequiredValue: `* Years is required field.`,
          }}
          errorLabel={errorLabel}
        />
        <Popup
          trigger={
            <Button
              type="button"
              className="iconic-button-custom delete-exp-button"
              disabled={this.props.allItems.length === 1}
              onClick={() => this.props.delete(item.id)}
            >
              <Image src={closeIcon} />
            </Button>
          }
          size="mini"
          basic={true}
          content="Delete"
        />
      </div>
    );
  }
}

export default withFormsy(ExperienceItem);
