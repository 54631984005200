import { trackPromise } from 'react-promise-tracker';
import { request } from './base';

export const list = () => {
  return request({
    method: 'get',
    url: 'user/list',
  });
};

export const getCurrentUser = () => {
  return request({
    method: 'get',
    url: 'adjuster/profile',
  });
};

export const getCurrentUserById = (id: any) => {
  return request({
    method: 'get',
    url: `adjuster/${id}`,
  });
};

export const updateCurrentUser = (model: any) => {
  return request({
    method: 'post',
    url: 'adjuster/profile',
    data: {
      ...model,
    },
  });
};

export const updatePersonalEmail = (email: string) => {
  return request({
    method: 'patch',
    url: '/adjuster/me/email',
    data: {
      email,
    },
  });
};

export const getCurrentUserSkills = () => {
  return request({
    method: 'get',
    url: 'adjuster/professional-skills',
  });
};

export const getPaperwork = () => {
  return request({
    method: 'get',
    url: 'paperwork',
  });
};

export const updateUserSkills = (model: any) => {
  return request({
    method: 'post',
    url: 'adjuster/professional-skills',
    data: {
      ...model,
    },
  });
};

export const updateProfile = (data: any) => {
  return request({
    method: 'post',
    url: 'user/me',
    data,
  });
};

export const sendEmailVerificationRequest = () => {
  return request({
    method: 'post',
    url: 'user/me/email-verification-request',
  });
};

export const listUserResumes = () => {
  return request({
    method: 'get',
    url: '/resume',
  });
};

export const listUserLicenses = () => {
  return trackPromise(
    request({
      method: 'get',
      url: '/license',
    })
  );
};

export const removeResume = (id: any) => {
  return request({
    method: 'DELETE',
    url: `/resume/${id}`,
  });
};

export const listUserCertifications = () => {
  return request({
    method: 'get',
    url: '/certification',
  });
};

export const removeCertification = (id: any) => {
  return request({
    method: 'DELETE',
    url: `/certification/${id}`,
  });
};

export const addLicense = (postData: any) => {
  return trackPromise(
    request({
      method: 'post',
      url: `/license`,
      data: {
        ...postData,
      },
    })
  );
};

export const updateLicense = (id: any, postData: any) => {
  return trackPromise(
    request({
      method: 'patch',
      url: `/license/${id}`,
      data: {
        ...postData,
      },
    })
  );
};

export const removeLicense = (id: any) => {
  return request({
    method: 'DELETE',
    url: `/license/${id}`,
  });
};

export const addCertification = (postData: any) => {
  return request({
    method: 'post',
    url: `/certification`,
    data: {
      ...postData,
    },
  });
};

export const addResume = (postData: any) => {
  return request({
    method: 'post',
    url: `/resume`,
    data: {
      ...postData,
    },
  });
};

export const updateResume = (id: any, postData: any) => {
  return request({
    method: 'patch',
    url: `/resume/${id}`,
    data: {
      ...postData,
    },
  });
};

export const updateCertifications = (id: any, postData: any) => {
  return request({
    method: 'patch',
    url: `/certification/${id}`,
    data: {
      ...postData,
    },
  });
};

export const getFilteredFinances = (params?: any) => {
  return trackPromise(
    request({
      method: 'GET',
      url: `adjuster/finances`,
      params: {
        ...params,
        pageSize: 20,
      },
    }),
    'finance-adjuster-list'
  );
};
