import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators, Dispatch } from 'redux';
import '../../assets/styles/Login.css';
import '../../assets/styles/Register.css';
import PasswordForm from '../../components/register-form/PasswordForm';
import RegisterForm from '../../components/register-form/RegisterForm';
import RegisterProfile from '../../components/register-profile/RegisterProfile';
import { accountActions } from '../../features/account';
import { NewUser } from '../../models/newUser';
import services from '../../services';
import { checkEmailIsValidForRegistration } from '../../services/api/account';

interface Props {
  changeRegisterStore: typeof accountActions.changeRegisterStore;
  submitSocialRegister: typeof accountActions.submitSocialRegister;
  changeProgressValue: typeof accountActions.changeProgressValue;
  register: typeof accountActions.registerAttempt;
  prevStep: typeof accountActions.registerPrevStep;
  errors: string[];
  isLoading: boolean;
  step: number;
  newUser: NewUser;
  progress: number;
}

interface State {}

class Register extends React.Component<Props, State> {
  public componentDidMount = () => {
    if (services.localStorage.get('accessToken')) {
      services.router.goto('/');
    }
  };

  public registerUser = () => {
    this.props.register(this.props.newUser);
  };

  public validateEmail = async (email: string) => {
    const emailExistsResponse = await checkEmailIsValidForRegistration(email);
    if (emailExistsResponse.data.exists) {
      toast.dismiss();
      toast.error(
        'This account is already registered in the system. If you have problems with accessing your account please send an email to employment@compassadjusters.com.'
      );

      return false;
    }

    return true;
  };

  public validateSocialRegister = async (email: string) => {
    const isValid = await this.validateEmail(email);
    if (isValid) {
      this.props.submitSocialRegister(email);
    }
  };

  public validateEmailRegister = async (model: any) => {
    const isValid = await this.validateEmail(model.email);
    if (isValid) {
      this.props.changeRegisterStore(model);
    }
  };

  public showStep = () => {
    switch (true) {
      case this.props.step === 1:
        return (
          <RegisterForm
            socialRegister={this.validateSocialRegister}
            isLoading={this.props.isLoading}
            onSubmit={this.validateEmailRegister}
            previousStep={this.props.prevStep}
            errors={this.props.errors}
          />
        );
      case this.props.step === 2:
        return (
          <PasswordForm
            isLoading={this.props.isLoading}
            onSubmit={this.props.changeRegisterStore}
            previousStep={this.props.prevStep}
            errors={this.props.errors}
          />
        );
      case this.props.step >= 3:
        return (
          <RegisterProfile
            errors={this.props.errors}
            progress={this.props.progress}
            step={this.props.step}
            registerUser={this.registerUser}
            previousStep={this.props.prevStep}
            changeProgressValue={this.props.changeProgressValue}
            newUser={this.props.newUser}
          />
        );
    }
  };

  public render() {
    return <div>{this.showStep()}</div>;
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  errors: state.account.register.errors,
  isLoading: state.loading.loading.isVisible,
  step: state.account.register.step,
  newUser: state.account.register.newUser,
  progress: state.account.register.progress,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      submitSocialRegister: accountActions.submitSocialRegister,
      changeRegisterStore: accountActions.changeRegisterStore,
      changeProgressValue: accountActions.changeProgressValue,
      register: accountActions.registerAttempt,
      nextStep: accountActions.registerNextStep,
      prevStep: accountActions.registerPrevStep,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);
