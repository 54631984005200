import { Form } from 'formsy-semantic-ui-react';
import React from 'react';
import { trackPromise } from 'react-promise-tracker';
import { Grid } from 'semantic-ui-react';
import { IUser } from '../../models';
import services from '../../services';
import PaperworkItem from '../register-layout/PaperworkItem';
import { LoadingSpiner } from './../loading-spinner/LoadingSpinner';

interface Props {
  getSubmitRef: (ref: any) => void;
  user: IUser;
  download: (file: any) => void;
}

interface State {
  paperwork: object[];
}

class ProfilePaperwork extends React.Component<Props, State> {
  public state = {
    paperwork: [],
  };

  public componentDidMount = () => {
    this.listPaperwork();
  };

  public listPaperwork = async () => {
    const response = await trackPromise(services.api.user.getPaperwork(), 'profile-paperwork');
    if (response.isSuccess) {
      this.setState({ paperwork: response.data.filter(paperwork => paperwork.files.length) });
    } else {
      console.log(response);
    }
  };

  public render() {
    return (
      <Grid stackable={true} style={{ width: '100%' }} className="licenses-grid">
        <LoadingSpiner area="profile-paperwork" />
        <Grid.Row columns={1} only="computer">
          <Grid.Column className="licenses-column">
            <Form
              onValidSubmit={(model: any) => {
                const { paperwork } = this.state;
              }}
            >
              {this.state.paperwork.length === 0 ? (
                <Form.Input
                  name="paperwork"
                  placeholder="Nothing Added"
                  instantValidation={false}
                  className="form-input readonly-input"
                  readOnly={true}
                />
              ) : (
                <div>
                  {this.state.paperwork.map((el: any, index: number) => (
                    <PaperworkItem download={this.props.download} item={el} key={index} />
                  ))}
                </div>
              )}
              <Form.Input
                name="licenseFile"
                hidden={true}
                style={{ display: 'none' }}
                readOnly={true}
              />
            </Form>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={16} only="tablet mobile">
          <Grid.Column className="licenses-column">
            <Form
              onValidSubmit={(model: any) => {
                const { paperwork } = this.state;
              }}
            >
              {this.state.paperwork.length === 0 ? (
                <Form.Input
                  name="licenses"
                  placeholder="Nothing Added"
                  instantValidation={false}
                  className="form-input readonly-input"
                  readOnly={true}
                />
              ) : (
                <div>
                  {this.state.paperwork.map((el: any, index: number) => (
                    <PaperworkItem download={this.props.download} item={el} key={index} />
                  ))}
                </div>
              )}
              <Form.Input
                name="licenseFile"
                hidden={true}
                style={{ display: 'none' }}
                readOnly={true}
              />
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default ProfilePaperwork;
