import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import { IUser } from '../../models';
import { NewUser } from './../../models/newUser';
import * as actions from './actions';
import * as constants from './constants';

export interface Account {
  isAuthenticationChecked: boolean;
  isAuthenticated: boolean | undefined;
  accessToken?: string;
  user?: IUser;
}

export const AccountDefaultState: Account = {
  isAuthenticationChecked: false,
  isAuthenticated: undefined,
};

export interface Login {
  error: string;
}

export const LoginDefaultState: Login = {
  error: '',
};

export interface Register {
  errors: string[];
  step: number;
  totalSteps: number;
  progress: number;
  newUser: NewUser;
  token: string;
}

export const RegisterDefaultState: Register = {
  totalSteps: 7,
  errors: [],
  step: 1,
  progress: 5,
  newUser: {
    avatar: undefined,
    firstName: '',
    availableForClaims: true,
    middleName: undefined,
    lastName: '',
    birthday: 0,
    email: '',
    password: '',
    passwordConfirm: '',
    phone: '',
    mobilePhone: '',
    addressLine1: '',
    addressLine2: undefined,
    city: '',
    state: '',
    zip: '',
    catZip: '',
    fax: '',
    catCity: '',
    catStateProvince: '',
    catExpirationDate: '',
    positions: [],
    educations: [],
    references: [],
    experiences: [],
    languages: [],
    softwareSkills: [],
    equipments: [],
    expertise: [],
    climbRoof: true,
    milesRange: undefined,
    xactnetAddress: undefined,
    licenses: [],
    resumes: [],
    certifications: [],
    howDidYouHearAboutUs: '',
    isRegisteredWithSocialAccount: false,
    registrationType: '',
    companyName: undefined,
    preferredName: undefined,
    temporaryLocation: false,
    isSubscribedToCompassMailUpdates: true,
    isSubscribedToPersonalMailUpdates: true,
  },
  token: '',
};

export interface EmailVerification {
  errors: string[];
}

export const EmailVerificationDefaultState: EmailVerification = {
  errors: [],
};

export interface State {
  account: Account;
  login: Login;
  register: Register;
  emailVerification: EmailVerification;
}

export type Action = ActionType<typeof actions>;

export default combineReducers<State, Action>({
  account: (state = AccountDefaultState, action) => {
    switch (action.type) {
      case constants.CHECK_AUTHENTICATION_FINISH:
        return {
          ...state,
          isAuthenticationChecked: true,
        };

      case constants.UPDATE_USER_DATA:
        return {
          ...state,
          user: action.payload,
        };

      case constants.LOGIN_SUCCESS:
        return {
          ...state,
          accessToken: action.payload.accessToken,
          isAuthenticated: true,
          user: action.payload.user,
          isAuthenticationChecked: true,
        };

      case constants.LOGOUT:
        return {
          ...state,
          accessToken: undefined,
          isAuthenticated: false,
          user: undefined,
        };
      default:
        return state;
    }
  },
  login: (state = LoginDefaultState, action) => {
    switch (action.type) {
      case constants.LOGIN_ATTEMPT:
        return { ...state };

      case constants.SOCIAL_LOGIN_ATTEMPT:
        return { ...state };

      case constants.LOGIN_ATTEMPT_FAILED:
        return { ...state, error: action.payload.error };

      case constants.ADMIN_LOGIN:
        return { ...state };

      default:
        return state;
    }
  },
  register: (state = RegisterDefaultState, action) => {
    switch (action.type) {
      case constants.REGISTER_ATTEMPT:
        return { ...state };

      case constants.CHANGE_REGISTER_STORE:
        return { ...state };

      case constants.SUBMIT_SOCIAL_REGISTER:
        return {
          ...state,
          step: 3,
          newUser: {
            ...state.newUser,
            email: action.payload.email,
            isRegisteredWithSocialAccount: true,
          },
        };

      case constants.SET_NEW_USER_DATA:
        return {
          ...state,
          newUser: action.payload.model,
          step: state.step < state.totalSteps ? state.step + 1 : state.step,
        };

      case constants.REGISTER_NEXT_STEP:
        return { ...state, step: state.step + 1 };

      case constants.REGISTER_PREV_STEP:
        return { ...state, step: state.step === 3 ? 1 : state.step - 1 };

      case constants.REGISTER_IS_NEW_EMAIL:
        return { ...state, email: action.payload.email };

      case constants.REGISTER_NEW_PASSWORD:
        return { ...state, password: action.payload.password };

      case constants.REGISTER_ATTEMPT_FAILED:
        return { ...state, errors: action.payload.errors };

      case constants.CHANGE_PROGRESS_VALUE:
        return { ...state, progress: action.payload.value };

      case constants.PHONE_CONFIRM_ATTEMPT:
        return { ...state, token: action.payload.token };

      case constants.PHONE_CONFIRM:
        return { ...state };

      case constants.PHONE_VERIFY:
        return { ...state };

      case constants.RESET_REGISTER_STEPS:
        return {
          ...state,
          newUser: RegisterDefaultState.newUser,
          step: RegisterDefaultState.step,
          progress: RegisterDefaultState.progress,
        };

      default:
        return state;
    }
  },
  emailVerification: (state = EmailVerificationDefaultState, action) => {
    switch (action.type) {
      case constants.EMAIL_VERIFICATION_ATTEMPT_FAILED:
        return { ...state, errors: action.payload.errors };

      default:
        return state;
    }
  },
});
