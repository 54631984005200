import * as Formsy from 'formsy-react';
import { Form } from 'formsy-semantic-ui-react';
import React, { FormEventHandler } from 'react';
import { Button, Grid, Header, Icon, Image, Popup } from 'semantic-ui-react';
import zxcvbn from 'zxcvbn';
import compassLogo from '../../assets/images/compass-logo-vector.svg';
import eyeIcon from '../../assets/images/ui-show-pass.svg';
import '../../assets/styles/Login.css';
import ErrorLabel from '../error-label/ErrorLabel';
import ErrorListMessage from '../error-list-message/ErrorListMessage';

import { NewPasswordModel } from '../../features/account/actions';

interface Props {
  isLoading: boolean;
  onSubmit: (model: NewPasswordModel) => void;
  previousStep: () => void;
  errors: string[];
}

interface State {
  strength: string;
  password: string;
  showPass: boolean;
}

Formsy.addValidationRule('hasUppercase', (values, value) => {
  if (value !== undefined && value.toLowerCase() !== value) {
    return true;
  } else {
    return false;
  }
});
Formsy.addValidationRule('hasLowercase', (values, value) => {
  if (value !== undefined && value.toUpperCase() !== value) {
    return true;
  } else {
    return false;
  }
});
Formsy.addValidationRule('hasSpecial', (values, value) => {
  const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
  if (value !== undefined && format.test(value)) {
    return true;
  } else {
    return false;
  }
});
Formsy.addValidationRule('hasNumbers', (values, value) => {
  return /\d/.test(value);
});

class PasswordForm extends React.Component<Props, State> {
  public form: any;
  public state: State = {
    strength: '',
    password: '',
    showPass: false,
  };

  public onValidSubmit = (model: any) => {
    this.props.onSubmit(model);
  };

  public computePasswordStrength: FormEventHandler = event => {
    this.setState({ password: event.target.value });
    const passwordScore = zxcvbn(event.target.value).score;
    const changeDotColor = () => {
      const dots = document.querySelectorAll('.password-dot-container > .circle.icon');
      dots.forEach(dot => {
        dot.className = 'circle icon';
      });
      for (let i = 0; i <= passwordScore; i++) {
        dots.item(i).className = `circle icon ${this.state.strength}`;
      }
    };
    switch (passwordScore) {
      case 0:
        this.setState({ strength: 'weak' }, () => changeDotColor());
        break;
      case 1:
        this.setState({ strength: 'weak' }, () => changeDotColor());
        break;
      case 2:
        this.setState({ strength: 'fair' }, () => changeDotColor());
        break;
      case 3:
        this.setState({ strength: 'good' }, () => changeDotColor());
        break;
      case 4:
        this.setState({ strength: 'strong' }, () => changeDotColor());
        break;
      default:
        this.setState({ strength: '' }, () => changeDotColor());
    }
  };

  public render() {
    const errorLabel = <ErrorLabel />;
    const { errors } = this.props;
    return (
      <div>
        <div className="logo-container">
          <Image src={compassLogo} />
          <h3>
            Create your account to start your work with Compass Adjusting Services. All information
            is handled as confidential by Compass Adjusting Services, Inc.
          </h3>
        </div>
        <Grid centered={true} style={{ height: '100%' }} verticalAlign="middle">
          <Grid.Column style={{ maxWidth: 300 }}>
            <ErrorListMessage errors={errors} />

            <div className="login-form-container">
              <Header
                as="h5"
                icon={true}
                textAlign="center"
                style={{ marginTop: '50px', fontWeight: '800', fontSize: '32px' }}
              >
                <Header.Content>Enter Password</Header.Content>
              </Header>
              <Form
                onValidSubmit={this.onValidSubmit}
                ref={(ref: any) => (this.form = ref)}
                className="login-form"
              >
                <Form.Input
                  name="password"
                  placeholder="Password"
                  type={this.state.showPass ? 'text' : 'password'}
                  icon={
                    <Popup
                      trigger={
                        <i
                          className={`icon link custom-icon`}
                          onClick={() => this.setState({ showPass: !this.state.showPass })}
                        >
                          <Image
                            src={eyeIcon}
                            className={this.state.showPass ? 'show-pass-icon' : 'hide-pass-icon'}
                          />
                        </i>
                      }
                      size="mini"
                      basic={true}
                      content={this.state.showPass ? 'Hide Password' : 'View Password'}
                    />
                  }
                  instantValidation={false}
                  required={true}
                  onChange={this.computePasswordStrength.bind(this)}
                  validations={{
                    hasLowercase: true,
                    hasUppercase: true,
                    hasSpecial: true,
                    hasNumbers: true,
                    minLength: 8,
                  }}
                  validationErrors={{
                    isDefaultRequiredValue: '* Password is required field.',
                    hasLowercase: '* The password must contain at least 1 lowercase character.',
                    hasUppercase: '* The password must contain at least 1 uppercase character.',
                    hasSpecial: '* The password must contain at least 1 special character.',
                    hasNumbers: '* The password must contain at least 1 number.',
                    minLength: '* The password must be at least 8 symbols long.',
                  }}
                  errorLabel={errorLabel}
                />
                <div
                  className="password-strength-container"
                  style={{ display: this.state.password === '' ? 'none' : 'flex' }}
                >
                  <div
                    className={`password-strength ${this.state.strength}`}
                    style={{ display: this.state.password === '' ? 'none' : 'block' }}
                  >
                    {this.state.strength}
                  </div>
                  <div className="password-dot-container">
                    <Icon name="circle" />
                    <Icon name="circle" />
                    <Icon name="circle" />
                    <Icon name="circle" />
                    <Icon name="circle" />
                  </div>
                </div>

                <Form.Input
                  name="passwordConfirm"
                  placeholder="Repeat Password"
                  type={this.state.showPass ? 'text' : 'password'}
                  instantValidation={false}
                  required={true}
                  validations="equalsField:password"
                  validationErrors={{
                    isDefaultRequiredValue: '* Repeat password is required field.',
                    equalsField: '* Passwords do not match.',
                  }}
                  errorLabel={errorLabel}
                />

                <Button content="Next" primary={true} />
              </Form>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default PasswordForm;
