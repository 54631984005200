import classnames from 'classnames';
// @ts-ignore
import { withFormsy } from 'formsy-react';
import React, { cloneElement } from 'react';
import { DateInput } from 'semantic-ui-calendar-react';
import { Button, Icon, Image } from 'semantic-ui-react';

import InputMask from 'react-input-mask';

import moment from 'moment';
import clearIcon from '../../assets/images/Blue/cancel.svg';
import icon from '../../assets/images/Blue/ui-calendar.svg';
import './styles.css';

interface Props {
  getValue: () => any;
  setValue: (val: any) => void;
  isFormSubmitted: () => any;
  getErrorMessage: () => any;
  minDate?: any;
  maxDate?: any;
  className: string;
  isValid: () => any;
  isPristine: () => any;
  errorLabel: any;
  validationErrors: any;
  readOnly: boolean;
  required: boolean;
}

interface State {}

class DatePickerInput extends React.Component<Props, State> {
  private containerNode: any = React.createRef();

  public handleChange = (event: any, data: any) => {
    let { value } = data;

    if (value) {
      value = moment(value, 'MM/DD/YYYY').format();
    }

    this.props.setValue(value || '');
  };

  public handleChangeText = (event: any) => {
    const dateString = event.target.value;

    const parts = dateString.split('/');
    const day = parseInt(parts[1], 10);
    const month = parseInt(parts[0], 10);
    const year = parseInt(parts[2], 10);

    const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
      monthLength[1] = 29;
    }

    const parsedMonth = month > 12 ? `12` : parts[0] === '00' ? '01' : parts[0];
    const parsedDay =
      day > monthLength[month - 1]
        ? `${monthLength[month - 1]}`
        : parts[1] === '00'
        ? '01'
        : parts[1];

    const result =
      `${parsedMonth}` + `${parsedDay ? '/' + parsedDay : ''}` + `${year ? '/' + year : ''}`;

    this.props.setValue(result);

    if (event.target.value.length === 10) {
      this.props.setValue(moment(event.target.value).format());
    }
  };

  public handleIconPress = () => {
    this.containerNode.current.getElementsByTagName('input')[1].focus();
  };

  public render() {
    let value = this.props.getValue() || '';

    if (value && 4 === `${value}`.indexOf('-')) {
      value = moment(value.substring(0, 10), 'YYYY-MM-DD').format('MM/DD/YYYY');
    }

    const {
      className,
      isValid,
      isPristine,
      errorLabel,
      getErrorMessage,
      readOnly,
      required,
      isFormSubmitted,
    } = this.props;

    const error = !isPristine() && !isValid() && isFormSubmitted();
    return (
      <div className={classnames('form-input-short', className)}>
        <div ref={this.containerNode} className={classnames('dateinput-container', className)}>
          <InputMask
            readOnly={readOnly}
            onChange={this.handleChangeText}
            value={value}
            formNoValidate={true}
            placeholder="MM/DD/YYYY"
            onTouchEnd={(e: any) =>
              this.containerNode.current.getElementsByTagName('input')[0].blur()
            }
            mask="99/99/9999"
            maskChar={''}
            style={{ width: '145px', background: `${readOnly ? '#e4e8f0' : 'white'}` }}
          />

          <DateInput
            className={`dateinput custom-dateinput ${readOnly ? 'readonly-input' : ''}`}
            name="date"
            required={required}
            readOnly={readOnly}
            closable={true}
            clearable={!readOnly}
            clearIcon={
              <Icon className="clear-date-icon">
                <Image src={clearIcon} />
              </Icon>
            }
            dateFormat="MM/DD/YYYY"
            placeholder="MM/DD/YYYY"
            value={value}
            onChange={this.handleChange}
            icon={false}
            iconPosition="right"
            closeOnMouseLeave={false}
            hideMobileKeyboard={true}
          />

          <div className="icon">
            <Button type="button" icon={true} onClick={this.handleIconPress}>
              <Icon>
                <Image src={icon} />
              </Icon>
            </Button>
          </div>
        </div>
        {error && errorLabel && cloneElement(errorLabel, {}, getErrorMessage())}
      </div>
    );
  }
}

export default withFormsy(DatePickerInput);
