import { Form } from 'formsy-semantic-ui-react';
import React from 'react';
import { Button, Header, Image, Popup } from 'semantic-ui-react';
import fbIcon from '../../assets/images//icn-f.svg';
import gIcon from '../../assets/images//icn-g.svg';
import inIcon from '../../assets/images//icn-in.svg';
import eyeIcon from '../../assets/images/ui-show-pass.svg';
import '../../assets/styles/Login.css';
import { api } from '../../config';
import services from '../../services';
import ErrorLabel from '../error-label/ErrorLabel';

interface Props {
  isLoading: boolean;
  onSubmit: (email: string, password: string) => void;
  social: (token: string, phoneVerified: any) => void;
}

interface State {
  showPass: boolean;
}

class LoginForm extends React.Component<Props, State> {
  public form: any;

  public state = {
    showPass: false,
  };

  public onValidSubmit = (model: any) => {
    this.props.onSubmit(model.email, model.password);
  };

  public handleSocial = (id: string) => {
    let url: string = '';

    switch (id) {
      case 'google':
        url = `${api.endpoint}account/google-login`;
        break;
      case 'linkedIn':
        url = `${api.endpoint}account/linkedin-login`;
        break;
      case 'facebook':
        url = `${api.endpoint}account/facebook-login`;
        break;
    }

    if (window.location.search.includes('?redirect_url')) {
      const params = new URLSearchParams(window.location.search);
      url += `?redirect_url=${params.get('redirect_url')}&token=${params.get('token')}`;
    }

    window.location.href = url;
  };

  public componentDidMount() {
    if (services.localStorage.get('accessToken') && !window.location.search.includes('?token=')) {
      services.router.goto('/');
    } else if (window.location.search.includes('?token=')) {
      const params = new URLSearchParams(window.location.search);
      const socialToken = params.get('token');
      const phoneVerified = params.get('phoneVerified') === 'false' ? false : true;
      if (socialToken !== 'undefined') {
        this.props.social(socialToken, phoneVerified);
      }
    }
  }

  public render() {
    const errorLabel = <ErrorLabel />;

    return (
      <div className="login-form-container">
        <Form
          onValidSubmit={this.onValidSubmit}
          ref={(ref: any) => (this.form = ref)}
          className="login-form"
        >
          <Form.Input
            name="email"
            autoCapitalize="none"
            autoCorrect="off"
            placeholder="Email"
            instantValidation={false}
            required={true}
            validations="isEmail"
            validationErrors={{
              isDefaultRequiredValue: '* Email is required field.',
              isEmail: 'Email format is invalid.',
            }}
            errorLabel={errorLabel}
          />

          <Form.Input
            name="password"
            placeholder="Password"
            icon={
              <Popup
                trigger={
                  <i
                    className={`icon link custom-icon`}
                    onClick={() => this.setState({ showPass: !this.state.showPass })}
                  >
                    <Image
                      src={eyeIcon}
                      className={this.state.showPass ? 'show-pass-icon' : 'hide-pass-icon'}
                    />
                  </i>
                }
                size="mini"
                basic={true}
                content={this.state.showPass ? 'Hide Password' : 'View Password'}
              />
            }
            type={this.state.showPass ? 'text' : 'password'}
            instantValidation={false}
            required={true}
            validations="minLength:8"
            validationErrors={{
              isDefaultRequiredValue: '* Password is required field.',
              minLength: 'Password must be at least 8 symbols long.',
            }}
            errorLabel={errorLabel}
          />
          <Button content="Login" primary={true} />

          <Header as="h4" icon={true} textAlign="center" style={{ color: '#535D6C' }}>
            <Header.Content>Or login with</Header.Content>
          </Header>

          <div className="social-login-block">
            <Button
              type="button"
              className="social-button fb-button"
              onClick={() => this.handleSocial('facebook')}
            >
              <Image src={fbIcon} />
            </Button>
            <Button
              type="button"
              className="social-button g-button"
              onClick={() => this.handleSocial('google')}
            >
              <Image src={gIcon} />
            </Button>
            <Button
              type="button"
              className="social-button in-button"
              onClick={() => this.handleSocial('linkedIn')}
            >
              <Image src={inIcon} />
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

export default LoginForm;
