import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators, Dispatch } from 'redux';
import { Dimmer, Grid, Loader, Responsive } from 'semantic-ui-react';
import AdjusterProfile from '../../components/adjuster-profile-layout/AdjusterProfile';
import AccountHeader from '../../components/layout-header/AccountHeader';
import Sidebar from '../../components/sidebar-layout/Sidebar';
import { accountActions } from '../../features/account';
import { IUser } from '../../models';
import services from '../../services';
import { PrivateRoute } from '../PrivateRoute';
import StandbyLists from '../standby/standby';
import StandbyQuestions from '../standby/standbyQuestions';
import AccountHeaderMobile from './../../components/layout-header/AccountHeaderMobile';
import TrainingCenter from '../training-center/trainingCenter';

interface Props {
  isAuthenticationChecked: boolean;
  isLoading: boolean;
  loadingProgress: number;
  user?: IUser;
  checkAuthentication: () => void;
  logout: typeof accountActions.logout;
  pathname: string;
  router: any;
}

interface State {}

class Homepage extends React.Component<Props, State> {
  public componentDidMount = () => {
    const link = new URLSearchParams(location.search);
    if (link.get('unsubscribedFromEmails') === 'true') {
      toast.dismiss();
      toast.success('You have successfully unsubscribed from email notifications!');
    }
  };

  public componentWillMount(): void {
    this.props.checkAuthentication();
  }

  public render() {
    const { user, isAuthenticationChecked } = this.props;
    if (!this.props.isAuthenticationChecked) {
      return (
        <Dimmer active={true} page={true}>
          <Loader size="massive">Please wait</Loader>
        </Dimmer>
      );
    }
    return (
      <React.Fragment>
        <AccountHeader user={user} logout={this.props.logout} pathname={this.props.match.path} />

        <Grid stackable={true} style={{ margin: '0 8px', paddingBottom: '20px' }}>
          <Grid.Row style={{ width: '100%' }}>
            <Grid.Column className="sidebar-container-column">
              <Sidebar user={this.props.user} />
              <Responsive maxWidth={768}>
                <AccountHeaderMobile user={user} pathname={this.props.pathname} />
              </Responsive>
            </Grid.Column>

            <Grid.Column className="content-container-column">
              <PrivateRoute
                exact={true}
                history={this.props.history}
                path={services.router.url.homepage}
                component={AdjusterProfile}
              />
              <PrivateRoute
                exact={true}
                history={this.props.history}
                path={services.router.url.standby}
                component={StandbyLists}
              />
              <PrivateRoute
                exact={false}
                history={this.props.history}
                path={services.router.url.standby_questions}
                component={StandbyQuestions}
              />
              <PrivateRoute
                exact={false}
                history={this.props.history}
                path={services.router.url.training_center}
                component={TrainingCenter}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
  pathname: state.router.location.pathname,
  router: state.router,
  isAuthenticationChecked: state.account.account.isAuthenticationChecked,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      checkAuthentication: accountActions.checkAuthentication,
      logout: accountActions.logout,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Homepage);
