import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import '../../assets/styles/Profile.css';
import { accountActions } from '../../features/account';
import { profileActions } from '../../features/profile';
import { IUser } from '../../models';
import AdjusterLogs from './AdjusterLogs';
import AdjusterProfileHeader from './AdjusterProfileHeader';
import ProfileCertifications from './ProfileCertifications';
import ProfileDetails from './ProfileDetails';
import ProfileFinances from './ProfileFinances';
import ProfileLicenses from './ProfileLicenses';
import ProfilePaperwork from './ProfilePaperwork';
import ProfileProfessionalSkills from './ProfileProfessionalSkills';
import ProfileResumes from './ProfileResumes';

interface Props {
  checkAuthentication: typeof accountActions.checkAuthentication;
  user: IUser;
  logout: typeof accountActions.logout;
  downloadFile: typeof accountActions.downloadFile;
  downloadPaperwork: typeof accountActions.downloadPaperwork;
  setActiveTab: typeof profileActions.setActiveTab;
  activeTab: string;
}

interface State {
  activeTab: string;
  submitRef: any;
}

class AdjusterProfile extends React.Component<Props, State> {
  public state = {
    activeTab: 'Profile',
    submitRef: '',
  };

  public handleTabs = (name: string) => {
    this.props.setActiveTab(name);
  };

  public getSubmitRef = (ref: any) => {
    this.setState({ submitRef: ref });
  };

  public componentDidMount = () => {
    this.setState({ activeTab: this.props.activeTab });
  };

  public componentDidUpdate = (prevProps: Props) => {
    if (prevProps.activeTab !== this.props.activeTab) {
      this.setState({ activeTab: this.props.activeTab }, this.renderTab);
    }
  };

  public renderTab = () => {
    const { activeTab } = this.state;
    const { user, downloadFile, downloadPaperwork } = this.props;

    if (activeTab !== 'Finances') {
      const header: HTMLElement = document.getElementById('account-header-title') as HTMLElement;
      if (header) header.innerText = 'Adjuster Details';
    }

    switch (activeTab) {
      case 'Profile':
        return (
          <ProfileDetails
            updateData={this.props.checkAuthentication}
            user={user}
            logout={this.props.logout}
            getSubmitRef={this.getSubmitRef}
          />
        );
      case 'Professional Skills':
        return (
          <ProfileProfessionalSkills
            updateData={this.props.checkAuthentication}
            user={user}
            getSubmitRef={this.getSubmitRef}
          />
        );
      case 'Licenses':
        return (
          <ProfileLicenses download={downloadFile} user={user} getSubmitRef={this.getSubmitRef} />
        );
      case 'Certifications':
        return (
          <ProfileCertifications
            download={downloadFile}
            user={user}
            getSubmitRef={this.getSubmitRef}
          />
        );
      case 'Resumes':
        return (
          <ProfileResumes download={downloadFile} user={user} getSubmitRef={this.getSubmitRef} />
        );
      case 'Paperwork':
        return (
          <ProfilePaperwork
            download={downloadPaperwork}
            user={user}
            getSubmitRef={this.getSubmitRef}
          />
        );
      case 'Finances':
        return (
          <ProfileFinances download={downloadFile} user={user} getSubmitRef={this.getSubmitRef} />
        );
      case 'Logs':
        return <AdjusterLogs user={user} getSubmitRef={this.getSubmitRef} />;
      default:
        return <div>No Info</div>;
    }
  };

  public render() {
    return (
      <div className="adjuster-profile-page">
        <AdjusterProfileHeader
          submitRef={this.state.submitRef}
          getTabName={this.handleTabs}
          activeTab={this.props.activeTab}
        />
        {this.renderTab()}
      </div>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
  pathname: state.router.location.pathname,
  isAuthenticationChecked: state.account.account.isAuthenticationChecked,
  activeTab: state.profile.profile.activeTab,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      checkAuthentication: accountActions.checkAuthentication,
      logout: accountActions.logout,
      downloadFile: accountActions.downloadFile,
      downloadPaperwork: accountActions.downloadPaperwork,
      setActiveTab: profileActions.setActiveTab,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdjusterProfile);
