import { action } from 'typesafe-actions';
import { IUser } from '../../models';
import { NewUser } from '../../models/newUser';
import * as constants from './constants';

export const checkAuthentication = () => action(constants.CHECK_AUTHENTICATION);

export const updateUserData = (user: IUser) => action(constants.UPDATE_USER_DATA, user);

export const loginAttempt = (email: string, password: string, redirect?: { url: string, token: string}) =>
  action(constants.LOGIN_ATTEMPT, {
    email,
    password,
    redirect,
  });

export const socialLoginAttempt = (token: string, phoneVerified: boolean) =>
  action(constants.SOCIAL_LOGIN_ATTEMPT, {
    token,
    phoneVerified,
  });

export const loginFailed = (error: string) =>
  action(constants.LOGIN_ATTEMPT_FAILED, {
    error,
  });

export const adminLogin = (accessToken: string) =>
  action(constants.ADMIN_LOGIN, {
    accessToken,
  });

export const loginSuccess = (accessToken: string, user: IUser) =>
  action(constants.LOGIN_SUCCESS, {
    accessToken,
    user,
  });

export const changeProgressValue = (value: number) =>
  action(constants.CHANGE_PROGRESS_VALUE, { value });

export interface RegisterAttemptModel {
  email: string;
  password: string;
  passwordConfirm: string;
}

export interface NewEmailModel {
  email: string;
}

export interface NewPasswordModel {
  password: string;
}

export interface ResetPasswordModel {
  token: string;
  password: string;
  passwordConfirm: string;
}

export const registerAttempt = (model: NewUser) => action(constants.REGISTER_ATTEMPT, model);

export const postRegisterFiles = (item: any, token: string) =>
  action(constants.POST_REGISTER_FILES, {
    item,
    token,
  });

export const isNewEmail = (model: NewEmailModel) => action(constants.REGISTER_IS_NEW_EMAIL, model);

export const NewPassword = (model: NewPasswordModel) =>
  action(constants.REGISTER_NEW_PASSWORD, model);

export const registerFailed = (errors: string[]) =>
  action(constants.REGISTER_ATTEMPT_FAILED, {
    errors,
  });

export const registerSuccess = (accessToken: string, user: IUser) =>
  action(constants.REGISTER_SUCCESS, {
    accessToken,
    user,
  });

export const recoverAttempt = (email: string) =>
  action(constants.RECOVER_ATTEMPT, {
    email,
  });

export const resetPasswordAttempt = (model: ResetPasswordModel) =>
  action(constants.RESET_PASSWORD_ATTEMPT, model);

export const changeRegisterStore = (model: object) =>
  action(constants.CHANGE_REGISTER_STORE, {
    ...model,
  });

export const submitSocialRegister = (email: string) =>
  action(constants.SUBMIT_SOCIAL_REGISTER, {
    email,
  });

export const setNewUserData = (model: NewUser) =>
  action(constants.SET_NEW_USER_DATA, {
    model,
  });

export const phoneConfirm = (phone: any) =>
  action(constants.PHONE_CONFIRM, {
    phone,
  });

export const phoneVerify = (code: any) =>
  action(constants.PHONE_VERIFY, {
    code,
  });

export const phoneConfirmAttempt = (token: any) =>
  action(constants.PHONE_CONFIRM_ATTEMPT, {
    token,
  });

export const downloadFile = (file: any) =>
  action(constants.DOWNLOAD_FILE, {
    file,
  });

export const registerNextStep = () => action(constants.REGISTER_NEXT_STEP);

export const registerPrevStep = () => action(constants.REGISTER_PREV_STEP);

export interface EmailVerificationAttemptModel {
  userId: string;
  verificationToken: string;
}

export const emailVerificationAttempt = (model: EmailVerificationAttemptModel) =>
  action(constants.EMAIL_VERIFICATION_ATTEMPT, model);

export const emailVerificationFailed = (errors: string[]) =>
  action(constants.EMAIL_VERIFICATION_ATTEMPT_FAILED, {
    errors,
  });

export const checkAuthenticationFinished = () => action(constants.CHECK_AUTHENTICATION_FINISH);

export const logout = () => action(constants.LOGOUT);

export const resetRegisterSteps = () => action(constants.RESET_REGISTER_STEPS);

export const downloadPaperwork = (file: any) => action(constants.DOWNLOAD_PAPERWORK, { file });

export const standbyListQuickSignUp = (model: any) => action(constants.STANDBY_LIST_QUICK_SIGN_UP, model);