import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';

import accountReducer from '../features/account/reducer';
import loadingReducer from '../features/loading/reducer';
import modalReducer from '../features/modal/reducer';
import profileReducer from '../features/profile/reducer';
import standbyReducer from '../features/standby/reducer';
import trainingCenterReducer from '../features/training-center/reducer';

// create browser history
export const history = createBrowserHistory();

const rootReducer = combineReducers({
  router: connectRouter(history),
  loading: loadingReducer,
  modal: modalReducer,
  account: accountReducer,
  profile: profileReducer,
  standby: standbyReducer,
  trainingCenter: trainingCenterReducer,
});

export default rootReducer;
