import { Form } from 'formsy-semantic-ui-react';
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Grid, Header, Image } from 'semantic-ui-react';
import compassLogo from '../../assets/images/compass-logo-vector.svg';
import { accountActions } from '../../features/account';
import ErrorLabel from '../error-label/ErrorLabel';
import PhoneInput from '../layout-input/PhoneInput';

interface Props {
  phoneConfirm: typeof accountActions.phoneConfirm;
  phoneVerify: typeof accountActions.phoneVerify;
  resetRegisterSteps: typeof accountActions.resetRegisterSteps;
}

interface State {
  disableButton: boolean;
  time: string;
  timer: any;
  min: number;
  sec: number;
}

class PhoneConfirmation extends React.Component<Props, State> {
  public state = {
    disableButton: false,
    time: '',
    timer: '',
    min: 4,
    sec: 59,
  };

  public componentDidMount = () => {
    this.props.resetRegisterSteps();
  };

  public timerCountdown = () => {
    let { min, sec } = this.state;
    const timeInterval = setInterval(() => {
      sec--;
      if (sec < 0 && min !== 0) {
        min--;
        sec = 59;
      }
      this.setState({ min, sec: sec }, () => {
        if (min === 0 && sec < 0) {
          this.setState({ disableButton: false, min: 4, sec: 59 });
        }
      });
    }, 1000);
    this.setState({ min, sec, timer: timeInterval });
  };

  public render() {
    const errorLabel = <ErrorLabel />;

    if (this.state.min === 0 && this.state.sec < 0) {
      clearInterval(this.state.timer);
    }

    return (
      <div className="phone-page">
        <div className="logo-container">
          <Image src={compassLogo} />
          <h3>
            We need to confirm your mobile phone number for account activation.
            <br />
            By registering you agree with us to send SMS notifications.
          </h3>
        </div>
        <Grid centered={true} style={{ height: '100%' }} verticalAlign="middle">
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as="h5" icon={true} textAlign="center" className="login-title-header">
              <Header.Content>Phone confirmation</Header.Content>
            </Header>
            <Form
              style={{ width: '100%' }}
              onValidSubmit={async (model: any) => {
                this.props.phoneConfirm(model.phone.replace(/[^0-9]/g, ''));
                this.setState({ disableButton: true });
                this.timerCountdown();
              }}
            >
              <div className="phone-field-container">
                <div className="phone-page-label">Phone# (Mobile)</div>
                <div className="phone-input-container">
                  <PhoneInput
                    name="phone"
                    instantValidation={false}
                    required={true}
                    value=""
                    validationErrors={{
                      isDefaultRequiredValue: `* Phone is required field.`,
                    }}
                    className="phone-input"
                    errorLabel={errorLabel}
                  />
                </div>
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '20px',
                }}
              >
                <Button
                  primary={true}
                  style={{ width: '250px', margin: '0 auto', height: '32px' }}
                  disabled={this.state.disableButton}
                >
                  Send Code
                </Button>
              </div>
            </Form>
            {this.state.disableButton && (
              <div className="time-counter-container">
                Request another in {this.state.min}:
                {this.state.sec < 10 ? `0${this.state.sec}` : this.state.sec}
              </div>
            )}

            <Form
              style={{ width: '100%', marginTop: '40px' }}
              onValidSubmit={(model: any) => {
                this.props.phoneVerify(model.code);
              }}
            >
              <div className="code-field-container">
                <div className="phone-page-label">Code from SMS</div>
                <Form.Input
                  name="code"
                  required={true}
                  validationErrors={{
                    isDefaultRequiredValue: '* Code is required field.',
                  }}
                  placeholder="Please Enter..."
                  style={{ width: '39%' }}
                  className="form-input"
                  errorLabel={errorLabel}
                />
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '20px',
                }}
              >
                <Button primary={true} style={{ width: '250px', height: '32px', margin: '0 auto' }}>
                  Submit
                </Button>
              </div>
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      phoneConfirm: accountActions.phoneConfirm,
      phoneVerify: accountActions.phoneVerify,
      resetRegisterSteps: accountActions.resetRegisterSteps,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhoneConfirmation);
