import * as account from './api/account';
import * as aws from './api/aws';
import * as file from './api/file';
import * as finance from './api/finance';
import * as social from './api/social';
import * as standby from './api/standby';
import * as user from './api/user';
import * as folder from './api/folder';
import * as material from './api/material';

export { account, aws, user, file, social, finance, standby, folder, material };
