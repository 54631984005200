import React from 'react';
import { Dropdown, Grid, Image, Responsive } from 'semantic-ui-react';
import { IUser } from '../../models';
import services from '../../services';
import RegisterProgress from '../register-profile-header/RegisterProgress';

interface Props {
  pathname: string;
  user: IUser | undefined;
}

interface State {
  headerTitle: string;
}

class AccountHeaderMobile extends React.Component<Props, State> {
  public state = {
    headerTitle: '',
  };

  public chooseTitle = (location: string) => {
    if (location.includes('/stand-by-lists/')) {
      return 'Standby List Details';
    } else {
      switch (location) {
        case '/':
          return 'Adjuster Details';
        case '/stand-by-lists':
          return 'Standby Lists';
        case '/training-center':
          return 'Training Center';
        default:
          return '';
      }
    }
  };

  public componentDidMount() {
    this.setState({ headerTitle: this.chooseTitle(this.props.pathname) });
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.pathname !== this.props.pathname) {
      this.setState({ headerTitle: this.chooseTitle(this.props.pathname) });
    }
  }

  public render() {
    return (
      <div className="account-header-mobile-score">
        <div className="account-header-title">{this.state.headerTitle}</div>
        {this.props.pathname === '/' && (
          <RegisterProgress
            progress={this.props.user ? Number(this.props.user.profileScore).toFixed() : 0}
          />
        )}
      </div>
    );
  }
}

export default AccountHeaderMobile;
