import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Table, Image, Popup, Button } from 'semantic-ui-react';
import { MaterialModel, PaginationInfo } from '../../../features/training-center/reducer';
import moment from 'moment';
import noAvatarIcon from '../../../assets/images/no-avatar.svg';
import downloadIcon from '../../../assets/images/Blue/download.svg';
import { uploads } from '../../../config';
import { isNull, isUndefined } from 'lodash';
import './MaterialListView.css';
import CustomIconicButton from '../../custom-iconic-button/CustomIconicButton';
import checkIconW from '../../../assets/images/White/check.svg';
import checkIconB from '../../../assets/images/Blue/check.svg';
import { trackPromise } from 'react-promise-tracker';
import services from '../../../services';
import { truncateString } from '../../../utils/helper';

interface Props {
  pagingInfo: PaginationInfo;
  downloadMaterial: (material: MaterialModel) => void;
  onMaterialClick: (material: MaterialModel) => void;
  selectMaterial: (material: MaterialModel) => void;
  selectedMaterials: Array<number>;
}

interface State {}

class MaterialListView extends React.Component<Props, State> {
  public renderSelectCheckBox = (material: MaterialModel) => {
    const { selectedMaterials } = this.props;
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Popup
          trigger={
            <Button
              type="button"
              className={
                selectedMaterials.find(el => el === material.id)
                  ? 'check-button active iconic-button-custom'
                  : 'check-button iconic-button-custom'
              }
              onClick={event => {
                event.stopPropagation();
                this.props.selectMaterial(material);
              }}
            >
              <Image
                src={selectedMaterials.find(el => el === material.id) ? checkIconW : checkIconB}
              />
            </Button>
          }
          basic={true}
          size="mini"
          content={selectedMaterials.find(el => el === material.id) ? 'Deselect' : 'Select'}
        />
      </div>
    );
  };

  public renderTableHeader() {
    return (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell style={{ width: 50 }} />
          <Table.HeaderCell>Created Date</Table.HeaderCell>
          <Table.HeaderCell>File Name</Table.HeaderCell>
          <Table.HeaderCell>Creator</Table.HeaderCell>
          <Table.HeaderCell>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
    );
  }

  public renderTableActions(material: MaterialModel) {
    return (
      <div style={{ display: 'flex' }}>
        {material.type === 'document' && (
          <div>
            <CustomIconicButton
              popup={'Download'}
              image={downloadIcon}
              onPress={(event: any) => {
                event.stopPropagation();
                this.props.downloadMaterial(material);
              }}
            />
          </div>
        )}
      </div>
    );
  }

  public getAdminAvatar(admin: any) {
    if (isNull(admin) || isUndefined(admin)) {
      return noAvatarIcon;
    }

    if (admin.avatar) {
      return uploads.s3BucketAddress + admin.avatar;
    }

    return noAvatarIcon;
  }

  public getAdminName(admin: any) {
    if (isNull(admin) || isUndefined(admin)) {
      return '';
    }

    return admin.firstName + ' ' + admin.lastName;
  }

  public renderTableBody(records: Array<MaterialModel> = []) {
    return (
      <Table.Body>
        {records.map((material, i: number) => (
          <Table.Row key={material.id}>
            <Table.Cell className="table-date" onClick={() => this.props.onMaterialClick(material)}>
              {this.renderSelectCheckBox(material)}
            </Table.Cell>
            <Table.Cell className="table-date" onClick={() => this.props.onMaterialClick(material)}>
              {moment(material.updatedAt).format('MM/DD/YYYY, hh:mm A')}
            </Table.Cell>
            <Table.Cell style={{ width: '100%' }} className="table-name">
              {material.type === 'document' ? (
                <a
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={e => {
                    // e.stopPropagation()
                    this.openFile(material);
                  }}
                >
                  {truncateString(material.name, 20)}
                </a>
              ) : (
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.viewVideoUrl(material.videoUrl);
                  }}
                >
                  {truncateString(material.name, 80)}
                </a>
              )}
            </Table.Cell>
            <Table.Cell
              className="table-image"
              onClick={() => this.props.onMaterialClick(material)}
            >
              <Image circular={true} src={this.getAdminAvatar(material.admin)} avatar={true} />
              {this.getAdminName(material.admin)}
            </Table.Cell>
            <Table.Cell style={{ width: 250 }} className="table-actions">
              {this.renderTableActions(material)}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    );
  }

  public async openFile(material: MaterialModel) {
    const url = await services.api.file.getDownloadFileUrl(material.fileKey);

    if (material.mimetype.includes('image')) {
      // location.assign(url);
      window.open(url, '_blank');
    } else {
      const previewLink = `https://docs.google.com/gview?url=${url}&embedded=true`;
      // location.assign(previewLink);
      window.open(previewLink, '_blank');
    }
  }

  public viewVideoUrl = (url: string) => {
    window.open(url, '_blank');
  };

  public render() {
    const { pagingInfo } = this.props;
    const { records } = pagingInfo;
    if (!records || records.length === 0) {
      return null;
    }
    return (
      <Table
        compact={true}
        striped={true}
        stackable={true}
        singleLine={true}
        className="data-table materials-table"
      >
        {this.renderTableHeader()}
        {this.renderTableBody(records)}
      </Table>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  pagingInfo: state.trainingCenter.material.pagingInfo,
  selectedMaterials: state.trainingCenter.material.pagingInfo.selected,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MaterialListView);
