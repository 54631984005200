import {trackPromise} from 'react-promise-tracker';
import {PaginationInfo} from '../../features/training-center/reducer';
import {request} from './base';

export interface MaterialFilter {
  activePage: number;
  pageSize?: number;
  search?: string;
  fileType?: string;
  sortByField?: string;
  sortType?: 'ASC' | 'DESC';
  noPagination?: boolean;
  folderIds?: string;
}

export const searchMaterial = (adjusterID: number, filters: MaterialFilter, paginationInfo: PaginationInfo) => {
  return trackPromise(
    request({
      method: 'GET',
      url: `/adjuster/${adjusterID}/my-materials?page=${paginationInfo.activePage - 1}&pageSize=${paginationInfo.pageSize}`,
      params: {
        ...filters,
      },
    }),
    'material'
  );
};

export const getById = (id: any) => {
  return request({
    method: 'get',
    url: `/material/${id}`,
  });
};
