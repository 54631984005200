import { trackPromise } from 'react-promise-tracker';
import { request } from './base';

export const getStandbyList = (
  model: any,
  sort: string,
  closed: any,
  status: any,
  title?: string
) => {
  return trackPromise(
    request({
      method: 'post',
      url: `/standby-list/list/${model.id}?page=${model.page}&pageSize=10`,
      data: {
        closed,
        title,
        status,
        sort,
      },
    }),
    'standby-list'
  );
};

export const getStandbyListAllActive = (model: any, order: any, title?: string) => {
  return trackPromise(
    request({
      method: 'get',
      url: `/standby-list/list/active/${model.id}?page=${model.page}&pageSize=10`,
      params: {
        title,
        order,
      },
    }),
    'standby-list'
  );
};

export const getListDetails = (id: any) => {
  return request({
    method: 'get',
    url: `/standby-list/details/${id}`,
  });
};

export const getListDetailsForGuest = (id: number, quicksignup: number) => {
  return request({
    method: 'get',
    url: `/standbylist/guests/details/${id}/${quicksignup}`,
  });
};

export const submitAnswers = (answers: any, standbyId: any, adjusterId: any, quicksignup: any = null) => {
  return request({
    method: 'post',
    url: quicksignup ? `/standbylist/guests/${standbyId}/apply/${quicksignup}` : `/standby-list/${standbyId}/apply/${adjusterId}`,
    data: {
      answers,
    },
  });
};

export const getAnswers = (standbyId: any, adjusterId: any) => {
  return request({
    method: 'get',
    url: `/standby-list/${standbyId}/answers/${adjusterId}?page=${0}&pageSize=25`,
  });
};

export const getStandbyListForGuest = (publicId: string) => {
  return request({
    method: 'get',
    url: `/standbylist/guests/${publicId}`,
  });
};
