import React from 'react';
import { toast } from 'react-toastify';
import { Button, Dropdown, Image, Popup } from 'semantic-ui-react';
import avatarPlaceholder from '../../assets/images/no-avatar.svg';
import editIcon from '../../assets/images/White/edit.svg';
import { uploads } from '../../config';
import services from '../../services';
import AvatarScaled from './AvatarScaled';

interface Props {
  getAvatar: (file: any, avatarEdited: boolean) => void;
  getAvatarFields: (url: string, data: FormData) => void;
  noEdit: boolean;
  hasToken: boolean;
  avatar: any;
  registerPage?: boolean;
}

interface State {
  file: string | ArrayBuffer | null;
  showIcon: boolean;
  showScaled: boolean;
}

class AvatarInput extends React.Component<Props, State> {
  public state = {
    file: '',
    showIcon: false,
    showScaled: false,
  };

  public fileInputRef = React.createRef();

  public fileChange = async (event: any) => {
    if (event.target.files.length !== 0) {
      const file = event.target.files[0];
      if (file.size > uploads.maxFileSize) {
        return toast.warn('Max size limit for avatar is 5MB!');
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
        this.setState({ file: reader.result });
      };
      if (this.props.hasToken) {
        const resp = await services.api.file.uploadAvatar({
          name: file.name,
          type: file.type,
          size: file.size,
        });
        if (resp.isSuccess) {
          const data = resp.data.url.fields;
          this.props.getAvatar(data.key, true);
          const obj = Object.assign({}, data, {
            file,
          });
          const formData = new FormData();
          for (const key in obj) {
            if (undefined === obj[key]) {
              continue;
            }
            formData.append(key, obj[key]);
          }
          this.props.getAvatarFields(resp.data.url.url, formData);
        } else {
          this.props.getAvatar(this.state.file, false);
        }
      } else {
        this.props.getAvatar(
          {
            file: {
              file,
              fileName: file.name,
              fileType: file.type,
              fileSize: file.size,
            },
          },
          true
        );
      }
    }
  };

  public showScaled = (show: boolean) => {
    this.setState({ showScaled: show });
  };

  public componentDidMount() {
    this.setState({ file: this.props.avatar });
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.avatar !== this.props.avatar) {
      this.setState({ file: this.props.avatar });
    }
  }

  public render() {
    const trigger = (
      <Popup
        trigger={
          <Button className="avatar-edit-icon-button" type="button">
            <Image className="icon" src={editIcon} />
          </Button>
        }
        size="mini"
        basic={true}
        content="Change Photo"
      />
    );

    return (
      <React.Fragment>
        <div
          className={`avatar-input ${this.props.noEdit ? 'no-edit-avatar' : ''}`}
          onMouseEnter={() => this.setState({ showIcon: true })}
          onMouseLeave={() => this.setState({ showIcon: false })}
          onDoubleClick={() => {
            this.state.file === '' ||
            !this.state.file ||
            this.state.file === avatarPlaceholder ||
            this.props.registerPage
              ? console.log('no-avatar')
              : this.setState({ showScaled: true });
          }}
        >
          <Image
            src={
              this.state.file === '' || !this.state.file
                ? avatarPlaceholder
                : this.state.file.name
                ? URL.createObjectURL(this.state.file)
                : this.state.file
            }
          />
          {this.state.showIcon && !this.props.noEdit && (
            <div className="avatar-edit-icon">
              <Dropdown trigger={trigger} icon={false} className="profile-avatar-dropdown">
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => this.fileInputRef.current.click()}>
                    Upload
                  </Dropdown.Item>
                  {this.state.file !== '' && this.state.file !== avatarPlaceholder && (
                    <Dropdown.Item
                      onClick={() =>
                        this.setState({ file: '' }, () => this.props.getAvatar('', true))
                      }
                    >
                      Delete
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
          <input
            ref={this.fileInputRef}
            type="file"
            accept="image/jpeg, image/png"
            hidden={true}
            value=""
            onChange={this.fileChange}
          />
        </div>
        <AvatarScaled
          avatar={
            this.state.file === '' || !this.state.file
              ? avatarPlaceholder
              : this.state.file.name
              ? URL.createObjectURL(this.state.file)
              : this.state.file
          }
          showScaled={this.showScaled}
          show={this.state.showScaled}
        />
      </React.Fragment>
    );
  }
}

export default AvatarInput;
