import { action } from 'typesafe-actions';
import * as constants from './constants';

export const getStandbyList = (filterOptions?: object) =>
  action(constants.GET_STANDBY_LIST, { filterOptions });

export const submitStandbyList = (list: object[], count: number) =>
  action(constants.SUBMIT_STANDBY_LIST, { list, count });

export const editStandbyList = (edit: boolean) => action(constants.EDIT_STANDBY_LIST, { edit });

export const setStandbyTab = (tab: string) => action(constants.SET_STANDBY_TAB, { tab });

export const setStandbySort = (sort: any) => action(constants.SET_STANDBY_SORT, { sort });

export const setStandbySearch = (search: string) =>
  action(constants.SET_STANDBY_SEARCH, { search });

export const setActiveStandby = (id: any) => action(constants.SET_ACTIVE_STANDBY, { id });
export const setActiveStandbyReloaded = (id: any) =>
  action(constants.SET_ACTIVE_STANDBY_RELOADED, { id });
export const submitActiveStandbyReloaded = (list: any) =>
  action(constants.SUBMIT_ACTIVE_STANDBY_RELOADED, { list });

export const showInviteMessage = (show: boolean) => action(constants.SHOW_INVITE_MESSAGE, { show });
export const showInviteModal = (show: boolean) => action(constants.SHOW_INVITE_MODAL, { show });

export const setSelectedId = (arrayId: object[]) => action(constants.SET_SELECTED_ID, { arrayId });

export const getSelectedAdjusters = () => action(constants.GET_SELECTED_ADJUSTERS);

export const setActivePage = (page: number) => action(constants.SET_ACTIVE_PAGE, { page });

export const setRedirectStandby = (id: any) => action(constants.SET_REDIRECT_STANDBY, { id });
