import { Button, Image, Modal } from 'semantic-ui-react';
import closeIcon from '../../../assets/images/Blue/cancel.svg';
import React from 'react';
import { MaterialModel } from '../../../features/training-center/reducer';
import DocumentDetails from './DocumentDetails';
import EmbeddedVideoDetails from './EmbeddedVideoDetails';
import './MaterialDetails.css';

interface Props {
  material: MaterialModel;
  onClose: () => void;
  onDownload: () => void;
}

interface State {}

export default class MaterialDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  public renderMaterial = () => {
    const { material } = this.props;
    if (material.type === 'document') {
      return (
        <DocumentDetails
          name={material.name}
          description={material.description}
          lastUpdatedOn={material.updatedAt as string}
          folders={material.folders.map(f => f.name)}
          fileType={material.type}
          fileKey={material.fileKey}
          mimetype={material.mimetype}
        />
      );
    }

    return (
      <EmbeddedVideoDetails
        url={material.videoUrl}
        name={material.name}
        description={material.description}
        lastUpdatedOn={material.updatedAt as string}
        folders={material.folders.map(f => f.name)}
      />
    );
  };

  viewUrl = () => {
    window.open(this.props.material.videoUrl, '_blank');
  };

  public render() {
    return (
      <Modal
        open
        className="material-details-modal"
        size="small"
        closeIcon={<Image src={closeIcon} className="icon" onClick={this.props.onClose} />}
      >
        <Modal.Header>File details</Modal.Header>
        <Modal.Content>{this.renderMaterial()}</Modal.Content>
        <div className="modal-button-footer">
          <div className="modal-buttons-right">
            {this.props.material.type === 'document' ? (
              <Button primary={true} onClick={this.props.onDownload}>
                Download
              </Button>
            ) : (
              <Button primary={true} onClick={this.viewUrl}>
                GO TO VIDEO URL URL
              </Button>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}
