import { withFormsy } from 'formsy-react';
import { Form } from 'formsy-semantic-ui-react';
import React, { cloneElement } from 'react';
import { Button, Image, Popup, Responsive } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import services from '../../services';
import ErrorLabel from '../error-label/ErrorLabel';

interface Props {
  item: object;
  allItems: string[];
  id: number;
  delete: (id: number) => void;
  edit: (id: number, item: any) => void;
  getValue: () => any;
  setValue: (val: any) => any;
  isFormSubmitted: () => any;
  value: any;
}

interface State {
  lossOptions: object[];
  items: object[];
  type: string | undefined;
  residentialCATClaims: number | undefined;
  commercialCATClaims: number | undefined;
  residentialDailyClaims: number | undefined;
  commercialDailyClaims: number | undefined;
}

class LossTypeItem extends React.Component<Props, State> {
  public state = {
    lossOptions: [],
    items: [],
    type: undefined,
    residentialCATClaims: 0,
    commercialCATClaims: 0,
    residentialDailyClaims: 0,
    commercialDailyClaims: 0,
  };

  public componentDidMount = async () => {
    const { item } = this.props;

    this.setState(
      {
        type: item.type,
        residentialCATClaims: item.residentialCATClaims,
        commercialCATClaims: item.commercialCATClaims,
        residentialDailyClaims: item.residentialDailyClaims,
        commercialDailyClaims: item.commercialDailyClaims,
      },
      this.validateChange
    );

    const response = await services.api.account.getSettingsData('lossType');
    if (response.isSuccess) {
      const lossOptions = response.data.list.map((el: any, index: number) => {
        return { value: el.name, text: el.name };
      });
      this.setState({ lossOptions });
    } else {
      console.log(response);
    }
  };

  public componentDidUpdate = (prevProps: Props) => {
    if (prevProps.item.type !== this.props.item.type && this.props.item.type !== '') {
      this.setState(
        {
          type: this.props.item.type,
          residentialCATClaims: this.props.item.residentialCATClaims,
          commercialCATClaims: this.props.item.commercialCATClaims,
          residentialDailyClaims: this.props.item.residentialDailyClaims,
          commercialDailyClaims: this.props.item.commercialDailyClaims,
        },
        this.validateChange
      );
    }
    if (prevProps.allItems.length !== this.props.allItems.length) {
      this.setState(
        {
          type: this.props.item.type,
          residentialCATClaims: this.props.item.residentialCATClaims,
          commercialCATClaims: this.props.item.commercialCATClaims,
          residentialDailyClaims: this.props.item.residentialDailyClaims,
          commercialDailyClaims: this.props.item.commercialDailyClaims,
        },
        this.validateChange
      );
    }
  };

  public handleChange = (name: any, value: any) => {
    switch (name) {
      case 'residentialCATClaims':
        this.setState({ residentialCATClaims: Number(value) }, this.validateChange);
        break;
      case 'commercialCATClaims':
        this.setState({ commercialCATClaims: Number(value) }, this.validateChange);
        break;
      case 'residentialDailyClaims':
        this.setState({ residentialDailyClaims: Number(value) }, this.validateChange);
        break;
      case 'commercialDailyClaims':
        this.setState({ commercialDailyClaims: Number(value) }, this.validateChange);
        break;
      default:
        this.setState({ type: value }, this.validateChange);
        break;
    }
  };

  public editItem = () => {
    const {
      type,
      residentialCATClaims,
      commercialCATClaims,
      residentialDailyClaims,
      commercialDailyClaims,
    } = this.state;
    this.props.edit(this.props.item.id, {
      type,
      residentialCATClaims,
      commercialCATClaims,
      residentialDailyClaims,
      commercialDailyClaims,
      id: this.props.item.id,
    });
  };

  public validateChange = () => {
    const {
      type,
      residentialCATClaims,
      commercialCATClaims,
      residentialDailyClaims,
      commercialDailyClaims,
    } = this.state;
    if (type !== undefined && type !== null && type !== '') {
      this.props.setValue({
        type,
        residentialCATClaims: Number(residentialCATClaims),
        commercialCATClaims: Number(commercialCATClaims),
        residentialDailyClaims: Number(residentialDailyClaims),
        commercialDailyClaims: Number(commercialDailyClaims),
        id: this.props.item.id,
      });
      this.editItem();
    } else {
      this.props.setValue(undefined);
    }
  };

  public render() {
    const {
      item,
      id,
      errorLabel,
      validationErrors,
      isFormSubmitted,
      isPristine,
      isValid,
    } = this.props;
    const errorLabelInd = <ErrorLabel />;
    const error = isFormSubmitted() && !isValid();
    return (
      <>
        <Responsive minWidth={665} className="responsive-loss-item">
          <>
            <div className="expertise-group">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%',
                }}
              >
                {id === 0 && (
                  <p style={{ marginTop: '1.15rem' }} className="loss-item-label">
                    Loss Type
                  </p>
                )}
                <Form.Dropdown
                  search={true}
                  selectOnBlur={false}
                  options={this.state.lossOptions}
                  name={`type-${this.props.id}`}
                  placeholder="Please Select"
                  instantValidation={false}
                  required={true}
                  validations={{
                    hasSameLoss: true,
                  }}
                  className="form-input-dropdown short"
                  onChange={(e: any, data: any) => this.handleChange(data.name, data.value)}
                  value={item.type}
                  validationErrors={{
                    hasSameLoss: '* Loss Type values should be unique.',
                  }}
                  errorLabel={errorLabelInd}
                />
              </div>
              <div>
                {id === 0 && <p className="loss-item-label">#Residential CAT Claims</p>}
                <Form.Input
                  type="number"
                  name="residentialCATClaims"
                  placeholder="0"
                  instantValidation={false}
                  required={true}
                  className="form-input-years"
                  onChange={(e: any, data: any) => this.handleChange(data.name, data.value)}
                  value={item.residentialCATClaims ? item.residentialCATClaims : 0}
                />
              </div>
              <div>
                {id === 0 && <p className="loss-item-label">#Commercial CAT Claims</p>}
                <Form.Input
                  type="number"
                  name="commercialCATClaims"
                  placeholder="0"
                  instantValidation={false}
                  required={true}
                  className="form-input-years"
                  onChange={(e: any, data: any) => this.handleChange(data.name, data.value)}
                  value={item.commercialCATClaims ? item.commercialCATClaims : 0}
                />
              </div>
              <div>
                {id === 0 && <p className="loss-item-label">#Residential Daily Claims</p>}
                <Form.Input
                  type="number"
                  name="residentialDailyClaims"
                  placeholder="0"
                  instantValidation={false}
                  required={true}
                  className="form-input-years"
                  onChange={(e: any, data: any) => this.handleChange(data.name, data.value)}
                  value={item.residentialDailyClaims ? item.residentialDailyClaims : 0}
                />
              </div>
              <div className="expertise-last-item">
                {id === 0 && <p className="loss-item-label">#Commercial Daily Claims</p>}
                <Form.Input
                  type="number"
                  name="commercialDailyClaims"
                  placeholder="0"
                  instantValidation={false}
                  required={true}
                  className="form-input-years"
                  onChange={(e: any, data: any) => this.handleChange(data.name, data.value)}
                  value={item.commercialDailyClaims ? item.commercialDailyClaims : 0}
                />
              </div>
              <Popup
                trigger={
                  <Button
                    type="button"
                    className={`iconic-button-custom delete-exp-button ${
                      this.props.id === 0 ? 'first-item-cancel' : ''
                    }`}
                    onClick={() => this.props.delete(item.id)}
                    disabled={this.props.allItems.length === 1}
                  >
                    <Image src={closeIcon} />
                  </Button>
                }
                size="mini"
                basic={true}
                content="Delete"
              />
            </div>
            {error &&
              errorLabel &&
              cloneElement(errorLabel, {}, validationErrors.isDefaultRequiredValue)}
          </>
        </Responsive>

        <Responsive maxWidth={664}>
          <>
            <div className="expertise-mobile-full-container">
              <div
                className="expertise-mobile-container"
                style={{
                  flexDirection: 'column',
                  marginBottom: '5px',
                }}
              >
                <label style={{ marginBottom: '5px' }} className="loss-item-label-mobile">
                  Loss Type
                </label>
                <Form.Dropdown
                  selectOnBlur={false}
                  search={true}
                  options={this.state.lossOptions}
                  name={`type-${this.props.id}`}
                  placeholder="Please Select"
                  instantValidation={false}
                  required={true}
                  validations={{
                    hasSameLoss: true,
                  }}
                  className="form-input-dropdown short"
                  onChange={(e: any, data: any) => this.handleChange(data.name, data.value)}
                  value={item.type}
                  validationErrors={{
                    hasSameLoss: '* Loss Type values should be unique.',
                  }}
                  errorLabel={errorLabelInd}
                />
              </div>
              <div
                className="expertise-mobile-container"
                style={{
                  flexDirection: 'row',
                  marginBottom: '5px',
                }}
              >
                <label className="loss-item-label-mobile">#Residential CAT Claims</label>
                <Form.Input
                  type="number"
                  name="residentialCATClaims"
                  placeholder="0"
                  instantValidation={false}
                  required={true}
                  className="form-input-years"
                  onChange={(e: any, data: any) => this.handleChange(data.name, data.value)}
                  value={item.residentialCATClaims ? item.residentialCATClaims : 0}
                />
              </div>
              <div
                className="expertise-mobile-container"
                style={{
                  flexDirection: 'row',
                  marginBottom: '5px',
                }}
              >
                <label className="loss-item-label-mobile">#Commercial CAT Claims</label>
                <Form.Input
                  type="number"
                  name="commercialCATClaims"
                  placeholder="0"
                  instantValidation={false}
                  required={true}
                  className="form-input-years"
                  onChange={(e: any, data: any) => this.handleChange(data.name, data.value)}
                  value={item.commercialCATClaims ? item.commercialCATClaims : 0}
                />
              </div>
              <div
                className="expertise-mobile-container"
                style={{
                  flexDirection: 'row',
                  marginBottom: '5px',
                }}
              >
                <label className="loss-item-label-mobile">#Residential Daily Claims</label>
                <Form.Input
                  type="number"
                  name="residentialDailyClaims"
                  placeholder="0"
                  instantValidation={false}
                  required={true}
                  className="form-input-years"
                  onChange={(e: any, data: any) => this.handleChange(data.name, data.value)}
                  value={item.residentialDailyClaims ? item.residentialDailyClaims : 0}
                />
              </div>
              <div
                className="expertise-mobile-container"
                style={{
                  flexDirection: 'row',
                  marginBottom: '5px',
                }}
              >
                <label className="loss-item-label-mobile">#Commercial Daily Claims</label>
                <Form.Input
                  type="number"
                  name="commercialDailyClaims"
                  placeholder="0"
                  instantValidation={false}
                  required={true}
                  className="form-input-years"
                  onChange={(e: any, data: any) => this.handleChange(data.name, data.value)}
                  value={item.commercialDailyClaims ? item.commercialDailyClaims : 0}
                />
              </div>
              <Popup
                trigger={
                  <Button
                    type="button"
                    className={`iconic-button-custom delete-exp-button`}
                    onClick={() => this.props.delete(item.id)}
                    disabled={this.props.allItems.length === 1}
                  >
                    <Image src={closeIcon} />
                  </Button>
                }
                size="mini"
                basic={true}
                content="Delete"
              />
            </div>
            {error &&
              errorLabel &&
              cloneElement(errorLabel, {}, validationErrors.isDefaultRequiredValue)}
          </>
        </Responsive>
      </>
    );
  }
}

export default withFormsy(LossTypeItem);
