import React from 'react';
import { Dropdown, Icon, Image, Menu, Responsive } from 'semantic-ui-react';
import compassLogo from '../../assets/images/compass-logo-vector.svg';
import menuIcon from '../../assets/images/Mobile/top-menu.svg';
import '../../assets/styles/Header.css';
import { IUser } from '../../models';
import services from '../../services';

interface Props {
  user?: IUser;
  triggerSidebar: () => void;
  logout: () => void;
  hideWarningMessage?: boolean;
  pathname: string;
  step: number;
}

interface State {}

class LayoutHeader extends React.Component<Props, State> {
  public render() {
    const trigger = (
      <div>
        <Image src={menuIcon} />
      </div>
    );
    return (
      <div>
        <Responsive maxWidth={599}>
          <div className="main-header">
            <div className="header-logo-container">
              <Image src={compassLogo} />
            </div>
            <div className="header-menu register-header-mobile">
              <Dropdown trigger={trigger} icon={false} className="register-dropdown">
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => window.open('http://www.compassadjusters.com', '_blank')}
                  >
                    <span>More Info</span>
                  </Dropdown.Item>
                  {!services.localStorage.get('accessToken') && (
                    <Dropdown.Item
                      onClick={() => services.router.goto(services.router.url.register)}
                    >
                      <span>Register</span>
                    </Dropdown.Item>
                  )}
                  {!services.localStorage.get('accessToken') && (
                    <Dropdown.Item
                      onClick={() => {
                        services.localStorage.remove('accessToken');
                        services.router.goto(services.router.url.login);
                      }}
                    >
                      <span>Login</span>
                    </Dropdown.Item>
                  )}
                  {window.location.pathname.includes('/phone-confirmation') &&
                    services.localStorage.get('accessToken') && (
                      <Dropdown.Item onClick={() => this.props.logout()}>
                        <span>Logout</span>
                      </Dropdown.Item>
                    )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </Responsive>

        <Responsive minWidth={600}>
          <div className="main-header">
            <div className="position-left">
              <Menu borderless={true} className="header-menu">
                <Menu.Item
                  header={true}
                  link={true}
                  onClick={() => window.open('http://www.compassadjusters.com', '_blank')}
                >
                  More Info
                </Menu.Item>
              </Menu>
            </div>

            {this.props.step >= 3 && this.props.pathname === '/create-free-account' && (
              <div className="header-logo-container">
                <Image src={compassLogo} />
              </div>
            )}

            <div className="position-right">
              <Menu borderless={true} className="header-menu">
                {!services.localStorage.get('accessToken') && (
                  <Menu.Item
                    className={
                      this.props.pathname === '/create-free-account' ? 'active-route-header' : ''
                    }
                    as="a"
                    onClick={() => services.router.goto(services.router.url.register)}
                  >
                    Register
                  </Menu.Item>
                )}
                {!services.localStorage.get('accessToken') && (
                  <Menu.Item
                    className={this.props.pathname === '/login' ? 'active-route-header' : ''}
                    as="a"
                    onClick={() => {
                      services.localStorage.remove('accessToken');
                      services.router.goto(services.router.url.login);
                    }}
                  >
                    Login
                  </Menu.Item>
                )}
                {window.location.pathname.includes('/phone-confirmation') &&
                  services.localStorage.get('accessToken') && (
                    <Menu.Item as="a" onClick={() => this.props.logout()}>
                      Logout
                    </Menu.Item>
                  )}
              </Menu>
            </div>
          </div>
        </Responsive>
      </div>
    );
  }
}

export default LayoutHeader;
