import { Form } from 'formsy-semantic-ui-react';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Confirm, Image, Loader, Modal, Popup } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import { uploads } from '../../config';
import ErrorLabel from '../error-label/ErrorLabel';
import UploadZone from './../file-uploader/UploadZone';

interface Props {
  editResume: (id: number, item: object) => void;
  showModal: (show: boolean, id?: number) => void;
  show: boolean;
  item: object;
}

interface State {
  showFile: boolean;
  showConfirm: boolean;
  file: string | ArrayBuffer | null;
  fileInitial: any;
  fileType: string;
  fileName: string;
  fileSize: string | number;
  fileError: boolean;
  fileErrorString: string;
  fileKey: string | undefined;
}

class EditResumeModal extends React.Component<Props, State> {
  public state = {
    showFile: false,
    showConfirm: false,
    file: '',
    fileInitial: '',
    fileType: '',
    fileName: '',
    fileSize: 0,
    fileError: false,
    fileErrorString: '',
    fileKey: '',
  };

  public fileInputRef = React.createRef();

  public componentDidMount() {
    if (this.props.item) {
      this.setState({
        showFile: this.props.item.file.file !== '',
        file: this.props.item.file.file,
        fileName: this.props.item.file.name || this.props.item.file.fileName,
        fileType: this.props.item.file.type || this.props.item.file.fileType,
        fileSize: this.props.item.file.size || this.props.item.file.fileSize,
        fileKey: this.props.item.file.key || this.props.item.file.fileKey,
        fileInitial: this.props.item.file.fileInitial ? this.props.item.file.fileInitial : '',
      });
    }
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.item !== this.props.item) {
      if (this.props.item) {
        this.setState({
          showFile: this.props.item.file.file !== '',
          file: this.props.item.file.file,
          fileName: this.props.item.file.name || this.props.item.file.fileName,
          fileType: this.props.item.file.type || this.props.item.file.fileType,
          fileSize: this.props.item.file.size || this.props.item.file.fileSize,
          fileKey: this.props.item.file.key || this.props.item.file.fileKey,
          fileInitial: this.props.item.file.fileInitial ? this.props.item.file.fileInitial : '',
        });
      }
    }
  }

  public handleFileDrop = async (files: any) => {
    const file = files[0];
    if (!file) {
      this.setState({
        fileError: true,
        fileErrorString: '* Only *.pdf, *.doc, *.docx, *.rtf files are supported.',
      });
    } else {
      if (file.size > uploads.maxFileSize) {
        return toast.warn(uploads.maxFileSizeErrorMsg);
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
        this.setState({
          file: reader.result,
          fileInitial: file,
          showFile: true,
          fileType: file.type,
          fileSize: file.size,
          fileError: false,
          fileName: file.name,
          fileKey: undefined,
        });
      };
    }
  };

  public render() {
    const { item } = this.props;

    if (!item) {
      return <Loader size="massive">Loading</Loader>;
    }

    const errorLabel = <ErrorLabel />;
    const fileType = this.state.fileType ? this.state.fileType : item.file.fileType;

    return (
      <Modal
        size="tiny"
        className="licenses-modal"
        open={this.props.show}
        closeIcon={
          <Image src={closeIcon} className="icon" onClick={() => this.props.showModal(false)} />
        }
      >
        <Modal.Header>Edit Resume</Modal.Header>
        <Form
          onValidSubmit={(model: any) => {
            if (this.state.file === '') {
              this.setState({ fileErrorString: '* Resume file is required.', fileError: true });
              return;
            }
            const dateToday = new Date();
            const obj = Object.assign(
              {},
              { name: model.name },
              { id: item.id },
              {
                file: this.state.fileKey
                  ? {
                      fileKey: this.state.fileKey,
                      fileType: this.state.fileType,
                      fileSize: this.state.fileSize,
                      fileName: this.state.fileName,
                    }
                  : {
                      fileInitial: this.state.fileInitial,
                      file: this.state.file,
                      fileName: this.state.fileName,
                      fileType: this.state.fileType,
                      fileSize: this.state.fileSize,
                    },
              },
              { createdAt: dateToday.toLocaleDateString() }
            );
            this.props.editResume(item.id, obj);
            this.props.showModal(false);
          }}
        >
          <Modal.Content style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
            <div className="input-field-container">
              <div className="input-field-label">File Name</div>
              <Form.Input
                name="name"
                maxLength={100}
                placeholder="Please Enter..."
                instantValidation={false}
                required={true}
                validationErrors={{
                  isDefaultRequiredValue: `* File Name is required field.`,
                }}
                className="form-input"
                errorLabel={errorLabel}
                value={item.name}
              />
            </div>
            <div className="input-field-container">
              <div className="input-field-label">Resume</div>
              <div />
            </div>
            {!this.state.showFile ? (
              <div>
                <UploadZone onDrop={this.handleFileDrop} accept=".doc, .docx, .pdf, .rtf" />
                {this.state.fileError && (
                  <ErrorLabel>
                    <div style={{ paddingLeft: '1rem' }}>{this.state.fileErrorString}</div>
                  </ErrorLabel>
                )}
              </div>
            ) : (
              <div className="licenses-file-preview">
                {fileType === 'application/pdf' ? (
                  <div className="pdf-file-container">PDF</div>
                ) : (
                  <div className="doc-file-container">DOC</div>
                )}
                <Popup
                  trigger={
                    <Button
                      type="button"
                      className="iconic-button-custom"
                      onClick={() => this.setState({ showConfirm: true })}
                    >
                      <Image src={closeIcon} />
                    </Button>
                  }
                  size="mini"
                  basic={true}
                  content="Delete"
                />
              </div>
            )}
          </Modal.Content>

          <div className="modal-button-footer">
            <Button
              type="button"
              style={{ width: '115px' }}
              className="secondary-button"
              secondary={true}
              onClick={() => this.props.showModal(false)}
            >
              CANCEL
            </Button>
            <Button style={{ width: '115px' }} primary={true}>
              SAVE
            </Button>
          </div>
        </Form>
        <Confirm
          size="tiny"
          closeIcon={
            <Image
              src={closeIcon}
              className="icon"
              onClick={() => this.setState({ showConfirm: false })}
            />
          }
          open={this.state.showConfirm}
          onCancel={() => this.setState({ showConfirm: false })}
          header="Confirm"
          content="Are you sure you want to delete this file?"
          cancelButton="NO"
          confirmButton="YES"
          onConfirm={() =>
            this.setState({
              showFile: false,
              file: '',
              fileType: '',
              showConfirm: false,
              fileName: '',
              fileSize: '',
              fileInitial: '',
              fileError: false,
            })
          }
          className="confirm-modal-custom"
          closeOnDimmerClick={false}
        />
      </Modal>
    );
  }
}

export default EditResumeModal;
