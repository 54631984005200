import { Form } from 'formsy-semantic-ui-react';
import moment from 'moment';
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Grid } from 'semantic-ui-react';
import { accountActions } from '../../features/account';
import { NewUser } from '../../models/newUser';
import services from '../../services';
import AddResumeModal from './../register-layout/AddResumeModal';
import EditResumeModal from './../register-layout/EditResumeModal';
import ResumeItem from './../register-layout/ResumeItem';

interface Props {
  getSubmitRef: (ref: any) => void;
  changeRegisterStore: typeof accountActions.changeRegisterStore;
  changeProgressValue: typeof accountActions.changeProgressValue;
  newUser: NewUser;
  progress: number;
}

interface State {
  resumes: object[];
  resumeId: number | undefined;
  showEditModal: boolean;
}

class RegisterResumes extends React.Component<Props, State> {
  public state = {
    resumes: [],
    resumeId: 0,
    showEditModal: false,
  };

  public submitRef = React.createRef();

  public downloadLocalFile = (file: any) => {
    services.api.file.downloadLocalFile(file);
  };

  public getResume = (item: object, id?: number) => {
    const resumes = [...this.state.resumes];

    if (id === undefined) {
      resumes.push(item);
    } else {
      const index = resumes.findIndex((el: any) => el.id === id);
      resumes[index] = { ...item };
    }

    this.setState({ resumes });
  };

  public deleteResume = (id: number) => {
    const resumes = [...this.state.resumes].filter((el: any) => el.id !== id);
    this.setState({ resumes });
  };

  public editResumeData = (id: number, item: object) => {
    const resumes = [...this.state.resumes];
    const index = resumes.findIndex(el => el.id === id);
    resumes[index] = { ...item };
    this.setState({ resumes, showEditModal: false });
  };

  public editResume = (show: boolean, id?: number) => {
    if (id !== null) {
      this.setState({ resumeId: id });
    }

    this.setState({ showEditModal: show });
  };

  public componentDidMount() {
    this.props.getSubmitRef(this.submitRef);
    this.setState({
      resumes: this.props.newUser.resumes,
    });
  }

  public render() {
    return (
      <Grid stackable={true} relaxed={true} style={{ width: '100%' }} className="licenses-grid">
        <Grid.Row columns={1} only="computer">
          <Grid.Column className="licenses-column">
            <Form
              onValidSubmit={(model: any) => {
                const { resumes } = this.state;
                const resumesMod = resumes.map((el: any) => {
                  delete el.id;
                  return el;
                });
                if (resumes.length !== 0) {
                  this.props.changeProgressValue(this.props.progress + 10);
                  this.props.changeRegisterStore({ resumes: resumesMod });
                } else {
                  this.props.changeRegisterStore({ resumes: resumesMod });
                }
              }}
            >
              {this.state.resumes.length === 0 ? (
                <Form.Input
                  name="resumes"
                  placeholder="Nothing Added"
                  instantValidation={false}
                  className="form-input readonly-input"
                  readOnly={true}
                />
              ) : (
                <div>
                  {this.state.resumes.map((el: any, index: number) => (
                    <ResumeItem
                      item={el}
                      key={index}
                      id={index}
                      editResume={this.editResume}
                      deleteResume={this.deleteResume}
                      download={this.downloadLocalFile}
                    />
                  ))}
                </div>
              )}
              <Form.Input
                name="resume_file"
                hidden={true}
                style={{ display: 'none' }}
                readOnly={true}
              />
              <Button ref={this.submitRef} type="submit" style={{ display: 'none' }} />
            </Form>
            <div style={{ width: '100%' }}>
              <AddResumeModal getResume={this.getResume} />
            </div>
            <EditResumeModal
              showModal={this.editResume}
              show={this.state.showEditModal}
              editResume={this.editResumeData}
              item={this.state.resumes[this.state.resumeId]}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={16} only="tablet mobile">
          <Grid.Column className="licenses-column">
            <Form
              onValidSubmit={(model: any) => {
                const { resumes } = this.state;
                const resumesMod = resumes.map((el: any) => {
                  delete el.id;
                  return el;
                });
                if (resumes.length !== 0) {
                  this.props.changeProgressValue(this.props.progress + 10);
                  this.props.changeRegisterStore({ resumes: resumesMod });
                } else {
                  this.props.changeRegisterStore({ resumes: resumesMod });
                }
              }}
            >
              {this.state.resumes.length === 0 ? (
                <Form.Input
                  name="resumes"
                  placeholder="Nothing Added"
                  instantValidation={false}
                  className="form-input readonly-input"
                  readOnly={true}
                />
              ) : (
                <div>
                  {this.state.resumes.map((el: any, index: number) => (
                    <ResumeItem
                      item={el}
                      key={index}
                      id={index}
                      editResume={this.editResume}
                      deleteResume={this.deleteResume}
                      download={this.downloadLocalFile}
                    />
                  ))}
                </div>
              )}
              <Form.Input
                name="resume_file"
                hidden={true}
                style={{ display: 'none' }}
                readOnly={true}
              />
              <Button ref={this.submitRef} type="submit" style={{ display: 'none' }} />
            </Form>
            <div style={{ width: '100%' }}>
              <AddResumeModal getResume={this.getResume} />
            </div>
            <EditResumeModal
              showModal={this.editResume}
              show={this.state.showEditModal}
              editResume={this.editResumeData}
              item={this.state.resumes[this.state.resumeId]}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  newUser: state.account.register.newUser,
  progress: state.account.register.progress,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      changeRegisterStore: accountActions.changeRegisterStore,
      changeProgressValue: accountActions.changeProgressValue,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterResumes);
