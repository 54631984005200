import React from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { IUser } from '../../models';

interface Props {
  getSubmitRef: (ref: any) => void;
  user: IUser;
}

interface State {
  logs: object[];
}

class AdjusterLogs extends React.Component<Props, State> {
  public state = {
    logs: [],
  };

  public render() {
    return <div>logs</div>;
  }
}

export default AdjusterLogs;
