import React from 'react';
import '../../assets/styles/Staff.css';

import '../../assets/styles/Staff.css';

interface Props {}

interface State {}

class CreateAdminModal extends React.Component<Props, State> {
  public render() {
    return (
      <div
        style={{
          color: 'red',
          fontSize: '13px',
          paddingTop: '5px',
          display: 'inline-block',
        }}
        className="error-label-custom"
      >
        {' '}
        {this.props.children}
      </div>
    );
  }
}

export default CreateAdminModal;
