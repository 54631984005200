import { Form } from 'formsy-semantic-ui-react';
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Container, Divider, Header, Icon } from 'semantic-ui-react';
import '../../assets/styles/Standby.css';
import ErrorLabel from '../../components/error-label/ErrorLabel';
import { locadingActions } from '../../features/loading';
import { standbyActions } from '../../features/standby';
import { IUser } from '../../models';
import services from '../../services';
import SBDetailsHeader from './../../components/standby-layout/SBDetailsHeader';
import SBQuestion from './../../components/standby-layout/SBQuestion';

interface Props {
  getStandbyList: typeof standbyActions.getStandbyList;
  setActiveStandbyReloaded: typeof standbyActions.setActiveStandbyReloaded;
  setActivePage: typeof standbyActions.setActivePage;
  user: IUser;
  activeStandbyId: any;
  standbyList: object[];
  activeList: object;
  quicksignup?: number;
  standbyListId?: number;
  answerSaved?: () => void;
  showLoader: typeof locadingActions.show;
  hideLoader: typeof locadingActions.hide;
}

interface State {
  activeList: object;
  questions: object[];
  answers: object[];
  list: object;
}

class StandbyQuestions extends React.Component<Props, State> {
  public state = {
    activeList: {},
    questions: [],
    answers: [],
    list: {},
  };

  public componentDidMount = () => {
    const id = this.props.standbyListId || Number(this.props.match.params.id);

    this.getDetails(id);
  };

  public getDetails = async (id: any) => {
    if (id) {
      let response: any;

      if (this.props.quicksignup) {
        response = await services.api.standby.getListDetailsForGuest(id, this.props.quicksignup);
      } else {
        response = await services.api.standby.getListDetails(id);
      }

      if (response.isSuccess) {
        this.setState(
          {
            questions: response.data.questions,
            list: response.data,
            answers: response.data.adjusterData ? response.data.adjusterData.answers : [],
          },
          () => {
            if (response.data.adjusterData && response.data.adjusterData.answers.length !== 0) {
              this.getAnswers(id);
            }
          }
        );
      }
    }
  };

  public getAnswers = async (id: any) => {
    const { questions, answers } = this.state;

    questions.map((question: object, index: number) => {
      const answer = answers.find(el => el.questionId === question.id);
      question.answer = answer ? answer.text : '';
      return question;
    });

    this.setState({ questions: questions });
  };

  public onSubmit = async (model: any) => {
    delete model.answer;
    const answersArray = [];
    for (const question in model) {
      if (model.hasOwnProperty(question)) {
        answersArray.push(model[question]);
      } else {
        answersArray.push(model[question]);
      }
    }

    this.props.showLoader();

    const response = await services.api.standby.submitAnswers(
      answersArray,
      this.props.match.params.id || this.props.standbyListId,
      this.props.user ? this.props.user.id : null,
      this.props.quicksignup
    );

    this.props.hideLoader();

    if (response.isSuccess) {
      toast.dismiss();
      toast.success('Answers were succesfully submitted!');

      if (this.props.quicksignup && this.props.answerSaved) {
        this.props.answerSaved();
      } else {
        this.props.setActivePage(1);
        services.router.goto('/stand-by-lists');
      }
    } else {
      toast.dismiss();

      if (response.data && response.data.errors) {
        toast.error(
          response.data.errors ? response.data.errors[0].msg : `Error ${response.status}`
        );
      }
    }
    console.log(response);
  };

  public render() {
    const { list, questions } = this.state;
    const errorLabel = <ErrorLabel />;

    // if ((list as any).privacy === 'private') {
    // return <>{services.router.goto('/stand-by-lists')}</>;
    // }

    // return (
    //   <Container text style={{ marginTop: '40px' }}>
    //     <Header as="h2" textAlign="center">
    //       Private Standby List
    //     </Header>
    //     <Divider />
    //     <p style={{ textAlign: 'center', width: '100%' }}>
    //       You are not allowed to access to this standby
    //     </p>
    //     <Icon
    //       name="lock"
    //       size="massive"
    //       color="blue"
    //       style={{ margin: '20px auto', display: 'block' }}
    //     />
    //   </Container>
    // );
    return (
      <div>
        <SBDetailsHeader item={list} />
        <div
          style={{
            fontSize: '22px',
            fontWeight: 700,
            marginTop: '23px',
            marginBottom: '14px',
            paddingLeft: '15px',
          }}
        >
          Questions
        </div>
        <Form onValidSubmit={this.onSubmit}>
          {questions &&
            questions
              .sort((a: any, b: any) => a.order - b.order)
              .map((question: any, index: number) => {
                return (
                  <SBQuestion
                    applied={list.adjusterData && list.adjusterData.status === 'applied'}
                    required={true}
                    disabledQuestion={list.closedAt && list.closedAt !== ''}
                    answer={question.answer}
                    name={String(question.id)}
                    question={question}
                    key={index}
                    validationErrors={{
                      isChecked: '* Answer is required field.',
                    }}
                    errorLabel={errorLabel}
                  />
                );
              })}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            {(!list.closedAt || list.closedAt === '') && (
              <Button style={{ width: '263px', height: '32px' }} primary={true}>
                {list.adjusterData && list.adjusterData.status === 'applied'
                  ? 'Update Answers'
                  : 'Submit Answers'}
              </Button>
            )}
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
  activeStandbyId: state.standby.standby.activeStandbyId,
  standbyList: state.standby.standby.standbyList,
  activeList: state.standby.standby.activeList,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getStandbyList: standbyActions.getStandbyList,
      setActivePage: standbyActions.setActivePage,
      setActiveStandbyReloaded: standbyActions.setActiveStandbyReloaded,
      showLoader: locadingActions.show,
      hideLoader: locadingActions.hide,
    },
    dispatch
  );

export default connect<Props, State>(mapStateToProps, mapDispatchToProps)(StandbyQuestions);
