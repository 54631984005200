import MyTypes from 'MyTypes';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { accountActions } from '../features/account';
import { standbyActions } from '../features/standby';
import services from '../services';

interface PrivateRouteProps {
  setRedirectStandby: typeof standbyActions.setRedirectStandby;
  isAuthenticated: boolean;
  path: string;
  component: any;
  exact?: boolean;
  logout: () => void;
}

class PrivateRouteClass extends React.Component<PrivateRouteProps> {
  public render() {
    const { isAuthenticated, component, path, exact, history, logout } = this.props as any;

    const Component = component;
    if (!isAuthenticated && path.includes('/stand-by-lists/')) {
      this.props.setRedirectStandby(history.location.pathname);
    }
    return (
      <Route
        path={path}
        exact={exact}
        render={props => {
          return isAuthenticated === true ? (
            <Component {...props} />
          ) : (
            services.router.goto('/login')
          );
        }}
      />
    );
  }
}

const stp = (state: MyTypes.RootState) => ({
  isAuthenticated: state.account.account.isAuthenticated,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      logout: accountActions.logout,
      setRedirectStandby: standbyActions.setRedirectStandby,
    },
    dispatch
  );

const PrivateRoute = connect(
  stp,
  mapDispatchToProps
)(PrivateRouteClass);

export { PrivateRouteClass, PrivateRoute };
