import { action } from 'typesafe-actions';
import * as constants from './constants';

export interface ProfileSaveAttemptModel {
  first_name: string;
  last_name: string;
  email: string;
}

export const profileSaveAttempt = (model: ProfileSaveAttemptModel) =>
  action(constants.PROFILE_SAVE_ATTEMPT, model);

export const profileSaveFailed = (errors: string[]) =>
  action(constants.PROFILE_SAVE_ATTEMPT_FAILED, {
    errors,
  });

export const emailVerificationRequestAttempt = () =>
  action(constants.EMAIL_VERIFICATION_REQUEST_ATTEMPT);

export const emailVerificationRequestSend = () => action(constants.EMAIL_VERIFICATION_REQUEST_SEND);

export const setActiveTab = (tab: string) => action(constants.SET_ACTIVE_TAB, { tab });
