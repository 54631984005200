import { Form } from 'formsy-semantic-ui-react';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Grid, Icon, Responsive } from 'semantic-ui-react';
import { IUser } from '../../models';
import services from '../../services';
import CheckboxGroup from '../checkbox-group/CheckboxGroup';
import ErrorLabel from '../error-label/ErrorLabel';
import ExperienceItem from '../register-layout/ExperienceItem';
import LossTypeItem from '../register-layout/LossTypeItem';

interface Props {
  updateData: () => void;
  getSubmitRef: (ref: any) => void;
  user: IUser;
}

interface State {
  experiences: object[];
  skills: object[];
  losses: object[];
  languages: string[];
  equipment: string[];
  languageOptions: object[];
  equipmentOptions: object[];
  climbing: boolean;
}

class ProfileProfessionalSkills extends React.Component<Props, State> {
  public state = {
    experiences: [],
    skills: [],
    losses: [],
    languages: [],
    languageOptions: [],
    equipment: [],
    equipmentOptions: [],
    climbing: true,
  };

  public sumbitRef = React.createRef();

  public componentDidMount = async () => {
    this.props.getSubmitRef(this.sumbitRef);

    const response = await services.api.account.getSettingsData('language');
    if (response.isSuccess) {
      const languageOptions = response.data.list.map((el: any) => ({
        label: el.name,
        checked: this.props.user.languages.includes(el.name),
      }));
      this.setState({ languageOptions });
    } else {
      console.log(response);
    }

    const response2 = await services.api.account.getSettingsData('equipment');
    if (response2.isSuccess) {
      const equipmentOptions = response2.data.list.map((el: any) => ({
        label: el.name,
        checked: this.props.user.equipments.includes(el.name),
      }));
      this.setState({ equipmentOptions });
    } else {
      console.log(response2);
    }

    const modExperiences = this.props.user.experiences.map((el: any, index: number) => {
      el.id = index;
      return el;
    });

    const modSkills = this.props.user.softwareSkills.map((el: any, index: number) => {
      el.id = index;
      return el;
    });

    this.setState({
      experiences: this.props.user.experiences.length === 0 ? [{ id: 0 }] : modExperiences,
      skills: this.props.user.softwareSkills.length === 0 ? [{ id: 0 }] : modSkills,
      losses:
        this.props.user.expertise.length === 0
          ? [
              {
                id: 0,
                type: '',
                commercialCATClaims: 0,
                residentialCATClaims: 0,
                commercialDailyClaims: 0,
                residentialDailyClaims: 0,
              },
            ]
          : this.props.user.expertise.map((e, index) => {
              return { ...e, id: index };
            }),
      climbing: this.props.user.climbRoof,
    });
  };

  public componentDidUpdate = (prevProps: Props) => {
    if (prevProps !== this.props) {
      this.setState({
        losses:
          this.props.user.expertise.length === 0
            ? [
                {
                  id: 0,
                  type: '',
                  commercialCATClaims: 0,
                  residentialCATClaims: 0,
                  commercialDailyClaims: 0,
                  residentialDailyClaims: 0,
                },
              ]
            : this.props.user.expertise.map((e, index) => {
                return { ...e, id: index };
              }),
      });
    }
  };

  public editLoss = (id: number, item: any) => {
    const { losses } = this.state;
    const index = losses.findIndex(el => el.id === id);
    losses[index] = item;
    this.setState({ losses });
  };

  public editExperience = (id: number, name: any, value: any) => {
    const { experiences } = this.state;
    const index = experiences.findIndex(el => el.id === id);
    experiences[index][name] = value;
    this.setState({ experiences });
  };

  public editSkills = (id: number, name: any, value: any) => {
    const { skills } = this.state;
    const index = skills.findIndex(el => el.id === id);
    skills[index][name] = value;
    this.setState({ skills });
  };

  public addExperience = () => {
    const { experiences } = this.state;
    experiences.push({ id: 1000 * Math.random() });
    this.setState({ experiences });
  };

  public addLossItem = () => {
    const { losses } = this.state;
    losses.push({
      id: 1000 * Math.random(),
      type: '',
      commercialCATClaims: 0,
      residentialCATClaims: 0,
      commercialDailyClaims: 0,
      residentialDailyClaims: 0,
    });
    this.setState({ losses });
  };

  public addSkills = () => {
    const { skills } = this.state;
    skills.push({ id: 1000 * Math.random() });
    this.setState({ skills });
  };

  public deleteLoss = (id: number) => {
    const { losses } = this.state;
    const index = losses.findIndex(el => el.id === id);
    losses.splice(index, 1);
    this.setState({ losses });
  };

  public deleteExperience = (id: number) => {
    const { experiences } = this.state;
    const index = experiences.findIndex(el => el.id === id);
    experiences.splice(index, 1);
    this.setState({ experiences });
  };

  public deleteSkills = (id: number) => {
    const { skills } = this.state;
    const index = skills.findIndex(el => el.id === id);
    skills.splice(index, 1);
    this.setState({ skills });
  };

  public render() {
    const errorLabel = <ErrorLabel />;

    return (
      <Grid stackable={true} style={{ width: '100%', marginTop: '1rem' }} className="skills-grid">
        <Grid.Column>
          <Form
            onValidSubmit={async (model: any) => {
              const { losses, experiences, skills, climbing } = this.state;
              const expArray = [];
              for (let i = 0; i < experiences.length; i++) {
                expArray[i] = {
                  name: model[`experience-field-${i}`],
                  years: Number(model[`experience-years-${i}`]),
                };
              }
              const skillsArray = [];
              for (let j = 0; j < skills.length; j++) {
                skillsArray[j] = {
                  name: model[`skills-field-${j}`],
                  years: Number(model[`skills-years-${j}`]),
                };
              }
              const lossesArray = losses.map((el: any) => {
                delete el.id;
                return el;
              });
              const obj = Object.assign(
                {},
                { experiences: expArray },
                { languages: model.languages },
                { softwareSkills: skillsArray },
                { equipments: model.equipments },
                { expertise: lossesArray },
                { climbRoof: climbing },
                { milesRange: Number(model.milesRange) }
              );

              for (const key in obj) {
                if (
                  obj[key] === undefined ||
                  obj[key] === null ||
                  obj[key] === '' ||
                  (Array.isArray(obj[key]) && obj[key].length === 0)
                ) {
                  delete obj[key];
                }
              }

              const response = await services.api.user.updateUserSkills(obj);

              if (response.isSuccess) {
                this.props.updateData();
                toast.dismiss();
                toast.success('Professional Skills were sucessfully updated.');
              } else {
                toast.dismiss();
                toast.error(response.data.errors[0].msg);
              }
            }}
          >
            {this.state.experiences.map((el: any, index: number) => (
              <div className="input-field-container" key={index}>
                <div className="input-field-label">{index === 0 ? 'Experience' : null}</div>
                <ExperienceItem
                  name="test"
                  id={index}
                  item={el}
                  field="experience"
                  edit={this.editExperience}
                  delete={this.deleteExperience}
                  allItems={this.state.experiences.map((item: any) => item.name)}
                />
              </div>
            ))}
            <div className="input-field-container">
              <div className="input-field-label" />
              <div className="add-question">
                <Button type="button" onClick={() => this.addExperience()}>
                  + ADD
                </Button>
              </div>
            </div>

            <div className="input-field-container">
              <div className="input-field-label">Languages Spoken</div>
              <CheckboxGroup
                name="languages"
                items={this.state.languageOptions}
                validations={{
                  isChecked: true,
                }}
                value=""
                validationErrors={{
                  isChecked: '* Languages is required field.',
                }}
                errorLabel={errorLabel}
              />
            </div>
            {this.state.skills.map((el: any, index: number) => (
              <div className="input-field-container" key={index}>
                <div className="input-field-label">{index === 0 ? 'Software Skills' : null}</div>
                <ExperienceItem
                  name="test"
                  id={index}
                  item={el}
                  field="skills"
                  delete={this.deleteSkills}
                  allItems={this.state.skills.map((item: any) => item.name)}
                  edit={this.editSkills}
                />
              </div>
            ))}

            <div className="input-field-container">
              <div className="input-field-label" />
              <div className="add-question">
                <Button type="button" onClick={() => this.addSkills()}>
                  + ADD
                </Button>
              </div>
            </div>
            <div className="input-field-container">
              <div className="input-field-label">Equipment</div>
              <CheckboxGroup
                name="equipments"
                items={this.state.equipmentOptions}
                validations={{
                  isChecked: true,
                }}
                value=""
                validationErrors={{
                  isChecked: '* Equipment is required field.',
                }}
                errorLabel={errorLabel}
              />
            </div>
            {this.state.losses.map((el: any, index: number) => (
              <div className="input-field-container expertise-loss-container" key={index}>
                <div className="input-field-label">{index === 0 ? 'Adjuster Expertise' : null}</div>
                <LossTypeItem
                  name="loss-item"
                  id={index}
                  item={el}
                  required={true}
                  allItems={this.state.losses.map((item: any) => item.type)}
                  delete={this.deleteLoss}
                  edit={this.editLoss}
                  errorLabel={errorLabel}
                  validationErrors={{
                    isDefaultRequiredValue: `* Adjuster Expertise is required field.`,
                  }}
                />
              </div>
            ))}
            <div className="input-field-container">
              <div className="input-field-label" />
              <div className="add-question">
                <Button type="button" onClick={() => this.addLossItem()}>
                  + ADD
                </Button>
              </div>
            </div>
            <Responsive maxWidth={480}>
              <div className="input-field-container">
                <div className="input-field-label">
                  Are you willing to climb roof greater that 8/12 pitch without a roof assist?
                </div>
                <div className="climbing-toogle">
                  <div className="toggle-block">
                    <Button
                      type="button"
                      className={this.state.climbing ? 'yes-btn' : 'no-btn'}
                      onClick={() => this.setState({ climbing: true })}
                    >
                      YES
                    </Button>
                    <Button
                      type="button"
                      className={!this.state.climbing ? 'yes-btn' : 'no-btn'}
                      onClick={() => this.setState({ climbing: false })}
                    >
                      NO
                    </Button>
                  </div>
                </div>
              </div>
            </Responsive>

            <Responsive minWidth={481}>
              <div className="input-field-container">
                <div className="input-field-label" style={{ paddingRight: '25px' }}>
                  Are you willing to climb roof greater that 8/12 pitch without a roof assist?
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                  <div className="toggle-block">
                    <Button
                      type="button"
                      className={this.state.climbing ? 'yes-btn' : 'no-btn'}
                      onClick={() => this.setState({ climbing: true })}
                    >
                      YES
                    </Button>
                    <Button
                      type="button"
                      className={!this.state.climbing ? 'yes-btn' : 'no-btn'}
                      onClick={() => this.setState({ climbing: false })}
                    >
                      NO
                    </Button>
                  </div>
                </div>
              </div>
            </Responsive>
            <div className="input-field-container">
              <div className="input-field-label" style={{ paddingRight: '25px' }}>
                How far away from your base location are you willing to accept claims (one way)
                without billing for mileage?
              </div>
              <div className="miles-input">
                <Form.Input
                  name="milesRange"
                  icon={<Icon>MILES</Icon>}
                  type="number"
                  min={0}
                  placeholder="..."
                  instantValidation={false}
                  className="form-input-miles"
                  required={true}
                  value={this.props.user.milesRange}
                  validationErrors={{
                    isDefaultRequiredValue: `* Miles is required field.`,
                  }}
                  errorLabel={errorLabel}
                />
              </div>
            </div>
            <Button ref={this.sumbitRef} type="submit" style={{ display: 'none' }} />
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
}

export default ProfileProfessionalSkills;
