import * as Formsy from 'formsy-react';
import { Form, RadioGroup } from 'formsy-semantic-ui-react';
import moment from 'moment';
import React from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import { Button, Checkbox, Grid, Image, Popup, Radio } from 'semantic-ui-react';
import infoIcon from '../../assets/images/Common/icn-info.svg';
import avatarPlaceholder from '../../assets/images/no-avatar.svg';
import dropdownIcon from '../../assets/images/ui-dropdown.svg';
import eyeIcon from '../../assets/images/ui-show-pass.svg';
import { IUser } from '../../models';
import services from '../../services';
import * as datesHelper from '../../utils/datesHelper';
import * as helper from '../../utils/helper';
import ProfileDetailsFields from '../../utils/ProfileDetailsFields';
import statesUS from '../../utils/statesUS';
import CheckboxGroup from '../checkbox-group/CheckboxGroup';
import DatePickerInput from '../datepicker-input/DatePickerInput';
import ErrorLabel from '../error-label/ErrorLabel';
import PhoneInput from '../layout-input/PhoneInput';
import AddReference from '../register-layout/AddReference';
import ReferenceItem from '../register-layout/ReferenceItem';
import AvatarInput from './../avatar-input/AvatarInput';
import { LoadingSpiner } from './../loading-spinner/LoadingSpinner';
import AddEducation from './../register-layout/AddEducation';
import EducationItem from './../register-layout/EducationItem';

const fields = ProfileDetailsFields;

Formsy.addValidationRule('passConfirm', (values: any, value: any) => {
  if (values.password === value) {
    return true;
  } else if (values.password === '' && value === '') {
    return true;
  } else {
    return false;
  }
});

Formsy.addValidationRule('hasUppercaseProfile', (values: any, value: any) => {
  if (value !== undefined && value.toLowerCase() !== value) {
    return true;
  } else if (value === '' || value === undefined) {
    return true;
  } else {
    return false;
  }
});
Formsy.addValidationRule('hasLowercaseProfile', (values: any, value: any) => {
  if (value !== undefined && value.toUpperCase() !== value) {
    return true;
  } else if (value === '' || value === undefined) {
    return true;
  } else {
    return false;
  }
});
Formsy.addValidationRule('hasSpecialProfile', (values: any, value: any) => {
  const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
  if (value !== undefined && format.test(value)) {
    return true;
  } else if (value === '' || value === undefined) {
    return true;
  } else {
    return false;
  }
});
Formsy.addValidationRule('hasNumbersProfile', (values: any, value: any) => {
  if (value === undefined || value === '') {
    return true;
  } else {
    return /\d/.test(value);
  }
});

interface Props {
  getSubmitRef: (ref: any) => void;
  updateData: () => void;
  user: IUser;
  logout: () => void;
}

interface State {
  avatar: string;
  avatarEdited: boolean;
  fieldsUrl: string;
  formData: FormData | {};
  availableForClaims: boolean;
  education: object[];
  references: object[];
  positionsOptions: object[];
  activePositions: string[];
  isSubscribedToCompassMailUpdates: boolean;
  isSubscribedToPersonalMailUpdates: boolean;
  showPass: boolean;
  showCompassLabel: string;
  showEmailLabel: string;
  enteredPass: string;
  fullPositions: boolean;
  isChecked: boolean;
  showCompanyName: boolean;
  temporaryLocation: boolean;
  isCatValuesCleared: boolean;
  neverExpiresCheckbox: boolean;
  availableForClaimsExpirationDate: string;
  availableForClaimsNotes: string;
}

class ProfileDetails extends React.Component<Props, State> {
  public submitRef = React.createRef();
  public constructor(props) {
    super(props);
    this.state = {
      avatar: '',
      avatarEdited: false,
      fieldsUrl: '',
      formData: {},
      availableForClaims:
        props.user.availableForClaims === undefined ? true : props.user.availableForClaims,
      isSubscribedToCompassMailUpdates:
        props.user.isSubscribedToCompassMailUpdates === undefined
          ? true
          : props.user.isSubscribedToCompassMailUpdates,
      isSubscribedToPersonalMailUpdates:
        props.user.isSubscribedToPersonalMailUpdates === undefined
          ? true
          : props.user.isSubscribedToPersonalMailUpdates,
      education: [],
      references: [],
      positionsOptions: [],
      activePositions: [],
      showPass: false,
      showCompassLabel: 'readonly-input',
      showEmailLabel: 'readonly-input',
      enteredPass: '',
      fullPositions: false,
      rf: false,
      showCompanyName: false,
      temporaryLocation: false,
      isCatValuesCleared: false,
      neverExpiresCheckbox: false,
      availableForClaimsExpirationDate: '',
      availableForClaimsNotes: '',
    };
  }

  public componentDidMount = async () => {
    this.props.getSubmitRef(this.submitRef);

    const request = await services.api.account.getSettingsData('position');
    const positionsOptions = request.data.list.map((el: any, index: any) => {
      const element = {
        label: el.name,
        checked: this.props.user.positions && this.props.user.positions.includes(el.name),
      };
      return element;
    });

    this.setState({
      avatar: this.props.user.avatar,
      education: this.props.user.educations ? this.props.user.educations : [],
      positionsOptions,
      activePositions: this.props.user.positions ? this.props.user.positions : [],
      references: this.props.user.references ? this.props.user.references : [],
      // availableForClaims: this.props.user.availableForClaims,
      isSubscribedToCompassMailUpdates:
        this.props.user.isSubscribedToCompassMailUpdates === undefined
          ? true
          : this.props.user.isSubscribedToCompassMailUpdates,
      isSubscribedToPersonalMailUpdates:
        this.props.user.isSubscribedToPersonalMailUpdates === undefined
          ? true
          : this.props.user.isSubscribedToPersonalMailUpdates,
      temporaryLocation: this.props.user.temporaryLocation,
      neverExpiresCheckbox:
        !this.props.user.availableForClaims && !this.props.user.availableForClaimsExpirationDate,
      availableForClaimsExpirationDate: this.props.user.availableForClaimsExpirationDate,
      availableForClaimsNotes: this.props.user.availableForClaimsNotes,
    });

    if (this.props.user.registrationType === 'business') {
      this.setState({ showCompanyName: true });
    } else {
      this.setState({ showCompanyName: false });
    }
  };

  public componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (prevProps.user !== this.props.user) {
      this.props.user.positions.forEach((el: any) => {
        const index = this.state.positionsOptions.findIndex(item => item.label === el);
        if (index === -1) {
          return;
        } else {
          this.state.positionsOptions[index].checked = true;
        }
      });

      this.setState({
        avatar: this.props.user.avatar,
        education: this.props.user.educations ? this.props.user.educations : [],
        activePositions: this.props.user.positions ? this.props.user.positions : [],
        references: this.props.user.references ? this.props.user.references : [],
        availableForClaims: this.props.user.availableForClaims,
        isSubscribedToCompassMailUpdates: this.props.user.isSubscribedToCompassMailUpdates,
        isSubscribedToPersonalMailUpdates: this.props.user.isSubscribedToPersonalMailUpdates,
        temporaryLocation: this.props.user.temporaryLocation,
        availableForClaimsNotes: this.props.user.availableForClaimsNotes,
      });

      if (!prevState.availableForClaims && this.state.availableForClaims) {
        this.setState({ availableForClaimsNotes: '' });
      }
    }
  };

  public getAvatar = (key: any, avatarEdited: boolean) => {
    this.setState({ avatar: key, avatarEdited });
  };

  public handlePass = (e: any) => {
    this.setState({ enteredPass: e.target.value });
  };

  public handleTemporaryLocationRadio = (e: any, { value }: any) => {
    this.setState({ temporaryLocation: value === 'true' });

    if (value === 'false') {
      this.setState({ isCatValuesCleared: true });
    }
  };

  public getAvatatFields = (url: string, data: FormData) => {
    this.setState({ fieldsUrl: url, formData: data });
  };

  public getEducation = (item: object) => {
    const { education } = this.state;
    education.push(item);
    this.setState({ education });
  };

  public editEducation = (id: number, item: object) => {
    const { education } = this.state;
    education[id] = item;
    this.setState({ education });
  };

  public deleteEducation = (id: number) => {
    const { education } = this.state;
    education.splice(id, 1);
    this.setState({ education });
  };

  public getReference = (item: object) => {
    const { references } = this.state;
    references.push(item);
    this.setState({ references });
  };

  public editReference = (id: number, item: object) => {
    const { references } = this.state;
    references[id] = item;
    this.setState({ references });
  };

  public deleteReference = (id: number) => {
    const { references } = this.state;
    references.splice(id, 1);
    this.setState({ references });
  };

  public onExpirationCheckboxChange = (event: any, { checked }: any) => {
    this.setState({ neverExpiresCheckbox: checked, availableForClaimsExpirationDate: '' }, () => {
      this.claimsDatePicker.setValue('');
    });
  };

  public render() {
    const errorLabel = <ErrorLabel />;
    const { user } = this.props;
    const { fieldsUrl, formData, avatarEdited, temporaryLocation, isCatValuesCleared } = this.state;
    const catExpirationValue = isCatValuesCleared
      ? ''
      : this.props.user.catExpirationDate
      ? this.props.user.catExpirationDate
      : '';
    return (
      <Form
        onValidSubmit={async (model: any) => {
          delete model.compassEmail;

          model.birthday = datesHelper.parseDateToDateStringWithFormat(model.birthday);

          if (model.catExpirationDate) {
            model.catExpirationDate = datesHelper.parseDateToDateStringWithFormat(
              model.catExpirationDate
            );
          }

          if (this.state.neverExpiresCheckbox) {
            delete model.availableForClaimsExpirationDate;
          }

          if (model.availableForClaimsExpirationDate) {
            model.availableForClaimsExpirationDate = datesHelper.parseDateToDateStringWithFormat(
              model.availableForClaimsExpirationDate
            );
          }

          const positions = this.state.positionsOptions
            .filter((el: any) => el.checked)
            .map((el: any) => el.label);

          let obj = {};
          let response = {};
          let avatarValue;

          if (avatarEdited) {
            try {
              const url = new URL(this.state.avatar);
              avatarValue = url.pathname;
            } catch (error) {
              avatarValue = this.state.avatar;
            }
            obj = Object.assign(
              {},
              { avatar: avatarValue },
              { availableForClaims: this.state.availableForClaims },
              { isSubscribedToCompassMailUpdates: this.state.isSubscribedToCompassMailUpdates },
              { isSubscribedToPersonalMailUpdates: this.state.isSubscribedToPersonalMailUpdates },
              { positions },
              { educations: this.state.education },
              { references: this.state.references },
              { howDidYouHearAboutUs: 'Facebook' },
              { ...model },
              { catExpirationDate: model.catExpirationDate || null }
            );

            if (this.state.avatar !== '' && this.state.avatar) {
              const resp = await services.api.file.postFile(fieldsUrl, formData);
              if (resp && resp.status === 204) {
                response = await trackPromise(
                  services.api.user.updateCurrentUser(obj),
                  'admin-adjuster-profile-area'
                );
                this.setState({ enteredPass: '' });
              } else {
                console.log(resp);
              }
            } else {
              response = await trackPromise(
                services.api.user.updateCurrentUser(obj),
                'admin-adjuster-profile-area'
              );
            }
          } else {
            if (user.avatar !== '' && user.avatar) {
              const index1 = user.avatar.indexOf('public');
              const index2 = user.avatar.indexOf('?');
              const key = user.avatar.slice(index1, index2);
              obj = Object.assign(
                {},
                { avatar: key },
                { availableForClaims: this.state.availableForClaims },
                { isSubscribedToCompassMailUpdates: this.state.isSubscribedToCompassMailUpdates },
                {
                  isSubscribedToPersonalMailUpdates: this.state.isSubscribedToPersonalMailUpdates,
                },
                { positions },
                { educations: this.state.education },
                { references: this.state.references },
                { howDidYouHearAboutUs: 'Facebook' },
                { ...model },
                { catExpirationDate: model.catExpirationDate || null }
              );

              response = await trackPromise(
                services.api.user.updateCurrentUser(obj),
                'admin-adjuster-profile-area'
              );
            } else {
              obj = Object.assign(
                {},
                { avatar: '' },
                { availableForClaims: this.state.availableForClaims },
                { isSubscribedToCompassMailUpdates: this.state.isSubscribedToCompassMailUpdates },
                {
                  isSubscribedToPersonalMailUpdates: this.state.isSubscribedToPersonalMailUpdates,
                },
                { positions },
                { educations: this.state.education },
                { references: this.state.references },
                { howDidYouHearAboutUs: 'Facebook' },
                { ...model },
                { catExpirationDate: model.catExpirationDate || null }
              );

              response = await trackPromise(
                services.api.user.updateCurrentUser(obj),
                'admin-adjuster-profile-area'
              );
            }
          }

          if (response.isSuccess) {
            toast.dismiss();
            toast.success('Profile was sucessfully updated.');

            console.log({ response, model, user: this.props.user, state: this.state });
            // return;
            if (this.state.showEmailLabel !== 'readonly-input' && user.email !== model.email) {
              const emailResponse = await trackPromise(
                services.api.user.updatePersonalEmail(model.email),
                'admin-adjuster-profile-area'
              );
              if (emailResponse && emailResponse.isSuccess) {
                toast.success(
                  'Email with activation details was sent. Please activate your account!'
                );
                services.localStorage.remove('accessToken');
                services.router.goto('/login');
              } else if (emailResponse && emailResponse.isError) {
                this.props.updateData();
                toast.error(
                  emailResponse.data.errors
                    ? emailResponse.data.errors[0].msg
                    : `Error ${emailResponse.status}`
                );
              }
            } else {
              this.props.updateData();
            }
          } else {
            toast.dismiss();
            toast.error(
              response.data.errors ? response.data.errors[0].msg : `Error ${response.status}`
            );
          }
        }}
      >
        <Grid stackable={true}>
          <LoadingSpiner area="admin-adjuster-profile-area" />
          <Grid.Row columns="equal">
            <Grid.Column>
              <div className="input-field-container availability-for-claims avatar-block-mobile">
                <div className="input-field-label">
                  <AvatarInput
                    getAvatar={this.getAvatar}
                    getAvatarFields={this.getAvatatFields}
                    avatar={user.avatar ? user.avatar : avatarPlaceholder}
                    hasToken={true}
                    noEdit={false}
                  />
                </div>
                <div className="claims-block">
                  <div className="header-label-text" style={{ marginBottom: '8px' }}>
                    Availability for Claims
                  </div>
                  <div className="claims-controls">
                    <div className="toggle-block">
                      <Button
                        type="button"
                        className={this.state.availableForClaims ? 'yes-btn' : 'no-btn'}
                        onClick={() =>
                          this.setState({
                            availableForClaims: true,
                            availableForClaimsNotes: '',
                            availableForClaimsExpirationDate: '',
                          })
                        }
                      >
                        YES
                      </Button>
                      <Button
                        type="button"
                        className={!this.state.availableForClaims ? 'yes-btn' : 'no-btn'}
                        onClick={() => this.setState({ availableForClaims: false })}
                      >
                        NO
                      </Button>
                    </div>
                    {!this.state.availableForClaims && (
                      <div className="expiration-block">
                        <DatePickerInput
                          ref={el => (this.claimsDatePicker = el)}
                          name="availableForClaimsExpirationDate"
                          instantValidation={false}
                          validations={{ isValidMin: moment(), isValidDate: 'cat' }}
                          required={!this.state.neverExpiresCheckbox}
                          readOnly={this.state.neverExpiresCheckbox}
                          validationErrors={{
                            isValidMin: `* Date should not be earlier than current date.`,
                            isValidDate: `* Date format is invalid.`,
                            isDefaultRequiredValue: `* Availability For Claims Expiration Date is required field.`,
                          }}
                          value={this.state.availableForClaimsExpirationDate}
                          errorLabel={errorLabel}
                        />
                        {!this.state.availableForClaims && (
                          <div className="expiration-checkbox">
                            <Checkbox
                              name="neverExpiresCheckbox"
                              label="Never Expires"
                              checked={this.state.neverExpiresCheckbox}
                              onChange={this.onExpirationCheckboxChange}
                            />
                          </div>
                        )}
                        <div className="popup-wrap">
                          <Popup
                            trigger={<Image src={infoIcon} />}
                            size="mini"
                            basic={true}
                            content="Not available for claims until the selected date. When the date expires, the value is reverted back to “Yes“. If never expires is selected, you are not available for any claims."
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column>
              {!this.state.availableForClaims && (
                <div className="input-field-container">
                  <div className="availabilityNotes">
                    <div className="header-label-text" style={{ marginBottom: '8px' }}>
                      Availability Note
                    </div>
                    <Form.Input
                      name="availableForClaimsNotes"
                      type={'text'}
                      value={this.state.availableForClaimsNotes}
                      required={!this.state.availableForClaims}
                      readOnly={this.state.availableForClaims}
                      instantValidation={false}
                      validationErrors={{
                        isDefaultRequiredValue: `* Availability Note is required field.`,
                      }}
                      errorLabel={errorLabel}
                      placeholder="Please Enter..."
                      className={
                        'form-input' + (this.state.availableForClaims ? ' readonly-input' : '')
                      }
                      maxLength={140}
                    />
                  </div>
                </div>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="equal">
            <Grid.Column>
              <div className="input-field-container">
                <div className={'input-field-label disabled'}>Registration Type</div>
                <Form.Input
                  name="registrationType"
                  type={'text'}
                  value={
                    user.registrationType
                      ? user.registrationType.replace(/^./, user.registrationType[0].toUpperCase())
                      : undefined
                  }
                  instantValidation={false}
                  required={true}
                  readOnly={true}
                  validationErrors={{
                    isDefaultRequiredValue: `* Company Name is required field.`,
                  }}
                  errorLabel={errorLabel}
                  className={'form-input readonly-input'}
                />
              </div>
              {this.state.showCompanyName && (
                <div className="input-field-container">
                  <div className={'input-field-label disabled registration-type-label'}>
                    Company Name
                    <Popup
                      trigger={<Image src={infoIcon} />}
                      size="mini"
                      basic={true}
                      content="We pay your company (LLC, sole proprietorship, partnership, limited liability partnership, corporation, etc) instead of compensating you directly."
                    />
                  </div>
                  <Form.Input
                    name="companyName"
                    type={'text'}
                    value={helper.htmlEncode(user.companyName)}
                    placeholder="Please Enter..."
                    instantValidation={false}
                    required={true}
                    readOnly={true}
                    validationErrors={{
                      isDefaultRequiredValue: `* Company Name is required field.`,
                    }}
                    errorLabel={errorLabel}
                    className={'form-input readonly-input'}
                  />
                </div>
              )}
              {fields.map(field => {
                if (field.name === 'positions') {
                  return (
                    <div className="input-field-container" key={field.name}>
                      <div
                        className={
                          field.required ? 'input-field-label' : 'input-field-label disabled'
                        }
                      >
                        {field.label}
                      </div>
                      {this.state.fullPositions ? (
                        <CheckboxGroup
                          name="positions"
                          abilityType="position"
                          items={this.state.positionsOptions}
                          validations={{
                            isChecked: true,
                          }}
                          value=""
                          validationErrors={{
                            isChecked: '* Positions is required field.',
                          }}
                          errorLabel={errorLabel}
                        />
                      ) : (
                        <div className="checkbox-group" style={{ position: 'relative' }}>
                          <div className="positions-string">
                            {this.state.activePositions.map((input, index) => (
                              <span key={index}>
                                {input.label ? input.label : input}
                                {index === this.state.activePositions.length - 1 ? null : ', '}
                              </span>
                            ))}
                          </div>
                          <Button
                            type="button"
                            className="iconic-button-custom"
                            onClick={() =>
                              this.setState({ fullPositions: !this.state.fullPositions })
                            }
                            style={{
                              position: 'absolute',
                              right: '3px',
                              top: '5px',
                              display: this.state.fullPositions ? 'none' : 'flex',
                            }}
                          >
                            <Image src={dropdownIcon} />
                          </Button>
                        </div>
                      )}
                    </div>
                  );
                } else if (
                  field.name === 'phone' ||
                  field.name === 'fax' ||
                  field.name === 'mobilePhone'
                ) {
                  return (
                    <div
                      className={`input-field-container phone-field-custom-input ${
                        field.name === 'mobilePhone' &&
                        this.state.showCompassLabel === 'readonly-input'
                          ? 'read-only-phone-input'
                          : ''
                      }`}
                      key={field.name}
                    >
                      <div
                        className={
                          field.required ? 'input-field-label' : 'input-field-label disabled'
                        }
                      >
                        {field.label}
                      </div>
                      <PhoneInput
                        name={field.name}
                        showLabel={
                          field.name === 'mobilePhone' &&
                          this.state.showCompassLabel === 'readonly-input'
                        }
                        changeLabel={
                          <p
                            className="change-input-label"
                            onClick={() => this.setState({ showCompassLabel: '' })}
                          >
                            CHANGE
                          </p>
                        }
                        instantValidation={false}
                        value={this.props.user[field.name]}
                        required={field.required}
                        disabled={field.disabled}
                        readOnly={
                          field.name === 'mobilePhone' &&
                          this.state.showCompassLabel === 'readonly-input'
                        }
                        validations={field.validations}
                        validationErrors={{
                          isDefaultRequiredValue: `* ${field.label} is required field.`,
                          ...field.validationErrors,
                        }}
                        errorLabel={errorLabel}
                      />
                    </div>
                  );
                } else if (field.type === 'date') {
                  return (
                    <div className="input-field-container" key={field.name}>
                      <div
                        className={
                          field.required ? 'input-field-label' : 'input-field-label disabled'
                        }
                      >
                        {field.label}
                      </div>
                      <DatePickerInput
                        name={field.name}
                        required={true}
                        validations={{
                          isValidMax: moment(),
                          isValidDate: true,
                        }}
                        validationErrors={{
                          isDefaultRequiredValue: `* ${field.label} is required field.`,
                          isValidMax: `* Date should not be later than current date.`,
                          isValidDate: `* Date format is invalid.`,
                        }}
                        value={this.props.user[field.name] === 0 ? '' : this.props.user[field.name]}
                        errorLabel={errorLabel}
                      />
                    </div>
                  );
                } else if (field.type === 'dropdown') {
                  return (
                    <div className="input-field-container" key={field.name}>
                      <div className="input-field-label">{field.label}</div>
                      <div className="form-input dropdown">
                        <Form.Dropdown
                          search={true}
                          name={field.name}
                          options={field.options}
                          placeholder="Please Select"
                          instantValidation={false}
                          required={true}
                          validationErrors={{
                            isDefaultRequiredValue: `* ${field.label} is required field.`,
                          }}
                          className="form-input-short form-input-dropdown"
                          errorLabel={errorLabel}
                          value={user[field.name]}
                        />
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="input-field-container" key={field.name}>
                      <div
                        className={
                          field.required ? 'input-field-label' : 'input-field-label disabled'
                        }
                      >
                        {field.label}
                      </div>
                      <Form.Input
                        name={field.name}
                        type={field.type}
                        label={
                          field.name === 'email' &&
                          this.state.showEmailLabel === 'readonly-input' ? (
                            <p
                              className="change-input-label"
                              onClick={() => this.setState({ showEmailLabel: '' })}
                            >
                              CHANGE
                            </p>
                          ) : (
                            false
                          )
                        }
                        labelPosition={field.name === 'email' ? 'right' : undefined}
                        value={user[field.name]}
                        disabled={field.name === 'compassEmail'}
                        placeholder={field.name === 'compassEmail' ? 'N/A' : 'Please Enter...'}
                        instantValidation={false}
                        required={field.required}
                        readOnly={
                          field.name === 'email' && this.state.showEmailLabel === 'readonly-input'
                        }
                        validationErrors={{
                          isDefaultRequiredValue: `* ${field.label} is required field.`,
                        }}
                        errorLabel={errorLabel}
                        className={
                          field.name === 'email'
                            ? `${field.className} ${this.state.showEmailLabel}`
                            : field.className
                        }
                      />
                    </div>
                  );
                }
              })}
              <div className="input-field-container">
                <div className="input-field-label">State/Province</div>
                <div className="zip-form-input">
                  <Form.Dropdown
                    search={true}
                    name="state"
                    options={statesUS}
                    placeholder="Please Select"
                    instantValidation={false}
                    required={true}
                    validationErrors={{
                      isDefaultRequiredValue: `* State is required field.`,
                    }}
                    className="form-input-short form-input-dropdown"
                    errorLabel={errorLabel}
                    value={user.state}
                  />
                  <div className="zip zip-code-input">
                    <div style={{ paddingTop: '8px', marginRight: '5px' }}>ZIP</div>
                    <Form.Input
                      name="zip"
                      placeholder="Enter"
                      instantValidation={false}
                      required={true}
                      validationErrors={{
                        isDefaultRequiredValue: `* Zip is required field.`,
                      }}
                      errorLabel={errorLabel}
                      className="form-input-short"
                      value={user.zip}
                    />
                  </div>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="input-field-container">
                <div className="input-field-label disabled">XACTNET Address</div>
                <Form.Input
                  name="xactnetAddress"
                  placeholder="Please Enter..."
                  instantValidation={false}
                  className="form-input"
                  value={user.xactnetAddress}
                />
              </div>

              <div className="input-field-container">
                <div className="input-field-label" />
                <div className="remark-label" style={{ width: '65%', minWidth: '65%' }}>
                  We highly recommend that you provide your XACTNET Address.{' '}
                </div>
              </div>

              {!this.state.education || this.state.education.length === 0 ? (
                <div className="input-field-container">
                  <div className="input-field-label">Education</div>

                  <Form.Input
                    name="education"
                    placeholder="Nothing Added"
                    instantValidation={false}
                    className="form-input readonly-input"
                    readOnly={true}
                    validationErrors={{
                      isDefaultRequiredValue: `* Education is required field.`,
                    }}
                    errorLabel={errorLabel}
                  />
                </div>
              ) : (
                this.state.education.map((el, index) => (
                  <div className="input-field-container" key={index}>
                    <div className="input-field-label">{index === 0 ? 'Education' : null}</div>
                    <EducationItem
                      edit={this.editEducation}
                      delete={this.deleteEducation}
                      item={el}
                      id={index}
                    />
                  </div>
                ))
              )}
              <div className="input-field-container">
                <div className="input-field-label" />
                <AddEducation getEducation={this.getEducation} />
              </div>

              {!this.state.references || this.state.references.length === 0 ? (
                <div className="input-field-container">
                  <div className="input-field-label">References</div>

                  <Form.Input
                    name="references"
                    placeholder="Nothing Added"
                    instantValidation={false}
                    className="form-input readonly-input"
                    validationErrors={{
                      isDefaultRequiredValue: `* References is required field.`,
                    }}
                    errorLabel={errorLabel}
                    readOnly={true}
                  />
                </div>
              ) : (
                this.state.references.map((el, index) => (
                  <div className="input-field-container" key={index}>
                    <div className="input-field-label">{index === 0 ? 'References' : null}</div>
                    <ReferenceItem
                      edit={this.editReference}
                      delete={this.deleteReference}
                      item={el}
                      id={index}
                    />
                  </div>
                ))
              )}

              <div className="input-field-container">
                <div className="input-field-label" />
                <AddReference getReference={this.getReference} />
              </div>
              <div className="input-field-container">
                <div className="remark-label">
                  CAT Address represents your temporary location. Do not populate it if it is same
                  as your residence address.
                </div>
                <div />
              </div>
              <div className="input-field-container temporary-location">
                <div className="input-field-label">
                  Do you have a CAT Address (Temporary Location)?
                </div>
                <RadioGroup
                  name="temporaryLocation"
                  required={true}
                  onChange={this.handleTemporaryLocationRadio}
                  className="registration-type-group"
                  value={temporaryLocation ? temporaryLocation.toString() : 'false'}
                  validationErrors={{
                    isDefaultRequiredValue: `* Temporary Location is required field.`,
                  }}
                  errorLabel={errorLabel}
                >
                  <Radio label="Yes" value={'true'} />
                  <Radio label="No" value={'false'} />
                </RadioGroup>
              </div>
              {temporaryLocation && (
                <>
                  <div className="input-field-container">
                    <div className="input-field-label">CAT Address City</div>
                    <Form.Input
                      name="catCity"
                      placeholder="Please Enter..."
                      required={true}
                      instantValidation={false}
                      className="form-input"
                      value={isCatValuesCleared ? '' : user.catCity}
                      validationErrors={{
                        isDefaultRequiredValue: `* CAT City is required field.`,
                      }}
                      errorLabel={errorLabel}
                    />
                  </div>
                  <div className="input-field-container">
                    <div className="input-field-label">CAT State/Province</div>
                    <Form.Dropdown
                      search={true}
                      name="catStateProvince"
                      placeholder="Please Select"
                      options={[{ text: '', value: '' }, ...statesUS]}
                      instantValidation={false}
                      className="form-input-short form-input-dropdown state-province-dropdown"
                      value={isCatValuesCleared ? '' : user.catStateProvince}
                      required={true}
                      validationErrors={{
                        isDefaultRequiredValue: `* Cat State is required field.`,
                      }}
                      errorLabel={errorLabel}
                    />
                  </div>
                  <div className="input-field-container">
                    <div className="input-field-label">CAT Zip</div>
                    <Form.Input
                      name="catZip"
                      placeholder="Please Enter..."
                      instantValidation={false}
                      className="form-input"
                      value={isCatValuesCleared ? '' : user.catZip}
                      required={true}
                      validationErrors={{
                        isDefaultRequiredValue: `* Cat Zip is required field.`,
                      }}
                      errorLabel={errorLabel}
                    />
                  </div>
                  <div className="input-field-container">
                    <div className="input-field-label">CAT Expiration Date</div>
                    <DatePickerInput
                      name="catExpirationDate"
                      required={true}
                      validations={{ isValidMin: moment(), isValidDate: 'cat' }}
                      validationErrors={{
                        isValidMin: `* Date should not be earlier than current date.`,
                        isValidDate: `* Date format is invalid.`,
                        isDefaultRequiredValue: `* Cat Expiration Date is required field.`,
                      }}
                      value={catExpirationValue}
                      errorLabel={errorLabel}
                    />
                  </div>
                </>
              )}
            </Grid.Column>
          </Grid.Row>
          <hr />
          <Grid.Row columns="equal" className="password-change-profile-row">
            <Grid.Column>
              <div className="input-field-container">
                <div className="remark-label">
                  Leave the password field empty if you do not want to change it.
                </div>
              </div>
              <div className="input-field-container password-profile-input">
                <div className="input-field-label disabled">New Password</div>
                <Form.Input
                  name="password"
                  autoComplete="new-password"
                  icon={
                    <Popup
                      trigger={
                        <i
                          className={`icon link custom-icon`}
                          onClick={() => this.setState({ showPass: !this.state.showPass })}
                        >
                          <Image
                            src={eyeIcon}
                            className={this.state.showPass ? 'show-pass-icon' : 'hide-pass-icon'}
                          />
                        </i>
                      }
                      size="mini"
                      basic={true}
                      content={this.state.showPass ? 'Hide Password' : 'View Password'}
                    />
                  }
                  type={this.state.showPass ? 'text' : 'password'}
                  instantValidation={false}
                  onChange={e => this.handlePass(e)}
                  validations={{
                    hasLowercaseProfile: true,
                    hasUppercaseProfile: true,
                    hasSpecialProfile: true,
                    hasNumbersProfile: true,
                    minLength: 8,
                  }}
                  validationErrors={{
                    hasLowercaseProfile:
                      'The password must contain at least 1 lowercase character.',
                    hasUppercaseProfile:
                      'The password must contain at least 1 uppercase character.',
                    hasSpecialProfile: 'The password must contain at least 1 special character.',
                    hasNumbersProfile: 'The password must contain at least 1 number.',
                    minLength: 'The password must be at least 8 symbols long.',
                  }}
                  errorLabel={errorLabel}
                  className="form-input"
                />
              </div>
              <div className="input-field-container">
                <div className="input-field-label disabled">Repeat Password</div>
                <Form.Input
                  name="passwordConfirm"
                  autoComplete="new-password"
                  type={this.state.showPass ? 'text' : 'password'}
                  instantValidation={false}
                  validations="passConfirm"
                  validationErrors={{
                    passConfirm: 'Passwords do not match.',
                  }}
                  errorLabel={errorLabel}
                  className="form-input"
                />
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="input-field-container">
                <div>
                  <br />
                </div>
              </div>
              <div className="input-field-container">
                <div className="input-field-label">Subscription to Compass email updates</div>
                <div className="toggle-block-container-profile">
                  <div className="toggle-block">
                    <Button
                      type="button"
                      className={this.state.isSubscribedToCompassMailUpdates ? 'yes-btn' : 'no-btn'}
                      onClick={() => this.setState({ isSubscribedToCompassMailUpdates: true })}
                    >
                      YES
                    </Button>
                    <Button
                      type="button"
                      className={
                        !this.state.isSubscribedToCompassMailUpdates ? 'yes-btn' : 'no-btn'
                      }
                      onClick={() => this.setState({ isSubscribedToCompassMailUpdates: false })}
                    >
                      NO
                    </Button>
                  </div>
                </div>
              </div>
              <div className="input-field-container">
                <div className="input-field-label">Subscription to Personal email updates</div>
                <div className="toggle-block-container-profile">
                  <div className="toggle-block">
                    <Button
                      type="button"
                      className={
                        this.state.isSubscribedToPersonalMailUpdates ? 'yes-btn' : 'no-btn'
                      }
                      onClick={() => this.setState({ isSubscribedToPersonalMailUpdates: true })}
                    >
                      YES
                    </Button>
                    <Button
                      type="button"
                      className={
                        !this.state.isSubscribedToPersonalMailUpdates ? 'yes-btn' : 'no-btn'
                      }
                      onClick={() => this.setState({ isSubscribedToPersonalMailUpdates: false })}
                    >
                      NO
                    </Button>
                  </div>
                </div>
              </div>
              <Button ref={this.submitRef} type="submit" style={{ display: 'none' }} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

export default ProfileDetails;
