import MyTypes from 'MyTypes';
import React from 'react';
import { trackPromise } from 'react-promise-tracker';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Grid } from 'semantic-ui-react';
import { trainingCenterActions } from '../../../features/training-center';
import { MaterialModel, PaginationInfo } from '../../../features/training-center/reducer';
import { IUser } from '../../../models';
import services from '../../../services';
import { LoadingSpiner } from '../../loading-spinner/LoadingSpinner';
import { NoResults } from '../../no-results-placeholder/NoResults';
import MaterialFilter from '../material-filter/MaterialFilter';
import MaterialListView from './MaterialListView';
import MaterialPagination from './MaterialPagination';
import MaterialGridView from './MaterialGridView';
import MaterialDetails from '../material-details/MaterialDetails';

interface Props {
  user: IUser;
  searchMaterial: typeof trainingCenterActions.searchMaterial;
  pagingInfo: PaginationInfo;
  viewType?: 'grid' | 'list';
  selectedFolderId?: number;
  selectedMaterials: Array<number>;
  setSelectedMaterial: typeof trainingCenterActions.setSelectedMaterial;
  removeSelectedMaterials: typeof trainingCenterActions.removeSelectedMaterials;
}

interface State {
  materialDetails: MaterialModel | null;
}

class MaterialList extends React.Component<Props, State> {
  public state = {
    materialDetails: null,
  };

  public handlePagination = (e: any, data: any) => {
    const { pagingInfo } = this.props;
    const pageSize = data.pageSize || 20;

    if (data.pageSize) {
      data.activePage = 1;
    }

    this.props.searchMaterial({
      ...pagingInfo.filter,
      activePage: data.activePage,
      resetSelect: false,
      pageSize,
    });
  };

  public componentDidMount = () => {
    const { pagingInfo } = this.props;
    this.props.searchMaterial({
      ...pagingInfo,
      activePage: 1,
      resetSelect: true,
    });
  };

  public renderNoResult() {
    const { pagingInfo } = this.props;

    if (!pagingInfo || pagingInfo.records.length === 0) {
      return <NoResults />;
    }
    return null;
  }

  public selectMaterial(material: { id: number }) {
    const isSelected = this.props.selectedMaterials.find(el => el === material.id);
    if (!isSelected) {
      this.props.setSelectedMaterial(material.id);
    } else {
      this.props.removeSelectedMaterials(material.id);
    }
  }

  public async downloadMaterial(material: MaterialModel) {
    if (!material.fileKey) {
      return;
    }

    const fileKey = material.fileKey;
    const downloadUrl = await trackPromise(
      services.api.file.getDownloadFileUrl(fileKey),
      'material-list'
    );
    if (downloadUrl) {
      services.api.file.downloadFile(downloadUrl, material.fileName, material.name);
    }
  }

  public onMaterialDetailsClose() {
    this.setState({ materialDetails: null });
  }

  public selectAllMaterial(id: number) {}

  public viewMaterialDetails(material: MaterialModel) {
    this.setState({ materialDetails: material });
  }

  public render() {
    const { pagingInfo, viewType } = this.props;
    return (
      <React.Fragment>
        <LoadingSpiner area="material-list" />
        <Grid columns="1">
          <Grid.Row>
            <Grid.Column className=" folder-list-column" width={16}>
              <MaterialFilter />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column className=" folder-list-column" width={16}>
              {this.renderNoResult()}
              {viewType === 'list' && (
                <MaterialListView
                  selectMaterial={(data: any) => {
                    this.selectMaterial(data);
                  }}
                  downloadMaterial={(data: any) => this.downloadMaterial(data)}
                  onMaterialClick={(data: any) => this.viewMaterialDetails(data)}
                />
              )}
              {viewType === 'grid' && (
                <MaterialGridView
                  selectMaterial={(data: any) => {
                    this.selectMaterial(data);
                  }}
                  downloadMaterial={(data: any) => this.downloadMaterial(data)}
                  onMaterialClick={(data: any) => this.viewMaterialDetails(data)}
                />
              )}
              <MaterialPagination
                pagingInfo={pagingInfo}
                handlePagination={this.handlePagination}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {this.state.materialDetails !== null && (
          <MaterialDetails
            material={this.state.materialDetails as MaterialModel}
            onClose={() => this.onMaterialDetailsClose()}
            onDownload={() => {
              this.downloadMaterial(this.state.materialDetails as MaterialModel);
              this.onMaterialDetailsClose();
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
  pagingInfo: state.trainingCenter.material.pagingInfo,
  selectedMaterials: state.trainingCenter.material.pagingInfo.selected,
  viewType: state.trainingCenter.material.viewType,
  selectedFolderId: state.trainingCenter.material.selectedFolderId,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      searchMaterial: trainingCenterActions.searchMaterial,
      setSelectedMaterial: trainingCenterActions.setSelectedMaterial,
      removeSelectedMaterials: trainingCenterActions.removeSelectedMaterials,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MaterialList);
