import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Grid, Header, Image } from 'semantic-ui-react';
import compassLogo from '../../assets/images/compass-logo-vector.svg';
import { accountActions } from '../../features/account';

interface Props {
  emailVerificationAttempt: typeof accountActions.emailVerificationAttempt;
  errors: string[];
}

interface State {}

class EmailVerify extends React.Component<Props, State> {
  public componentWillMount() {
    const params = new URLSearchParams(location.search);
    const userId = params.get('userId') as string;
    const verificationToken = params.get('token') as string;
    this.props.emailVerificationAttempt({
      userId,
      verificationToken,
    });
  }

  public render() {
    return (
      <div>
        <div className="logo-container">
          <Image src={compassLogo} />
        </div>
        <Grid centered={true} style={{ height: '100%' }} verticalAlign="middle">
          <Grid.Column style={{ maxWidth: 500 }}>
            <Header
              as="h5"
              icon={true}
              textAlign="center"
              style={{ marginTop: '50px', fontWeight: '800', fontSize: '32px' }}
            >
              <Header.Content>Verifying Email</Header.Content>
            </Header>
            <p style={{ textAlign: 'center' }}>Please wait...</p>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  errors: state.account.emailVerification.errors,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      emailVerificationAttempt: accountActions.emailVerificationAttempt,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailVerify);
