import * as Formsy from 'formsy-react';
import { Form } from 'formsy-semantic-ui-react';
import moment from 'moment';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Confirm, Image, Modal, Popup } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import { uploads } from '../../config';
import services from '../../services';
import DatePickerInput from '../datepicker-input/DatePickerInput';
import ErrorLabel from '../error-label/ErrorLabel';
import UploadZone from './../file-uploader/UploadZone';

interface Props {
  getLicense: (item: object, id?: number) => void;
  mode: string;
}

interface State {
  showModal: boolean;
  showConfirm: boolean;
  showFile: boolean;
  file: string | ArrayBuffer | null;
  fileInitial: any;
  fileType: string;
  fileError: boolean;
  fileSize: string | number;
  fileName: string;
  statesOptions: object[];
}

Formsy.addValidationRule('isValidExpirationDate', (values: any, value: any) => {
  if (moment(value) < moment(values.issuedDate)) {
    return false;
  } else {
    return true;
  }
});

class AddLicenseModal extends React.Component<Props, State> {
  public state = {
    showModal: false,
    showConfirm: false,
    statesOptions: [],
    showFile: false,
    file: '',
    fileInitial: '',
    fileType: '',
    fileSize: '',
    fileName: '',
    fileError: false,
  };

  public fileInputRef = React.createRef();

  public componentDidMount = async () => {
    if (this.props.mode === 'license') {
      const request = await services.api.account.getSettingsData('state');
      if (request.isSuccess) {
        const statesOptions = request.data.list.map((el: any, index: any) => {
          const element = { text: el.name, value: el.name };
          return element;
        });
        this.setState({ statesOptions });
      }
    } else {
      const request = await services.api.account.getSettingsData('certification');
      if (request.isSuccess) {
        const statesOptions = request.data.list.map((el: any, index: any) => {
          const element = { text: el.name, value: el.name };
          return element;
        });
        this.setState({ statesOptions });
      }
    }
  };

  public handleFileDrop = (files: any) => {
    const file = files[0];
    if (file === undefined) {
      this.setState({ fileError: true });
    } else {
      if (file.size > uploads.maxFileSize) {
        return toast.warn(uploads.maxFileSizeErrorMsg);
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
        this.setState({
          file: reader.result,
          fileInitial: file,
          fileSize: file.size,
          fileName: file.name,
          showFile: true,
          fileType: file.type,
          fileError: false,
        });
      };
    }
  };

  public closeModal = () => {
    this.setState({
      showModal: false,
      showFile: false,
      file: '',
      fileInitial: '',
      fileType: '',
      fileSize: '',
      fileName: '',
      fileError: false,
    });
  };

  public render() {
    const errorLabel = <ErrorLabel />;
    const { mode } = this.props;
    const trigger = (
      <div className="add-question">
        <Button type="button" onClick={() => this.setState({ showModal: true })}>
          + ADD
        </Button>
      </div>
    );
    return (
      <Modal
        size="tiny"
        className="licenses-modal"
        trigger={trigger}
        open={this.state.showModal}
        closeIcon={<Image src={closeIcon} className="icon" onClick={() => this.closeModal()} />}
      >
        <Modal.Header>
          {this.props.mode === 'license' ? 'Add License' : 'Add Certification'}
        </Modal.Header>
        <Form
          onValidSubmit={model => {
            const obj = Object.assign(
              {},
              model,
              { id: 1000 * Math.random() },
              {
                file: {
                  file: this.state.file,
                  fileInitial: this.state.fileInitial,
                  fileName: this.state.fileName,
                  fileType: this.state.fileType,
                  fileSize: this.state.fileSize,
                },
              },
              { fileType: this.state.fileType }
            );
            this.props.getLicense(obj);
            this.closeModal();
          }}
        >
          <Modal.Content style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
            <div className="input-field-container">
              <div className="input-field-label">
                {this.props.mode === 'license' ? 'State' : 'Certification'}
              </div>
              <Form.Dropdown
                search={true}
                selectOnBlur={false}
                name={mode === 'cert' ? 'type' : 'state'}
                options={this.state.statesOptions}
                placeholder="Please Select"
                instantValidation={false}
                required={true}
                validationErrors={{
                  isDefaultRequiredValue: `* ${
                    this.props.mode === 'license' ? 'State' : 'Certification'
                  } is required field.`,
                }}
                className="form-input-dropdown"
                errorLabel={errorLabel}
              />
            </div>
            <div className="input-field-container">
              <div className="input-field-label">
                {this.props.mode === 'license' ? 'License' : 'Certification'} #
              </div>
              <Form.Input
                name={mode === 'cert' ? 'certification' : 'license'}
                placeholder="Please Enter..."
                instantValidation={false}
                required={true}
                maxLength={30}
                validationErrors={{
                  isDefaultRequiredValue: `* ${
                    this.props.mode === 'license' ? 'License' : 'Certification'
                  } # is required field.`,
                }}
                className="form-input"
                errorLabel={errorLabel}
              />
            </div>
            <div className="input-field-container">
              <div className="input-field-label">Issued Date</div>
              <DatePickerInput
                name="issuedDate"
                value=""
                required={true}
                validations={{
                  isValidMax: moment(),
                  isValidDate: true,
                }}
                validationErrors={{
                  isDefaultRequiredValue: `* Issued Date is required field.`,
                  isValidMax: `* Date should not be later than current date.`,
                  isValidDate: `* Date format is invalid.`,
                }}
                errorLabel={errorLabel}
              />
            </div>
            <div className="input-field-container">
              <div className="input-field-label">Expiration Date</div>
              <DatePickerInput
                name="expirationDate"
                value=""
                required={true}
                validations={{ isValidExpirationDate: true, isValidDate: true }}
                validationErrors={{
                  isDefaultRequiredValue: `* Expiration Date is required field.`,
                  isValidExpirationDate: `* Date should not be earlier than issued date.`,
                  isValidDate: `* Date format is invalid.`,
                }}
                errorLabel={errorLabel}
              />
            </div>
            <div className="input-field-container">
              <div className="input-field-label">
                {this.props.mode === 'license' ? 'Licenses' : 'Certifications'}
              </div>
              <div />
            </div>
            {!this.state.showFile ? (
              <div>
                <UploadZone
                  onDrop={this.handleFileDrop}
                  accept="image/jpeg, image/png, application/pdf"
                />
                {this.state.fileError && (
                  <ErrorLabel>
                    <div style={{ paddingLeft: '1rem' }}>
                      * Only *.pdf, *.jpg, *.jpeg, *.png files are supported.
                    </div>
                  </ErrorLabel>
                )}
              </div>
            ) : (
              <div className="licenses-file-preview">
                {this.state.fileType === 'application/pdf' ? (
                  <div className="pdf-file-container">PDF</div>
                ) : (
                  <Image src={this.state.file} />
                )}
                <Popup
                  trigger={
                    <Button
                      type="button"
                      className="iconic-button-custom"
                      onClick={() => this.setState({ showConfirm: true })}
                    >
                      <Image src={closeIcon} />
                    </Button>
                  }
                  size="mini"
                  basic={true}
                  content="Delete"
                />
              </div>
            )}
          </Modal.Content>

          <div className="modal-button-footer">
            <Button
              type="button"
              style={{ width: '115px' }}
              className="secondary-button"
              secondary={true}
              onClick={() => this.closeModal()}
            >
              CANCEL
            </Button>
            <Button style={{ width: '115px' }} primary={true}>
              ADD
            </Button>
          </div>
        </Form>
        <Confirm
          size="tiny"
          closeIcon={
            <Image
              src={closeIcon}
              className="icon"
              onClick={() => this.setState({ showConfirm: false })}
            />
          }
          open={this.state.showConfirm}
          onCancel={() => this.setState({ showConfirm: false })}
          header="Confirm"
          content="Are you sure you want to delete this file?"
          cancelButton="NO"
          confirmButton="YES"
          onConfirm={() =>
            this.setState({
              showFile: false,
              file: '',
              fileInitial: '',
              fileType: '',
              fileSize: '',
              fileName: '',
              fileError: false,
              showConfirm: false,
            })
          }
          className="confirm-modal-custom"
          closeOnDimmerClick={false}
        />
      </Modal>
    );
  }
}

export default AddLicenseModal;
