export const CHECK_AUTHENTICATION = 'account/CHECK_AUTHENTICATION';

export const CHECK_AUTHENTICATION_FINISH = 'account/CHECK_AUTHENTICATION_FINISH';

export const UPDATE_USER_DATA = 'account/UPDATE_USER_DATA';

export const ADMIN_LOGIN = 'account/ADMIN_LOGIN';

export const LOGIN_ATTEMPT = 'account/LOGIN_ATTEMPT';

export const RECOVER_ATTEMPT = 'account/RECOVER_ATTEMPT';

export const RESET_PASSWORD_ATTEMPT = 'account/RESET_PASSWORD_ATTEMPT';

export const SOCIAL_LOGIN_ATTEMPT = 'account/SOCIAL_LOGIN_ATTEMPT';

export const LOGIN_ATTEMPT_FAILED = 'account/LOGIN_ATTEMPT_FAILED';

export const LOGIN_SUCCESS = 'account/LOGIN_SUCCESS';

export const REGISTER_IS_NEW_EMAIL = 'account/REGISTER_IS_NEW_EMAIL';

export const REGISTER_NEW_PASSWORD = 'account/REGISTER_NEW_PASSWORD';

export const REGISTER_ATTEMPT = 'account/REGISTER_ATTEMPT';

export const REGISTER_ATTEMPT_FAILED = 'account/REGISTER_ATTEMPT_FAILED';

export const REGISTER_SUCCESS = 'account/REGISTER_SUCCESS';

export const REGISTER_NEXT_STEP = 'account/REGISTER_NEXT_STEP';

export const REGISTER_PREV_STEP = 'account/REGISTER_PREV_STEP';

export const SUBMIT_SOCIAL_REGISTER = 'account/SUBMIT_SOCIAL_REGISTER';

export const CHANGE_REGISTER_STORE = 'account/CHANGE_REGISTER_STORE';

export const SET_NEW_USER_DATA = 'account/SET_NEW_USER_DATA';

export const EMAIL_VERIFICATION_ATTEMPT = 'account/EMAIL_VERIFICATION_ATTEMPT';

export const EMAIL_VERIFICATION_ATTEMPT_FAILED = 'account/EMAIL_VERIFICATION_ATTEMPT_FAILED';

export const EMAIL_VERIFICATION_SUCCESS = 'account/EMAIL_VERIFICATION_SUCCESS';

export const LOGOUT = 'account/LOGOUT';

export const CHANGE_PROGRESS_VALUE = 'account/CHANGE_PROGRESS_VALUE';

export const POST_REGISTER_FILES = 'account/POST_REGISTER_FILES';

export const PHONE_CONFIRM = 'account/PHONE_CONFIRM';

export const PHONE_VERIFY = 'account/PHONE_VERIFY';

export const PHONE_CONFIRM_ATTEMPT = 'account/PHONE_CONFIRM_ATTEMPT';

export const DOWNLOAD_FILE = 'account/DOWNLOAD_FILE';

export const RESET_REGISTER_STEPS = 'account/RESET_REGISTER_STEPS';

export const DOWNLOAD_PAPERWORK = 'account/DOWNLOAD_PAPERWORK';

export const STANDBY_LIST_QUICK_SIGN_UP = 'account/STANDBY_LIST_QUICK_SIGN_UP';