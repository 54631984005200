import React from 'react';
import { Grid } from 'semantic-ui-react';
import { LoadingSpiner } from '../loading-spinner/LoadingSpinner';
import MaterialList from './material-list/MaterialList';
import SwitchViewMaterial from './switch-view-material/SwitchViewMaterial';
import MaterialActionMenu from './material-action-menu/MaterialActionMenu';
import TrainingCenterFoldersList from './TrainingCenterFoldersList';
import './TrainingCenter.css';
import FolderListRecursive from './folderListRecursive';

interface Props {}

interface State {}

export default class TrainingCenter extends React.Component<Props, State> {
  public render() {
    return (
      <React.Fragment>
        <Grid columns="2">
          <LoadingSpiner area="training-center-files-area" />
          <Grid.Row className="files-grid-row questions-grid-row">
            <Grid.Column className=" folder-list-column switch-view">
              <SwitchViewMaterial />
            </Grid.Column>
            <Grid.Column className=" folder-list-column" width={13}>
              <MaterialActionMenu />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="files-grid-row questions-grid-row">
            {/* <Grid.Column className=" folder-list-column folder-list">
              <TrainingCenterFoldersList />
            </Grid.Column> */}
            <Grid.Column className=" folder-list-column folder-list">
              <FolderListRecursive init={true} />
            </Grid.Column>
            <Grid.Column className=" folder-list-column" width={13}>
              <MaterialList />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  }
}
