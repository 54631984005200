import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';
import * as actions from './actions';
import * as constants from './constants';
import * as helper from '../../utils/helper';

export interface State {
  folder: Folder;
  folderList: Folder[];
  parentFolder: Folder[];
  material: TrainingCenterMaterial;
}

export interface Folder {
  id: number;
  name: string;
  order: number | undefined;
  parentId: number | undefined;
  parent: Folder | undefined;
  open?: boolean;
  active?: boolean;
  childActive?: boolean;
  parentActive?: boolean;
  parentOpen?: boolean;
  subFolders: Folder[];
  enter?: boolean;
  childEnter?: boolean;
  parentEnter?: boolean;
}

export interface PaginationInfo {
  activePage: number;
  pageSize: number;
  search: string;
  totalPages: number;
  totalRecords: number;
  records: Array<any>;
  allRecords: Array<any>;
  selected: Array<any>;
  filter?: any;
  resetSelect: boolean;
}

export interface TrainingCenterMaterial {
  selectedFolderId?: number;
  viewType: 'list' | 'grid';
  pagingInfo: PaginationInfo;
  showFilter: boolean;
}

export interface MaterialModel {
  id: number;
  name: string;
  thumbnailUrl: string;
  videoUrl: string;
  description: string;
  updatedAt: number;
  fileKey: string;
  fileName: string;
  type: string;
  mimetype: string;
  admin: {
    id: number;
    avatar?: string;
    firstName: string;
    lastName: string;
  };
  folders: { id: number; name: string }[];
}

export function initPagingInfo(): PaginationInfo {
  return {
    pageSize: 20,
    search: '',
    totalPages: 0,
    totalRecords: 0,
    activePage: 1,
    records: [],
    allRecords: [],
    selected: [],
    filter: {},
    resetSelect: false,
  };
}

export function initMaterialState(): TrainingCenterMaterial {
  return {
    selectedFolderId: undefined,
    viewType: 'list',
    pagingInfo: initPagingInfo(),
    showFilter: false,
  };
}

export const materialState = initMaterialState();
export const folderListState: Folder[] = [];

export interface CreateCustomEvent {
  name: string;
}

export type Action = ActionType<typeof actions>;

export default combineReducers<State, Action>({
  folderList: (state = folderListState, action) => {
    switch (action.type) {
      case constants.GET_FILES_LIST:
        return [];
      case constants.SET_FOLDER_LIST:
        return action.payload.list as Folder[];
      case constants.SET_FOLDER_TOGGLE:
        return state
          .map((t) => {
            if (t.id === action.payload.id) {
              return { ...t, open: !t.open };
            }
            return t;
          })
          .concat();
      case constants.SET_FOLDER_ACTIVE:
        const activeFolder = action.payload.folder as Folder;
        return state
          .map((t) => {
            t.active = t.id === activeFolder.id;
            t.childActive = t.id === activeFolder.parentId;
            t.subFolders = t.subFolders.map((child) => {
              child.active = activeFolder.id === child.id;
              child.parentActive = activeFolder.id === child.parentId;
              child.parentOpen = activeFolder.parentId === child.parentId;
              return child;
            });
            return t;
          })
          .concat();
      case constants.REMOVE_FOLDER_ACTIVE:
        return state.map((t) => {
          t.active = false;
          t.childActive = false;
          t.open = false;
          t.subFolders = t.subFolders.map((child) => {
            child.active = false;
            child.parentActive = false;
            child.parentOpen = false;
            return child;
          });

          return t;
        });
      case constants.SET_FOLDER_ENTER:
        const enterFolder = action.payload.folder as Folder;
        return state
          .map((t) => {
            t.enter = t.id === enterFolder.id;
            t.childEnter = t.id === enterFolder.parentId;
            t.subFolders = t.subFolders.map((child) => {
              child.enter = enterFolder.id === child.id;
              child.parentEnter = enterFolder.id === child.parentId;
              return child;
            });
            return t;
          })
          .concat();
      case constants.SET_FOLDER_LEAVE:
        const leaveFolder = action.payload.folder as Folder;
        return state
          .map((t) => {
            t.enter = t.id === leaveFolder.id ? false : t.enter;
            t.childEnter = t.id === leaveFolder.parentId ? false : t.childEnter;
            t.subFolders = t.subFolders.map((child) => {
              child.enter = leaveFolder.id === child.id ? false : child.enter;
              child.parentEnter = leaveFolder.id === child.parentId ? false : child.parentEnter;
              return child;
            });
            return t;
          })
          .concat();

      default:
        return state;
    }
  },
  material: (state = materialState, action: any) => {
    switch (action.type) {
      case constants.SET_VIEW_TYPE_LIST:
        return { ...state, viewType: 'list' };
      case constants.SET_VIEW_TYPE_GRID:
        return { ...state, viewType: 'grid' };
      case constants.CLOSE_MATERIAL_FILTER:
        return { ...state, showFilter: false };
      case constants.OPEN_MATERIAL_FILTER:
        return { ...state, showFilter: true };
      case constants.SEARCH_MATERIAL:
        return { ...state, pagingInfo: { ...state.pagingInfo, ...action.payload.pagingInfo } };
      case constants.MATERIAL_SELECT:
        const selectedMaterialId = action.payload.id;
        if (state.pagingInfo.selected.find((t) => t === selectedMaterialId)) {
          state.pagingInfo.selected = state.pagingInfo.selected.filter(
            (t) => t !== selectedMaterialId
          );
        } else {
          state.pagingInfo.selected.push(selectedMaterialId);
        }
        return {
          ...state,
          pagingInfo: { ...state.pagingInfo, selected: state.pagingInfo.selected.concat() },
        };
      case constants.MATERIAL_SELECT_ALL:
        return {
          ...state,
          pagingInfo: { ...state.pagingInfo, selected: state.pagingInfo.allRecords.concat() },
        };
      case constants.MATERIAL_SET_SEARCH_RESULT:
        let materialPagingInfo = state.pagingInfo;
        materialPagingInfo = {
          ...materialPagingInfo,
          totalRecords: action.payload.total,
          records: action.payload.data,
          totalPages: helper.getTotalPage(action.payload.total, materialPagingInfo.pageSize),
        };
        return { ...state, pagingInfo: materialPagingInfo };
      case constants.REMOVE_SELECTED_MATERIALS:
        const removedMaterials = action.payload.data as Array<number>;
        const selectedMaterials = state.pagingInfo.selected
          .filter((t) => {
            const currentItem = removedMaterials.find((removed) => removed === t);
            if (currentItem) {
              return false;
            }
            return true;
          })
          .filter((t) => t);
        return { ...state, pagingInfo: { ...state.pagingInfo, selected: selectedMaterials } };

      case constants.MATERIAL_SET_ALL_SEARCH_RESULT:
        state.pagingInfo.allRecords = action.payload.data;
        return { ...state, pagingInfo: { ...state.pagingInfo } };
      case constants.SET_SELECTED_MATERIALS:
        state.pagingInfo.selected.push(...action.payload.data);
        const selectedData = new Set(state.pagingInfo.selected);
        return {
          ...state,
          pagingInfo: { ...state.pagingInfo, selected: Array.from(selectedData) },
        };
      default:
        return state;
    }
  },
});
