import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import services from '../../services';
import * as actions from './actions';
import * as constants from './constants';
import { PaginationInfo } from '../../../../web-admin/src/features/training-center/trainingCenterReducer';
import { MaterialFilter } from '../../services/api/material';

export default function*() {
  yield takeEvery(constants.GET_FOLDER_LIST, getFolderList);
  yield takeEvery(constants.SET_FOLDER_ACTIVE, setFolderActive);
}

export function* getFolderList(action: ActionType<typeof actions.getFolderList>) {
  const requestList = yield call(services.api.folder.getFolderList, action.payload.id);

  if (requestList.isSuccess) {
    yield put(actions.setFolderList(requestList.data.data.folders));
  } else {
    toast.dismiss();
    toast.error(requestList.data ? requestList.data.errors[0].msg : `Error ${requestList.status}`);
  }
}

export function* setFolderActive(action: ActionType<typeof actions.setFolderActive>) {
  const { user } = yield select(state => state.account.account);
  const folder = action.payload.folder;
  let folderIds = folder.id.toString();
  if (folder.subFolders && folder.subFolders.length > 0) {
    folderIds = folderIds + ',' + folder.subFolders.map(f => f.id).join(',');
  }

  const requestList = yield call(
    services.api.material.searchMaterial,
    user.id,
    {
      folderIds,
    } as MaterialFilter,
    {
      activePage: 1,
      pageSize: 15,
    } as PaginationInfo
  );
  if (requestList.isSuccess) {
    yield put(
      actions.setMaterialSearchResult(requestList.data.data.list, requestList.data.data.count)
    );
  } else {
    toast.dismiss();
    toast.error(requestList.data ? requestList.data.errors[0].msg : `Error ${requestList.status}`);
  }
}
