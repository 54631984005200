import { Form } from 'formsy-semantic-ui-react';
import React from 'react';
import { Button, Image, Popup } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import editIcon from '../../assets/images/Blue/edit.svg';
import services from '../../services';
import ErrorLabel from '../error-label/ErrorLabel';

interface Props {
  item: object;
  id: number;
  delete: (id: number) => void;
  edit: (id: number, item: object) => void;
}

interface State {
  showCreate: boolean;
  degree: boolean;
  schoolOptions: object[];
}

class EducationItem extends React.Component<Props, State> {
  public state = {
    showCreate: false,
    schoolOptions: [],
    degree: false,
  };

  public componentDidMount = async () => {
    const request = await services.api.account.getSettingsData('school');
    if (request.isSuccess) {
      const schoolOptions = request.data.list.map((el: any, index: any) => {
        const element = { text: el.name, value: el.name };
        return element;
      });
      this.setState({ schoolOptions, degree: this.props.item.degreeAttained });
    }
  };

  public render() {
    const { item, id } = this.props;
    const errorLabel = <ErrorLabel />;
    return (
      <>
        {!this.state.showCreate ? (
          <div className="education-item">
            <div className="close-education-icon">
              <Popup
                trigger={
                  <Button
                    type="button"
                    className="iconic-button-custom"
                    onClick={() => this.props.delete(id)}
                  >
                    <Image src={closeIcon} />
                  </Button>
                }
                size="mini"
                basic={true}
                content="Delete"
              />
            </div>
            <div className="edit-education-icon">
              <Popup
                trigger={
                  <Button
                    type="button"
                    className="iconic-button-custom"
                    onClick={() => this.setState({ showCreate: true })}
                  >
                    <Image src={editIcon} />
                  </Button>
                }
                size="mini"
                basic={true}
                content="Edit"
              />
            </div>
            <div style={{ fontSize: '13px' }}>{item.type}</div>
            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{item.school}</div>
            <div style={{ fontSize: '13px' }}>
              Degree Attained: {item.degreeAttained ? 'Yes' : 'No'}
            </div>
          </div>
        ) : (
          <div className="create-education-container">
            <Form
              ref={ref => (this.form = ref)}
              id="create-education-form"
              onValidSubmit={(model: any) => {
                this.props.edit(id, model);
                this.setState({ showCreate: false });
              }}
            >
              <div className="input-field-container">
                <div className="input-field-label">School Type</div>
                <Form.Dropdown
                  name="type"
                  search={true}
                  placeholder="Please Select"
                  options={this.state.schoolOptions}
                  required={true}
                  value={item.type}
                  validationErrors={{
                    isDefaultRequiredValue: `* Type is required field.`,
                  }}
                  className="form-input-short form-input-dropdown"
                  errorLabel={errorLabel}
                />
              </div>

              <div className="input-field-container">
                <div className="input-field-label">School</div>
                <Form.Input
                  name="school"
                  placeholder="Please Enter..."
                  required={true}
                  value={item.school}
                  validationErrors={{
                    isDefaultRequiredValue: `* School is required field.`,
                  }}
                  className="form-input"
                  errorLabel={errorLabel}
                />
              </div>
              <Form.Checkbox
                name="degreeAttained"
                label="Degree Attained"
                onChange={() => this.setState({ degree: !this.state.degree })}
                value={this.state.degree}
              />
              <div className="education-button">
                <Button type="button" onClick={() => this.setState({ showCreate: false })}>
                  CANCEL
                </Button>
                <Button
                  type="button"
                  onClick={e => {
                    this.form.formsyForm.submit();
                  }}
                  form="create-education-form"
                >
                  SAVE
                </Button>
              </div>
            </Form>
          </div>
        )}
      </>
    );
  }
}

export default EducationItem;
