const REACT_APP_API_DOMAIN = window.ENV
  ? window.ENV.REACT_APP_API_DOMAIN
  : process.env.REACT_APP_API_DOMAIN;

const devMode = window.ENV
  ? window.ENV.REACT_APP_DEV_MODE
  : process.env.REACT_APP_DEV_MODE;

export default {
  endpoint: `${devMode === 'ON' ? 'http' : 'https'}://${REACT_APP_API_DOMAIN}/api/v1/`,
};
