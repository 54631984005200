import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import services from '../../services';
import MyTypes from 'MyTypes';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { IUser } from '../../models';
import { TrainingCenterIcons } from './trainingCenterIcons';
import { trainingCenterActions } from '../../features/training-center';
import { Folder, PaginationInfo } from '../../features/training-center/reducer';

const options = [
  { key: 'add', icon: 'add', text: 'Add Sub Folder', value: 'add' },
  { key: 'eit', icon: 'edit', text: 'Edit Folder', value: 'edit' },
  { key: 'delete', icon: 'delete', text: 'Remove Folder', value: 'delete' },
  { key: 'assign', icon: 'user', text: 'Assign Folder', value: 'assign' },
];

const optionsSub = [
  { key: 'add', icon: 'add', text: 'Add Sub Folder', value: 'add' },
  { key: 'eit', icon: 'edit', text: 'Edit Sub Folder', value: 'edit' },
  { key: 'delete', icon: 'delete', text: 'Remove Sub Folder', value: 'delete' },
  { key: 'assign', icon: 'user', text: 'Assign Sub Folder', value: 'assign' },
];

interface IProps {
  init?: boolean;
  subFolders: Folder[];
  user: IUser;
  folderList: Folder[];
  folderId: number;
  pagingInfo: PaginationInfo;
  searchMaterial: typeof trainingCenterActions.searchMaterial;
  getFolderList: typeof trainingCenterActions.getFolderList;
  setFolderToggle: typeof trainingCenterActions.setFolderToggle;
  setFolderActive: typeof trainingCenterActions.setFolderActive;
  removeFolderActive: typeof trainingCenterActions.removeFolderActive;
  setFolderEnter: typeof trainingCenterActions.setFolderEnter;
  setFolderLeave: typeof trainingCenterActions.setFolderLeave;
}

interface IState {
  open: any;
  folderList: Folder[];
  openCreateModal: any;
  openEditModal: any;
  openDeleteModal: any;
  children: Folder[];
  childrenCount: number;
  loading: boolean;
  activeFolder: number | null;
}

class FolderListTreeRecursive extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      open: {},
      openCreateModal: {},
      openEditModal: {},
      openDeleteModal: {},
      children: [],
      childrenCount: 0,
      loading: false,
      activeFolder: null,
    };

    this.toggleOpen = this.toggleOpen.bind(this);
  }

  setActiveFolder(id: number) {
    this.setState({ activeFolder: id });
  }

  showAll() {
    this.setState({ open: {} });

    const { pagingInfo } = this.props;
    console.log({ pagingInfo });
    this.props.searchMaterial({
      ...pagingInfo,
      activePage: 1,
      resetSelect: true,
      filter: { noPagination: true },
    });
  }

  toggleOpen(folder: Folder) {
    const isRoot = !this.props.subFolders;
    const value = this.state.open[folder.id];

    const { pagingInfo } = this.props;
    const filter = {
      ...pagingInfo.filter,
      folderIds: folder.id,
    };

    const folderData = { id: folder.id, subFolders: folder.subFolders };

    const folderIds = this.getChildrenIds(folderData);

    // get files
    this.props.searchMaterial({
      ...pagingInfo,
      filter: { ...filter, folderIds: folderIds.join(',') },
      activePage: 1,
      resetSelect: true,
    });

    // set active root
    if (isRoot) this.setActiveFolder(folder.id);
    if (!isRoot) this.props.setRootActive();

    if (folder.subFolders.length === 0) return;
    // toggle open folder
    this.setState(prev => ({
      open: {
        ...prev.open,
        [folder.id]: !!!value,
      },
    }));

    // set active root
    if (!this.props.subFolders && folder.id) this.setActiveFolder(folder.id);
  }

  getChildrenIds(o: any): number[] {
    const ids: number[] = [];

    const recursion = (o: any) => {
      if (o.subFolders.length === 0) {
        ids.push(o.id);
      } else {
        ids.push(o.id);

        o.subFolders.forEach(recursion);
      }
    };

    recursion(o);

    return ids;
  }

  componentDidMount(): void {
    if (this.props.init) this.props.getFolderList(this.props.user.id);
  }

  public getClassForFolder(folder: Folder) {
    if (
      folder.subFolders.length > 0 &&
      (this.state.open[folder.id] || folder.childActive || folder.childEnter)
    ) {
      return `folder-item open-with-child ${
        folder.childActive || (folder.childEnter && !folder.active) ? 'child-active' : ''
      }`;
    }

    return 'folder-item';
  }

  public renderOpenFolderIcon(folder: Folder, isActive: boolean) {
    const { folderId, folderList, subFolders, init, pagingInfo } = this.props;
    const { open } = this.state;

    const folderIds = pagingInfo.filter.folderIds ? pagingInfo.filter.folderIds.split(',') : null;
    const focusedFolder = folderIds && parseInt(folderIds[0]);
    const isRoot = !this.props.subFolders;

    if (folder.subFolders.length === 0) {
      return null;
    }

    const FolderIcon = this.state.open[folder.id]
      ? TrainingCenterIcons.CollapseFolder
      : TrainingCenterIcons.ExpandFolder;

    if (open[folder.id] && isRoot) return <FolderIcon className="open-collapse-folder-icon" />;
    if (focusedFolder === folder.id) return <FolderIcon className="open-collapse-folder-icon" />;

    return <FolderIcon className="open-collapse-folder-icon" style={{ fill: 'black' }} />;
  }

  public renderRootFolderIcon() {
    const FolderIcon = this.state.open
      ? TrainingCenterIcons.CollapseFolder
      : TrainingCenterIcons.ExpandFolder;

    return <FolderIcon className="open-collapse-folder-icon" />;
  }

  setRootActive(folderId: number) {
    this.setState({ activeFolder: folderId });
  }

  renderList() {
    const { open, activeFolder } = this.state;
    const { folderId, folderList, subFolders, pagingInfo } = this.props;

    const data = folderId ? subFolders : folderList;

    const folderIds = pagingInfo.filter.folderIds ? pagingInfo.filter.folderIds.split(',') : null;
    const focusedFolder = folderIds && parseInt(folderIds[0]);

    return (
      <ul style={{ paddingInlineStart: 2, paddingTop: !folderId ? '1rem' : undefined }}>
        {!folderId && (
          <div className="all" onClick={() => this.setRootFolderToggle()}>
            {this.renderRootFolderIcon()} All Files
          </div>
        )}

        {this.state.open &&
          data.map(({ id, name, ...item }: any, i: number) => (
            <div className="list">
              <div
                className={`root-folder ${
                  (open[id] || item.subFolders.length === 0) && activeFolder === id ? ' active' : ''
                }`}
              >
                <div
                  id={id}
                  className={`folder-item ${
                    focusedFolder === id ? 'open-with-child active' : ''
                  }  ${
                    item.subFolders.length > 0 &&
                    activeFolder === id &&
                    (open[id] || item.subFolders.length === 0)
                      ? ' open-with-child active'
                      : ''
                  }`}
                  onClick={() => this.toggleOpen({ id, name, ...item })}
                >
                  <span onClick={() => this.toggleOpen({ id, name, ...item })}>
                    {this.renderOpenFolderIcon({ id, name, ...item })}
                    <span>{name}</span>
                  </span>
                </div>
                {open[id] && (
                  <FolderListTreeRecursive
                    {...this.props}
                    folderId={id}
                    subFolders={item.subFolders}
                    rootId={subFolders ? this.props.rootId : id}
                    isRootActive={subFolders ? this.props.isRootActive : activeFolder}
                    setRootActive={
                      subFolders ? this.props.setRootActive : () => this.setRootActive(id)
                    }
                  />
                )}
              </div>
            </div>
          ))}
      </ul>
    );
  }

  public setRootFolderToggle() {
    this.setState({ open: !this.state.open });
    const { pagingInfo } = this.props;
    this.props.removeFolderActive();
    delete pagingInfo.filter.folderIds;

    this.props.searchMaterial({ ...pagingInfo, activePage: 1, resetSelect: true });
  }

  render() {
    const { folderId, folderList } = this.props;
    return (
      <div className="folder-list-container" style={!folderId ? { padding: 10 } : undefined}>
        <div className="list">
          <div style={{ visibility: folderList.length === 0 ? 'hidden' : 'visible' }}>
            {this.props.init ? (
              <div>{this.renderList()}</div>
            ) : (
              <div className="subfolder">{this.renderList()}</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
  pagingInfo: state.trainingCenter.material.pagingInfo,
  folderList: state.trainingCenter.folderList,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      searchMaterial: trainingCenterActions.searchMaterial,
      getFolderList: trainingCenterActions.getFolderList,
      setFolderToggle: trainingCenterActions.setFolderToggle,
      setFolderActive: trainingCenterActions.setFolderActive,
      removeFolderActive: trainingCenterActions.removeFolderActive,
      setFolderEnter: trainingCenterActions.setFolderEnter,
      setFolderLeave: trainingCenterActions.setFolderLeave,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FolderListTreeRecursive);
