import { action } from 'typesafe-actions';
import * as constants from './constants';
import { Folder, MaterialModel, PaginationInfo } from './reducer';

export const getFilesList = (filterOptions?: object) =>
  action(constants.GET_FILES_LIST, { filterOptions });

export const getFolderList = (id: number) =>
  action(constants.GET_FOLDER_LIST, {
    id,
  });

export const setFolderList = (list: object[]) => action(constants.SET_FOLDER_LIST, { list });

export const setFolderToggle = (id: number) => action(constants.SET_FOLDER_TOGGLE, { id });

export const setFolderActive = (folder: Folder) => action(constants.SET_FOLDER_ACTIVE, { folder });

export const removeFolderActive = () => action(constants.REMOVE_FOLDER_ACTIVE, {});

export const setFolderEnter = (folder: Folder) => action(constants.SET_FOLDER_ENTER, { folder });

export const setFolderLeave = (folder: Folder) => action(constants.SET_FOLDER_LEAVE, { folder });

export const searchMaterial = (pagingInfo: PaginationInfo) => {
  return action(constants.SEARCH_MATERIAL, { pagingInfo });
};

export const downloadMaterial = (id: number) => {
  return action(constants.MATERIAL_DOWNLOAD, { id });
};

export const selectMaterial = (id: number) => {
  return action(constants.MATERIAL_SELECT, { id });
};

export const selectAllMaterial = () => {
  return action(constants.MATERIAL_SELECT_ALL, {});
};

export const bulkDownloadMaterial = (ids: Array<number>) => {
  return action(constants.MATERIAL_BULK_DOWNLOAD, { ids });
};

export const setMaterialSearchResult = (data: Array<any> = [], total: number) => {
  return action(constants.MATERIAL_SET_SEARCH_RESULT, { data, total });
};

export const setMaterialAllSearchResult = (data: Array<MaterialModel> = []) => {
  return action(constants.MATERIAL_SET_ALL_SEARCH_RESULT, { data: data });
};

export const setSelectedMaterial = (data: Array<number> | number = []) => {
  if (typeof data === 'number') {
    data = [data];
  }
  return action(constants.SET_SELECTED_MATERIALS, { data });
};

export const removeSelectedMaterials = (data: Array<number> | number = []) => {
  if (typeof data === 'number') {
    data = [data];
  }
  return action(constants.REMOVE_SELECTED_MATERIALS, { data });
};

export const setViewTypeList = () => {
  return action(constants.SET_VIEW_TYPE_LIST, {});
};

export const setViewTypeGrid = () => {
  return action(constants.SET_VIEW_TYPE_GRID, {});
};
