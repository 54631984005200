import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import services from '../../services';
import * as actions from './actions';
import * as constants from './constants';

export default function*() {
  yield takeEvery(constants.GET_STANDBY_LIST, getStandbyList);
  yield takeEvery(constants.GET_SELECTED_ADJUSTERS, getSelectedAdjusters);
  yield takeEvery(constants.SET_ACTIVE_STANDBY_RELOADED, setActiveStandbyReloaded);
}

export function* getStandbyList(action: ActionType<typeof actions.getStandbyList>) {
  const { standbySort, standbySearch, activeListPage, activeTab } = yield select(
    state => state.standby.standby
  );
  const { user } = yield select(state => state.account.account);
  const sortArray = standbySort.split(',');
  const sortObject = Object.assign({}, { [sortArray[0]]: sortArray[1] });
  if (activeTab === 'All Active') {
    const requestList = yield call(
      services.api.standby.getStandbyListAllActive,
      { page: activeListPage - 1, id: user.id },
      standbySort,
      standbySearch
    );
    if (requestList.isSuccess) {
      yield put(actions.submitStandbyList(requestList.data.list, requestList.data.count));
    } else {
      console.log(requestList);
      toast.dismiss();
      toast.error(
        requestList.data ? requestList.data.errors[0].msg : `Error ${requestList.status}`
      );
    }
  } else {
    const requestList = yield call(
      services.api.standby.getStandbyList,
      { page: activeListPage - 1, id: user.id },
      standbySort,
      activeTab === 'Completed' ? true : false,
      activeTab.toLowerCase(),
      standbySearch
    );
    if (requestList.isSuccess) {
      yield put(actions.submitStandbyList(requestList.data.list, requestList.data.count));
    } else {
      console.log(requestList);
      toast.dismiss();
      toast.error(
        requestList.data ? requestList.data.errors[0].msg : `Error ${requestList.status}`
      );
    }
  }
}

export function* getSelectedAdjusters(action: ActionType<typeof actions.getSelectedAdjusters>) {
  const { adjustersList } = yield select(state => state.adjusters.adjusters);
  const { adminList } = yield select(state => state.admin.admins);

  const concItems = adjustersList.concat(adminList);
  console.log(concItems);
}

export function* setActiveStandbyReloaded(
  action: ActionType<typeof actions.setActiveStandbyReloaded>
) {
  const { standbySort, activeListPage } = yield select(state => state.standby.standby);
  const { user } = yield select(state => state.account.account);
  const sortArray = standbySort.split(',');
  const sortObject = Object.assign({}, { [sortArray[0]]: sortArray[1] });
  const requestList = yield call(
    services.api.standby.getStandbyList,
    { page: activeListPage - 1, id: user.id },
    sortObject,
    false,
    undefined
  );
  const list = requestList.data.list.find((el: any) => el.id === action.payload.id);
  yield put(actions.submitActiveStandbyReloaded(list));
}
