import moment from 'moment';
import React from 'react';
import { Button, Image, Popup } from 'semantic-ui-react';
import downloadIcon from '../../assets/images/Blue/download.svg';
import services from '../../services';
import { sizeToHumanReadable } from '../../utils/helper';
import { FilePreview } from '../file-preview/FilePreview';

interface Props {
  item: object;
  download: (file: any) => void;
}

interface State {
  showConfirm: boolean;
  image: any;
}

class LicenseItem extends React.Component<Props, State> {
  public state = {
    showConfirm: false,
    image: '',
  };

  public componentDidMount = async () => {
    const { item } = this.props;
    const resp = await services.api.account.downloadFile(item.files[0].key);
    if (resp.isSuccess) {
      this.setState({ image: resp.data.downloadURL });
    }
  };

  public render() {
    const { item } = this.props;
    const { image } = this.state;

    return (
      <div className="license-item-container">
        <div className="licenses-file-preview">
          <FilePreview type={item.files[0].type} src={image} />
        </div>
        <div className="license-item-info">
          <div
            style={{ fontSize: '16px', fontWeight: 'bold' }}
            dangerouslySetInnerHTML={{ __html: item.type }}
          />
          <div className="license-date-container">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ fontSize: '13px', marginRight: '10px' }}>Uploaded Date</div>
              <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                {moment(item.createdAt).format('MM/DD/YYYY')}
              </div>
            </div>
            {item.runDate && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ fontSize: '13px' }}>Run Date</div>
                <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                  {moment(item.runDate).format('MM/DD/YYYY')}
                </div>
              </div>
            )}
          </div>
          <div style={{ fontSize: '13px' }}>{sizeToHumanReadable(item.files[0].size)}</div>
        </div>
        <div className="buttons-container">
          <Popup
            trigger={
              <Button
                className="iconic-button-custom"
                onClick={() =>
                  this.props.download({ id: this.props.item.id, name: this.props.item.type })
                }
              >
                <Image src={downloadIcon} />
              </Button>
            }
            size="mini"
            basic={true}
            content="Download"
          />
        </div>
      </div>
    );
  }
}

export default LicenseItem;
