import { fork, put } from 'redux-saga/effects';
import accountSaga from '../features/account/saga';
import modalSaga from '../features/modal/saga';
import profileSaga from '../features/profile/saga';
import standbySaga from '../features/standby/saga';
import trainingCenterSaga from '../features/training-center/trainingCenterSaga';
import materialSaga from '../features/training-center/material.saga';

export default function* () {
  const sagas = [
    modalSaga,
    accountSaga,
    profileSaga,
    standbySaga,
    trainingCenterSaga,
    materialSaga,
  ];

  for (const saga of sagas) {
    try {
      yield put({ type: 'SAGA_RUN' });
      yield fork(saga);
    } catch (e) {
      yield put({ type: 'SAGA_FAILED', e });
    }
  }
}
