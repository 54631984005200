import React from 'react';
import { Button, Image, Popup } from 'semantic-ui-react';


const CustomIconicButton = (props: any) => {
  const { onPress, popup, image, style, disabled } = props;

  const items = (
    <Button
      type="button"
      className="iconic-button-custom"
      onClick={onPress}
      style={style}
      disabled={disabled}
    >
      <Image src={image} />
    </Button>
  );

  return <Popup trigger={items} basic={true} size="mini" content={popup} />;
};

export default CustomIconicButton;
