import moment from 'moment';
import React from 'react';
import { Button, Image, Responsive } from 'semantic-ui-react';
import arrowIcon from '../../assets/images/ui-dropdown.svg';
import '../../assets/styles/StandByListsAdmin.css';
import services from '../../services';

interface Props {
  item: object;
}

interface State {
  showFullDescription: boolean;
}

class SBDetailsHeader extends React.Component<Props, State> {
  public state = {
    showFullDescription: true,
  };

  public render() {
    const { item } = this.props;
    const { showFullDescription } = this.state;
    return (
      <>
        {!item ? (
          services.router.goto('/stand-by-lists')
        ) : (
          <>
            <Responsive minWidth={769}>
              <div className="details-header-container">
                <div className="details-header-title">{item.title}</div>
                <div className="details-header-subtitle">
                  <div>{item.location ? item.location : ''}</div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      className="sbl-item-title"
                      style={{ textAlign: 'center', marginRight: '3rem', alignSelf: 'flex-start' }}
                    >
                      <div style={{ fontWeight: 700, fontSize: '16px' }}>
                        {item.adjusterData && item.adjusterData.status === 'applied'
                          ? moment(item.adjusterData.appliedAt).format('MM/DD/YYYY, hh:mm A ')
                          : 'No Application'}
                      </div>
                      <div style={{ fontSize: '13px', textAlign: 'right' }}>
                        {item.adjusterData && item.adjusterData.status === 'applied'
                          ? 'Applied'
                          : ' '}{' '}
                      </div>
                    </div>
                    <div className="sbl-item-dates-section">
                      {item.closedAt ? (
                        <div>Closed On: {moment(item.closedAt).format('MM/DD/YYYY, hh:mm A ')}</div>
                      ) : (
                        <div>Expires On: {moment(item.expiresAt).format('MM/DD/YYYY')}</div>
                      )}
                      <div>Created On: {moment(item.createdAt).format('MM/DD/YYYY, hh:mm A ')}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  showFullDescription
                    ? 'standby-description-details active'
                    : 'standby-description-details'
                }
              >
                <div
                  className="standby-description-toggle"
                  onClick={() => this.setState({ showFullDescription: !showFullDescription })}
                >
                  Description
                  <Image
                    src={arrowIcon}
                    className={
                      showFullDescription
                        ? 'standby-description-details-arrow active'
                        : 'standby-description-details-arrow'
                    }
                  />
                </div>
                <div>{item.description}</div>
              </div>
            </Responsive>

            <Responsive maxWidth={768}>
              <div className="details-header-container">
                <div className="details-header-title">{item.title}</div>
                <div className="details-header-subtitle">
                  <div
                    style={{ minWidth: 'max-content', marginBottom: '1rem', marginRight: '1rem' }}
                  >
                    {item.location ? item.location : ''}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div className="sbl-item-dates-section">
                      {item.closedAt ? (
                        <div>Closed On: {moment(item.closedAt).format('MM/DD/YYYY, hh:mm A ')}</div>
                      ) : (
                        <div>
                          Expires On: {moment(item.expiresAt).format('MM/DD/YYYY, hh:mm A ')}
                        </div>
                      )}
                      <div>Created On: {moment(item.createdAt).format('MM/DD/YYYY, hh:mm A ')}</div>
                    </div>
                    <div
                      className="sbl-item-title"
                      style={{ textAlign: 'left', alignSelf: 'flex-start' }}
                    >
                      <div style={{ fontWeight: 700, fontSize: '16px' }}>
                        {item.adjusterData && item.adjusterData.status === 'applied'
                          ? moment(item.adjusterData.appliedAt).format('MM/DD/YYYY, hh:mm A ')
                          : 'No Application'}
                      </div>
                      <div style={{ fontSize: '13px', textAlign: 'left' }}>
                        {item.adjusterData && item.adjusterData.status === 'applied'
                          ? 'Applied'
                          : ' '}{' '}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  showFullDescription
                    ? 'standby-description-details active'
                    : 'standby-description-details'
                }
              >
                <div
                  className="standby-description-toggle"
                  onClick={() => this.setState({ showFullDescription: !showFullDescription })}
                >
                  Description
                  <Image
                    src={arrowIcon}
                    className={
                      showFullDescription
                        ? 'standby-description-details-arrow active'
                        : 'standby-description-details-arrow'
                    }
                  />
                </div>
                <div>{item.description}</div>
              </div>
            </Responsive>
          </>
        )}
      </>
    );
  }
}

export default SBDetailsHeader;
