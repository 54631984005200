export const GET_FILES_LIST = 'trainingCenter/GET_FILES_LIST';

export const GET_FOLDER_LIST = 'trainingCenter/GET_FOLDER_LIST';
export const SET_FOLDER_LIST = 'trainingCenter/SET_FOLDER_LIST';
export const SET_FOLDER_TOGGLE = 'trainingCenter/SET_FOLDER_TOGGLE';
export const SET_FOLDER_ACTIVE = 'trainingCenter/SET_FOLDER_ACTIVE';
export const REMOVE_FOLDER_ACTIVE = 'trainingCenter/REMOVE_FOLDER_ACTIVE';
export const SET_FOLDER_ENTER = 'trainingCenter/SET_FOLDER_ENTER';
export const SET_FOLDER_LEAVE = 'trainingCenter/SET_FOLDER_LEAVE';

// START MATERIAL REGION

export const SET_VIEW_TYPE_LIST = 'trainingCenter/SET_VIEW_TYPE_LIST';
export const SET_VIEW_TYPE_GRID = 'trainingCenter/SET_VIEW_TYPE_GRID';
export const OPEN_MATERIAL_FILTER = 'trainingCenter/OPEN_MATERIAL_FILTER';
export const CLOSE_MATERIAL_FILTER = 'trainingCenter/CLOSE_MATERIAL_FILTER';

export const SEARCH_MATERIAL = 'trainingCenter/SEARCH_MATERIAL';

export const MATERIAL_DOWNLOAD = 'trainingCenter/MATERIAL_DOWNLOAD';

export const MATERIAL_SELECT = 'trainingCenter/MATERIAL_SELECT';
export const MATERIAL_SELECT_ALL = 'trainingCenter/MATERIAL_SELECT_ALL';

export const MATERIAL_BULK_DOWNLOAD = 'trainingCenter/MATERIAL_BULK_DOWNLOAD';

export const MATERIAL_SET_SEARCH_RESULT = 'trainingCenter/MATERIAL_SET_SEARCH_RESULT';
export const MATERIAL_SET_ALL_SEARCH_RESULT = 'trainingCenter/MATERIAL_SET_ALL_SEARCH_RESULT';

export const SET_SELECTED_MATERIALS = 'trainingCenter/SET_SELECTED_MATERIALS';
export const REMOVE_SELECTED_MATERIALS = 'trainingCenter/REMOVE_SELECTED_MATERIALS';

// END MATERIAL REGION
