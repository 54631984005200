import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Grid, Image } from 'semantic-ui-react';
import compassLogo from '../../assets/images/compass-logo-vector.svg';
import { accountActions } from '../../features/account';
import services from '../../services';

interface Props {
  isAuthenticated: boolean;
  checked: boolean;
  checkAuthentication: typeof accountActions.checkAuthentication;
}

interface State {}

class ThankYouPage extends React.Component<Props, State> {
  public componentDidUpdate = (prevProps: Props) => {
    if (prevProps.isAuthenticated !== this.props.isAuthenticated) {
      if (this.props.isAuthenticated) {
        services.router.goto(services.router.url.homepage);
      }
    }
  };

  public handleButton = () => {
    services.localStorage.save('accessToken', services.localStorage.get('accessTokenRegistration'));
    this.props.checkAuthentication();
    if (this.props.isAuthenticated) {
      services.router.goto(services.router.url.homepage);
    }
  };

  public render() {
    return (
      <div className="thanks-page">
        <div className="logo-container">
          <Image src={compassLogo} />
          <h3>
            Thank you for applying with Compass Adjusting Services!
            <br />
            If the account was not yet activated, please activate it by following the instructions
            sent via email.
            <br />
            We appreciate your interest in working with us.
          </h3>
        </div>
        <Grid centered={true} style={{ height: '100%' }} verticalAlign="middle">
          <Grid.Column style={{ maxWidth: 650 }}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20px',
              }}
            >
              <Button
                primary={true}
                style={{ width: '250px', margin: '0 auto', height: '32px', marginTop: '42px' }}
                onClick={this.handleButton}
              >
                View Profile
              </Button>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  isAuthenticated: state.account.account.isAuthenticated,
  checked: state.account.account.isAuthenticationChecked,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      checkAuthentication: accountActions.checkAuthentication,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThankYouPage);
