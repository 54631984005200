import moment from 'moment';
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Icon, Image, Menu, Responsive } from 'semantic-ui-react';
import trainingCenterIcon from '../../assets/images/SideIcons/icn-training-center.svg';
import adjustersIcon from '../../assets/images/SideIcons/icn-adjusters.svg';
import financeIcon from '../../assets/images/SideIcons/icn-finances.svg';
import stbyIcon from '../../assets/images/SideIcons/icn-stby-lists.svg';
import '../../assets/styles/Sidebar.css';
import config from '../../config';
import { profileActions } from '../../features/profile';
import services from '../../services';
import { IUser } from '../../models';

interface Props {
  pathname: string;
  user: IUser;
  setActiveTab: typeof profileActions.setActiveTab;
  activeTab: string;
}

interface State {
  activeItem: string;
  showFullSidebar: boolean;
}

const profileTabs = [
  'Profile',
  'Professional Skills',
  'Licenses',
  'Certifications',
  'Resumes',
  'Paperwork',
];

const chooseIcon = {
  Profile: adjustersIcon,
  'Standby Lists': stbyIcon,
  Finances: stbyIcon,
  'Training Center': trainingCenterIcon,
};

class Sidebar extends React.Component<Props, State> {
  public state: State = {
    activeItem: 'Profile',
    showFullSidebar: false,
  };

  public chooseActive = (location: string) => {
    console.log(location);
    if (location.includes('/stand-by-lists/')) {
      return 'Standby Lists';
    } else if (location.includes('/training-center')) {
      return 'Training Center';
    } else if (location === '/' && this.props.activeTab === 'Finances') {
      return 'Finances';
    } else {
      switch (location) {
        case '/':
          return 'Profile';
        default:
          return '';
      }
    }
  };

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.activeTab !== this.props.activeTab) {
      this.handleItemClick({}, { name: this.props.activeTab });
      // this.setState({ activeItem: this.props.activeTab });
    }
  }

  private setPageHeaderText = (name: string) => {
    //defer so that it's the last to execute
    setTimeout(() => {
      (document.getElementById('account-header-title') as HTMLDivElement).innerText = name;
    }, 0);
  };

  public handleItemClick = (e: any, { name }) => {
    this.setState({ activeItem: profileTabs.includes(name) ? 'Profile' : name });
    switch (name) {
      case 'Profile':
        this.props.setActiveTab('Profile');
        services.router.goto('/');
        this.setPageHeaderText('Adjuster Details');
        break;
      case 'Finances':
        this.props.setActiveTab('Finances');
        services.router.goto('/');
        this.setPageHeaderText('Finances');
        break;
      case 'Standby Lists':
        this.props.setActiveTab('Standby Lists');
        services.router.goto(services.router.url.standby);
        break;
      case 'Training Center':
        this.props.setActiveTab('Training Center');
        services.router.goto(services.router.url.training_center);
        break;
      default:
        return;
    }
  };

  public render() {
    const { activeItem, showFullSidebar } = this.state;
    const { activeTab, pathname } = this.props;
    const activeList = pathname.includes('/stand-by-lists') || pathname.includes('/training-center');
    return (
      <>
        <Responsive minWidth={769} style={{ height: '100%' }}>
          <div className="sidebar-menu-container">
            <Menu vertical={true} className="sidebar-menu">
              <Menu.Item
                name="Profile"
                active={profileTabs.includes(activeTab) && !activeList}
                onClick={this.handleItemClick.bind(this)}
              >
                <Image className="icon side-icon" src={adjustersIcon} />
                Profile
              </Menu.Item>
              <Menu.Item
                name="Finances"
                active={activeTab === 'Finances' && !activeList}
                onClick={this.handleItemClick.bind(this)}
              >
                <Image className="icon side-icon" src={financeIcon} />
                Finances
              </Menu.Item>
              <Menu.Item
                name="Standby Lists"
                active={this.props.pathname.includes('/stand-by-lists')}
                onClick={this.handleItemClick.bind(this)}
              >
                <Image className="icon side-icon" src={stbyIcon} />
                Standby Lists
              </Menu.Item>
              <Menu.Item
                name="Training Center"
                active={this.props.pathname.includes('/training-center')}
                onClick={this.handleItemClick.bind(this)}
              >
                <Image className="icon side-icon" src={trainingCenterIcon} />
                Training Center
              </Menu.Item>
            </Menu>
            <div>
              <p>Version: {config.common.version}</p>
              <p>Copyright © {moment().format('YYYY')}</p>
              <p>Compass Adjusting Services</p>
            </div>
          </div>
        </Responsive>

        <Responsive maxWidth={768} style={{ width: '100%' }}>
          <div
            className={`sidebar-menu-container ${showFullSidebar ? '' : 'short'}`}
            onBlur={e => this.setState({ showFullSidebar: false })}
            tabIndex={0}
          >
            <div style={{ display: showFullSidebar ? 'block' : 'none' }}>
              <Menu vertical={true} className="sidebar-menu">
                <Menu.Item
                  name="Profile"
                  active={profileTabs.includes(activeTab)}
                  onClick={this.handleItemClick.bind(this)}
                >
                  <Image className="icon side-icon" src={adjustersIcon} />
                  Profile
                </Menu.Item>
                <Menu.Item
                  name="Finances"
                  active={activeTab === 'Finances'}
                  onClick={this.handleItemClick.bind(this)}
                >
                  <Image className="icon side-icon" src={financeIcon} />
                  Finances
                </Menu.Item>
                <Menu.Item
                  name="Standby Lists"
                  active={activeItem === 'Standby Lists'}
                  onClick={this.handleItemClick.bind(this)}
                >
                  <Image className="icon side-icon" src={stbyIcon} />
                  Standby Lists
                </Menu.Item>
                <Menu.Item
                  name="Training Center"
                  active={activeItem === 'Training Center'}
                  onClick={this.handleItemClick.bind(this)}
                >
                  <Image className="icon side-icon" src={trainingCenterIcon} />
                  Training Center
                </Menu.Item>
              </Menu>
              <div>
                <p>Version: {config.common.version}</p>
                <p>Copyright © {moment().format('YYYY')}</p>
                <p>Compass Adjusting Services</p>
              </div>
            </div>

            <div style={{ display: showFullSidebar ? 'none' : 'block' }}>
              <Menu vertical={true} className="sidebar-menu">
                <Menu.Item
                  name={activeItem}
                  active={true}
                  onClick={() => this.setState({ showFullSidebar: true })}
                >
                  <Image className="icon side-icon" src={chooseIcon[activeItem]} />
                  {activeItem}
                  <Icon name="th" className="side-icon collapse-icon" />
                </Menu.Item>
              </Menu>
            </div>
          </div>
        </Responsive>
      </>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  pathname: state.router.location.pathname,
  activeTab: state.profile.profile.activeTab,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setActiveTab: profileActions.setActiveTab,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar);
