import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import config from '../../config';
import { request } from './base';

export class ApiResponse {
  public status: number;
  public data: any;

  public isSuccess: boolean;
  public isError: boolean;

  constructor(status: number, data: any) {
    this.status = status;
    this.data = data;

    this.isSuccess = status >= 200 && status <= 299;
    this.isError = !this.isSuccess;
  }

  public get(x: string) {
    return this.data[x];
  }

  public getErrorList() {
    return this.data.errors.map((errorData: any) => {
      return errorData.msg;
    });
  }

  public getError() {
    return this.getErrorList().join(' ');
  }

  public isServerError() {
    return this.status >= 500 && this.status < 600;
  }
}

export const postFile = (url: string, formData: FormData | {}) => {
  return axios({
    method: 'post',
    url,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
    responseType: 'json',
  })
    .then(response => {
      const { status, data } = response;

      return new ApiResponse(status, data);
    })
    .catch(error => {
      if (error.response) {
        const { status, data } = error.response;

        return new ApiResponse(status, data);
      }
      return new ApiResponse(400, {});
    });
};

export const postRegisterFiles = (postData: object, token?: string) => {
  return axios({
    method: 'post',
    url: `${config.api.endpoint}file`,
    headers: {
      Authorization: `JWT ${token}`,
    },
    data: {
      ...postData,
    },
    responseType: 'json',
  })
    .then(response => {
      const { status, data } = response;

      return new ApiResponse(status, data);
    })
    .catch(error => {
      if (error.response) {
        const { status, data } = error.response;

        return new ApiResponse(status, data);
      }
      return new ApiResponse(400, {});
    });
};

export const addLicense = (postData: any, token: string) => {
  return axios({
    method: 'post',
    url: `${config.api.endpoint}license`,
    headers: {
      Authorization: `JWT ${token}`,
    },
    data: {
      ...postData,
    },
    responseType: 'json',
  })
    .then(response => {
      const { status, data } = response;

      return new ApiResponse(status, data);
    })
    .catch(error => {
      if (error.response) {
        const { status, data } = error.response;

        return new ApiResponse(status, data);
      }
      return new ApiResponse(400, {});
    });
};

export const addCertification = (postData: any, token: string) => {
  return axios({
    method: 'post',
    url: `${config.api.endpoint}certification`,
    headers: {
      Authorization: `JWT ${token}`,
    },
    data: {
      ...postData,
    },
    responseType: 'json',
  })
    .then(response => {
      const { status, data } = response;

      return new ApiResponse(status, data);
    })
    .catch(error => {
      if (error.response) {
        const { status, data } = error.response;

        return new ApiResponse(status, data);
      }
      return new ApiResponse(400, {});
    });
};

export const addResume = (postData: any, token: string) => {
  return axios({
    method: 'post',
    url: `${config.api.endpoint}resume`,
    headers: {
      Authorization: `JWT ${token}`,
    },
    data: {
      ...postData,
    },
    responseType: 'json',
  })
    .then(response => {
      const { status, data } = response;

      return new ApiResponse(status, data);
    })
    .catch(error => {
      if (error.response) {
        const { status, data } = error.response;

        return new ApiResponse(status, data);
      }
      return new ApiResponse(400, {});
    });
};

export const addAvatar = (postData: any, token: string) => {
  return axios({
    method: 'post',
    url: `${config.api.endpoint}adjuster/profile`,
    headers: {
      Authorization: `JWT ${token}`,
    },
    data: {
      ...postData,
    },
    responseType: 'json',
  })
    .then(response => {
      const { status, data } = response;

      return new ApiResponse(status, data);
    })
    .catch(error => {
      if (error.response) {
        const { status, data } = error.response;

        return new ApiResponse(status, data);
      }
      return new ApiResponse(400, {});
    });
};

export const uploadAvatar = ({ name, type, size }: any) => {
  return request({
    method: 'post',
    url: '/file/avatar',
    data: {
      name,
      type,
      size,
    },
  });
};

export const uploadRegAvatar = ({ name, type, size }: any, token: string) => {
  return request({
    method: 'post',
    url: '/file/avatar',
    data: {
      name,
      type,
      size,
    },
    headers: { Authorization: `JWT ${token}` },
  });
};

export const getDownloadFileUrl = async (key: string) => {
  const response = await request({
    method: 'post',
    url: `file/download`,
    data: {
      fileObjectKey: key,
    },
  });
  if (response.isSuccess) {
    return response.data.downloadURL;
  }

  return false;
};

export const phoneVerificationRequest = (phone: any, token: string) => {
  return axios({
    method: 'post',
    url: `${config.api.endpoint}adjuster/me/phone-verification-request`,
    headers: {
      Authorization: `JWT ${token}`,
    },
    data: {
      phone,
    },
    responseType: 'json',
  })
    .then(response => {
      const { status, data } = response;

      return new ApiResponse(status, data);
    })
    .catch(error => {
      if (error.response) {
        const { status, data } = error.response;

        return new ApiResponse(status, data);
      }
      return new ApiResponse(400, {});
    });
};

export const phoneVerification = (verificationCode: any, token: string) => {
  return axios({
    method: 'post',
    url: `${config.api.endpoint}adjuster/me/verify-phone`,
    headers: {
      Authorization: `JWT ${token}`,
    },
    data: {
      verificationCode,
    },
    responseType: 'json',
  })
    .then(response => {
      const { status, data } = response;

      return new ApiResponse(status, data);
    })
    .catch(error => {
      if (error.response) {
        const { status, data } = error.response;

        return new ApiResponse(status, data);
      }
      return new ApiResponse(400, {});
    });
};

export const downloadFile = async (url: string, name: string, assignedName?: string) => {
  if(assignedName){
    const extension = name.split('.')
    extension.shift()
    const nameExtension= assignedName+'.'+extension.toString()
    const blob = await axios.get(url, {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      responseType: 'blob',
    });
    const a = document.createElement('a');
    const href = window.URL.createObjectURL(blob.data);
    a.href = href;
    a.download = nameExtension;
    a.click();
  }else {
    const link = document.createElement('a');
    link.download = name;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const downloadLocalFile = (file: any) => {
  const fr = new FileReader();
  fr.readAsDataURL(file);

  const blob = new Blob([file], { type: file.type });

  const objectURL = window.URL.createObjectURL(blob);

  if (navigator.appVersion.toString().indexOf('.NET') > 0) {
    window.navigator.msSaveOrOpenBlob(blob, file.name);
  } else {
    const link = document.createElement('a');
    link.href = objectURL;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
};

const parseResponseAndShowDownload = (response: ApiResponse, defaultFileName = '') => {
  let filename = '';
  const disposition = response.headers['Content-Disposition'];
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  if (!filename) {
    filename = defaultFileName;
  }

  const blob = response.data;

  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const URL = window.URL || window.webkitURL;
    const downloadUrl = URL.createObjectURL(blob);

    if (filename) {
      // use HTML5 a[download] attribute to specify filename
      const a = document.createElement('a');
      // safari doesn't support this yet
      if (typeof a.download === 'undefined') {
        window.location = downloadUrl;
      } else {
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
      }
    } else {
      window.location = downloadUrl;
    }

    setTimeout(() => {
      URL.revokeObjectURL(downloadUrl);
    }, 100);
  }
};

const readBlob = (blob: any) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = () => {
      reader.abort();
      reject();
    };
    reader.readAsText(blob);
  });

export const downloadPaperwork = async (id: any, name: any) => {
  const response = await trackPromise(
    request({
      method: 'get',
      url: `/paperwork/${id}/download`,
      responseType: 'blob',
      headers: {
        Accept: '*/*',
      },
      params: {
        exportType: 'zip',
      },
    }),
    'paperwork-area'
  );

  if (!response.isSuccess) {
    const responseData = await readBlob(response.data);
    try {
      const jsonData = JSON.parse(responseData);
      if (jsonData && jsonData.errors && jsonData.errors.length > 0) {
        return {
          error: jsonData.errors[0].msg,
        };
      }
    } catch (err) {
      console.error('exportFinanceList err: ', err);
    }

    return {
      error: '',
    };
  }

  parseResponseAndShowDownload(response, `Paperwork_${name}.zip`);
  return true;
};
