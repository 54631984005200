import { Form } from 'formsy-semantic-ui-react';
import moment from 'moment';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Confirm, Image, Modal, Popup } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import { uploads } from '../../config';
import * as datesHelper from '../../utils/datesHelper';
import ErrorLabel from '../error-label/ErrorLabel';
import UploadZone from './../file-uploader/UploadZone';

interface Props {
  getResume: (item: object, id?: number) => void;
}

interface State {
  showModal: boolean;
  showConfirm: boolean;
  showFile: boolean;
  file: string | ArrayBuffer | null;
  fileInitial: any;
  fileType: string;
  fileSize: string | number;
  fileSizeInitial: string | number;
  fileError: boolean;
  fileName: string;
  fileErrorText: string;
}

class AddResumeModal extends React.Component<Props, State> {
  public state = {
    showModal: false,
    showConfirm: false,
    showFile: false,
    file: '',
    fileInitial: '',
    fileType: '',
    fileSize: 0,
    fileSizeInitial: '',
    fileError: false,
    fileErrorText: '',
    fileName: '',
  };

  public fileInputRef = React.createRef();

  public handleFileDrop = async (files: any) => {
    const file = files[0];
    if (file === undefined) {
      this.setState({
        fileError: true,
        fileErrorText: '* Only *.pdf, *.rtf, *.doc, *.docx files are supported.',
      });
    } else {
      if (file.size > uploads.maxFileSize) {
        return toast.warn(uploads.maxFileSizeErrorMsg);
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
        this.setState({
          file: reader.result,
          fileInitial: file,
          showFile: true,
          fileType: file.type,
          fileSize: file.size,
          fileError: false,
          fileName: file.name,
        });
      };
    }
  };

  public closeModal = () => {
    this.setState({
      showModal: false,
      showConfirm: false,
      showFile: false,
      file: '',
      fileType: '',
      fileSize: 0,
      fileSizeInitial: '',
      fileInitial: '',
      fileError: false,
      fileErrorText: '',
      fileName: '',
    });
  };

  public render() {
    const errorLabel = <ErrorLabel />;
    const trigger = (
      <div className="add-question">
        <Button type="button" onClick={() => this.setState({ showModal: true })}>
          + ADD
        </Button>
      </div>
    );
    return (
      <Modal
        size="tiny"
        className="licenses-modal"
        trigger={trigger}
        open={this.state.showModal}
        closeIcon={<Image src={closeIcon} className="icon" onClick={() => this.closeModal()} />}
      >
        <Modal.Header>Add Resume</Modal.Header>
        <Form
          onValidSubmit={(model: any) => {
            if (this.state.file === '') {
              this.setState({ fileErrorText: '* Resume file is required.', fileError: true });
              return;
            }

            const obj = Object.assign(
              {},
              model,
              { id: 1000 * Math.random() },
              {
                file: {
                  file: this.state.file,
                  fileInitial: this.state.fileInitial,
                  fileName: this.state.fileName,
                  fileType: this.state.fileType,
                  fileSize: this.state.fileSize,
                },
              },
              {
                createdAt: datesHelper.getCurrentDay(),
              }
            );
            this.props.getResume(obj);
            this.closeModal();
          }}
        >
          <Modal.Content style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
            <div className="input-field-container">
              <div className="input-field-label">File Name</div>
              <Form.Input
                name="name"
                maxLength={100}
                placeholder="Please Enter..."
                instantValidation={false}
                required={true}
                validationErrors={{
                  isDefaultRequiredValue: `* File Name is required field.`,
                }}
                className="form-input"
                errorLabel={errorLabel}
              />
            </div>

            <div className="input-field-container">
              <div className="input-field-label">Resume</div>
              <div />
            </div>

            {!this.state.showFile ? (
              <div>
                <UploadZone onDrop={this.handleFileDrop} accept=".doc, .docx, .pdf, .rtf" />
                {this.state.fileError && (
                  <ErrorLabel>
                    <div style={{ paddingLeft: '1rem' }}>{this.state.fileErrorText}</div>
                  </ErrorLabel>
                )}
              </div>
            ) : (
              <div className="licenses-file-preview">
                {this.state.fileType === 'application/pdf' ? (
                  <div className="pdf-file-container">PDF</div>
                ) : (
                  <div className="doc-file-container">DOC</div>
                )}
                <Popup
                  trigger={
                    <Button
                      type="button"
                      className="iconic-button-custom"
                      onClick={() => this.setState({ showConfirm: true })}
                    >
                      <Image src={closeIcon} />
                    </Button>
                  }
                  size="mini"
                  basic={true}
                  content="Delete"
                />
              </div>
            )}
          </Modal.Content>

          <div className="modal-button-footer">
            <Button
              type="button"
              style={{ width: '115px' }}
              className="secondary-button"
              secondary={true}
              onClick={() => this.closeModal()}
            >
              CANCEL
            </Button>
            <Button style={{ width: '115px' }} primary={true}>
              ADD
            </Button>
          </div>
        </Form>
        <Confirm
          size="tiny"
          closeIcon={
            <Image
              src={closeIcon}
              className="icon"
              onClick={() => this.setState({ showConfirm: false })}
            />
          }
          open={this.state.showConfirm}
          onCancel={() => this.setState({ showConfirm: false })}
          header="Confirm"
          content="Are you sure you want to delete this file?"
          cancelButton="NO"
          confirmButton="YES"
          onConfirm={() =>
            this.setState({
              showFile: false,
              file: '',
              fileType: '',
              fileName: '',
              fileSize: '',
              showConfirm: false,
              fileInitial: '',
              fileError: false,
              fileErrorText: '',
            })
          }
          className="confirm-modal-custom"
          closeOnDimmerClick={false}
        />
      </Modal>
    );
  }
}

export default AddResumeModal;
