import { toast } from 'react-toastify';
import {call, put, select, takeEvery} from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import services from '../../services';
import * as actions from './actions';
import * as constants from './constants';

export default function* () {
  yield takeEvery(constants.SEARCH_MATERIAL, searchMaterial);
}

export function* searchMaterial(action: ActionType<typeof actions.searchMaterial>) {
  const { pagingInfo } = action.payload;
  const { filter } = pagingInfo;
  const { user } = yield select(state => state.account.account);
  const folderList: any[] = yield select((state) => state.trainingCenter.folderList) || [];

  const selectedFolders: number[] = [];

  for (const folder of folderList) {
    if (folder.active) {
      selectedFolders.push(folder.id as number);
    }

    for (const sub of folder.subFolders) {
      if (sub.active || sub.parentActive) {
        selectedFolders.push(sub.id as number);
      }
    }
  }

  if (selectedFolders.length > 0) {
    filter.folderIds = selectedFolders.join(',');
  }

  const requestList = yield call(services.api.material.searchMaterial, user.id, filter, pagingInfo);
  if (requestList.isSuccess) {
    yield put(
      actions.setMaterialSearchResult(requestList.data.data.list, requestList.data.data.count)
    );

    if (pagingInfo.resetSelect) {
      // search all with no pagination = false
      filter.noPagination = true;
      const apiAllRequest = yield call(services.api.material.searchMaterial, user.id, filter, pagingInfo);
      yield put(actions.setMaterialAllSearchResult(apiAllRequest.data.data.list));
    }
  } else {
    toast.dismiss();
    toast.error(requestList.data ? requestList.data.errors[0].msg : `Error ${requestList.status}`);
  }
}
