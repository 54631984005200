import { Form } from 'formsy-semantic-ui-react';
import moment from 'moment';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Confirm, Image, Loader, Modal, Popup } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import { uploads } from '../../config';
import services from '../../services';
import DatePickerInput from '../datepicker-input/DatePickerInput';
import ErrorLabel from '../error-label/ErrorLabel';
import { FilePreview } from '../file-preview/FilePreview';
import UploadZone from './../file-uploader/UploadZone';

interface Props {
  showModal: (show: boolean, id?: number) => void;
  editLicense: (id: number, item: object) => void;
  show: boolean;
  item: object;
  mode: string;
}

interface State {
  showFile: boolean;
  showConfirm: boolean;
  statesOptions: object[];
  fileInitial: any;
  file: string | ArrayBuffer | null;
  fileType: string;
  fileSize: string;
  fileError: boolean;
  fileKey: string;
  fileName: string;
}

class EditLicenseModal extends React.Component<Props, State> {
  public state = {
    showFile: false,
    showConfirm: false,
    statesOptions: [],
    file: '',
    fileInitial: '',
    fileType: '',
    fileSize: '',
    fileError: false,
    fileKey: '',
    fileName: '',
  };

  public fileInputRef = React.createRef();

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.item !== this.props.item) {
      if (this.props.item && this.props.item.file) {
        this.setState({
          showFile: this.props.item.file.file !== '',
          file: this.props.item.file.file,
          fileType: this.props.item.file.type || this.props.item.file.fileType,
          fileSize: this.props.item.file.size || this.props.item.file.fileSize,
          fileKey: this.props.item.file.key || this.props.item.file.fileKey,
          fileName: this.props.item.file.name || this.props.item.file.fileName,
          fileInitial: this.props.item.file.fileInitial ? this.props.item.file.fileInitial : '',
        });
      }

      if (this.props.item && !this.props.item.file) {
        this.setState({
          showFile: false,
          showConfirm: false,
          file: '',
          fileInitial: '',
          fileType: '',
          fileSize: '',
          fileError: false,
          fileKey: '',
          fileName: '',
        });
      }
    }
  }

  public componentDidMount = async () => {
    if (this.props.mode === 'license') {
      const request = await services.api.account.getSettingsData('state');
      if (request.isSuccess) {
        const statesOptions = request.data.list.map((el: any, index: any) => {
          const element = { text: el.name, value: el.name };
          return element;
        });
        this.setState({ statesOptions });
      }
    } else {
      const request = await services.api.account.getSettingsData('certification');
      if (request.isSuccess) {
        const statesOptions = request.data.list.map((el: any, index: any) => {
          const element = { text: el.name, value: el.name };
          return element;
        });
        this.setState({ statesOptions });
      }
    }
  };

  public handleFileDrop = async (files: any) => {
    const file = files[0];
    if (file === undefined) {
      this.setState({ fileError: true });
    } else {
      if (file.size > uploads.maxFileSize) {
        return toast.warn(uploads.maxFileSizeErrorMsg);
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
        this.setState({
          fileInitial: file,
          file: reader.result,
          fileSize: file.size,
          fileName: file.name,
          showFile: true,
          fileType: file.type,
          fileError: false,
        });
      };
    }
  };

  public onCancel = () => {
    this.props.showModal(false);

    this.setState({
      showFile: false,
      showConfirm: false,
      file: '',
      fileInitial: '',
      fileType: '',
      fileSize: '',
      fileError: false,
      fileKey: '',
      fileName: '',
    });
  };

  public onSubmit = model => {
    const obj = Object.assign(
      {},
      model,
      { id: 1000 * Math.random() },
      {
        file:
          this.state.fileKey && !this.state.file
            ? {
                fileKey: this.state.fileKey,
                fileType: this.state.fileType,
                fileSize: this.state.fileSize,
              }
            : {
                fileKey: this.state.fileKey,
                file: this.state.file,
                fileInitial: this.state.fileInitial,
                fileName: this.state.fileName,
                fileType: this.state.fileType,
                fileSize: this.state.fileSize,
              },
      },
      { fileType: this.state.fileType }
    );

    this.props.editLicense(this.props.item.id, obj);
    this.props.showModal(false);
  };

  public render() {
    if (!this.props.item) {
      return <Loader size="massive">Loading</Loader>;
    }
    const { item, mode } = this.props;
    const errorLabel = <ErrorLabel />;
    return (
      <Modal
        size="tiny"
        className="licenses-modal"
        open={this.props.show}
        onClose={() =>
          this.setState({
            showFile: false,
            showConfirm: false,
            file: '',
            fileType: '',
            fileSize: '',
            fileError: false,
            fileInitial: '',
            fileKey: '',
            fileName: '',
          })
        }
        closeIcon={
          <Image src={closeIcon} className="icon" onClick={() => this.props.showModal(false)} />
        }
      >
        <Modal.Header>
          {this.props.mode === 'license' ? 'Edit License' : 'Edit Certification'}
        </Modal.Header>
        <Form onValidSubmit={this.onSubmit}>
          <Modal.Content style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
            <div className="input-field-container">
              <div className="input-field-label">State</div>
              <Form.Dropdown
                name={mode === 'cert' ? 'type' : 'state'}
                search={true}
                selectOnBlur={false}
                options={this.state.statesOptions}
                placeholder="Please Select"
                instantValidation={false}
                required={true}
                validationErrors={{
                  isDefaultRequiredValue: `* State is required field.`,
                }}
                className="form-input-dropdown"
                errorLabel={errorLabel}
                value={mode === 'cert' ? item.type : item.state}
              />
            </div>
            <div className="input-field-container">
              <div className="input-field-label">
                {this.props.mode === 'license' ? 'License' : 'Certification'} #
              </div>
              <Form.Input
                maxLength={30}
                name={mode === 'cert' ? 'certification' : 'license'}
                placeholder="Please Enter..."
                instantValidation={false}
                required={true}
                validationErrors={{
                  isDefaultRequiredValue: `* License is required field.`,
                }}
                className="form-input"
                errorLabel={errorLabel}
                value={mode === 'cert' ? item.certification : item.license}
              />
            </div>
            <div className="input-field-container">
              <div className="input-field-label">Issued Date</div>
              <DatePickerInput
                name="issuedDate"
                required={true}
                value={item.issuedDate ? item.issuedDate : ''}
                validations={{
                  isValidMax: moment(),
                  isValidDate: true,
                }}
                validationErrors={{
                  isDefaultRequiredValue: `* Issued Date is required field.`,
                  isValidMax: `* Date should not be later than current date.`,
                  isValidDate: `* Date format is invalid.`,
                }}
                errorLabel={errorLabel}
              />
            </div>
            <div className="input-field-container">
              <div className="input-field-label">Expiration Date</div>
              <DatePickerInput
                name="expirationDate"
                required={true}
                value={item.expirationDate ? item.expirationDate : ''}
                validations={{ isValidExpirationDate: true, isValidDate: true }}
                validationErrors={{
                  isDefaultRequiredValue: `* Expiration Date is required field.`,
                  isValidExpirationDate: `* Date should not be earlier than issued date.`,
                  isValidDate: `* Date format is invalid.`,
                }}
                errorLabel={errorLabel}
              />
            </div>
            <div className="input-field-container">
              <div className="input-field-label">
                {this.props.mode === 'license' ? 'Licenses' : 'Certifications'}
              </div>
              <div />
            </div>
            {!this.state.showFile ? (
              <div>
                <UploadZone
                  onDrop={this.handleFileDrop}
                  accept="image/jpeg, image/png, application/pdf"
                />
                {this.state.fileError && (
                  <ErrorLabel>
                    <div style={{ paddingLeft: '1rem' }}>
                      * Only *.pdf, *.jpg, *.jpeg, *.png files are supported.
                    </div>
                  </ErrorLabel>
                )}
              </div>
            ) : (
              <div className="licenses-file-preview">
                <FilePreview type={this.state.fileType} src={this.state.file} />
                <Popup
                  trigger={
                    <Button
                      type="button"
                      className="iconic-button-custom"
                      onClick={() => this.setState({ showConfirm: true })}
                    >
                      <Image src={closeIcon} />
                    </Button>
                  }
                  size="mini"
                  basic={true}
                  content="Delete"
                />
              </div>
            )}
          </Modal.Content>

          <div className="modal-button-footer">
            <Button
              type="button"
              style={{ width: '115px' }}
              className="secondary-button"
              secondary={true}
              onClick={() => this.onCancel()}
            >
              CANCEL
            </Button>
            <Button style={{ width: '115px' }} primary={true}>
              SAVE
            </Button>
          </div>
        </Form>
        <Confirm
          size="tiny"
          closeIcon={
            <Image
              src={closeIcon}
              className="icon"
              onClick={() => this.setState({ showConfirm: false })}
            />
          }
          open={this.state.showConfirm}
          onCancel={() => this.setState({ showConfirm: false })}
          header="Confirm"
          content="Are you sure you want to delete this file?"
          cancelButton="NO"
          confirmButton="YES"
          onConfirm={() =>
            this.setState({
              showFile: false,
              showConfirm: false,
              file: '',
              fileType: '',
              fileSize: '',
              fileError: false,
              fileInitial: '',
              fileKey: '',
              fileName: '',
            })
          }
          className="confirm-modal-custom"
          closeOnDimmerClick={false}
        />
      </Modal>
    );
  }
}

export default EditLicenseModal;
