import { action } from 'typesafe-actions';
import * as constants from './constants';

// export type ModalModel = {
//   title: string;
//   last_name: string;
//   email: string;
//   password: string;
//   passwordConfirm: string;
//   recaptcha_code: string;
// }

export interface ConformationModel {
  title?: string;
  message: string;
  okAction: object;
  cancelAction?: object;
}

export const confirmationModalDisplay = (model: ConformationModel) =>
  action(constants.CONFIRMATION_MODAL_DISPLAY, model);

export const confirmationModalHide = () => action(constants.CONFIRMATION_MODAL_HIDE);

export const confirmationModalConfirm = () => action(constants.CONFIRMATION_MODAL_CONFIRM);

export interface AlertModel {
  title?: string;
  message: string;
  closeAction?: object;
}

export const alertModalDisplaySuccess = (model: AlertModel) =>
  action(constants.ALERT_MODAL_DISPLAY, {
    type: 'success',
    model,
  });

export const alertModalDisplayWarning = (model: AlertModel) =>
  action(constants.ALERT_MODAL_DISPLAY, {
    type: 'warning',
    model,
  });

export const alertModalDisplayError = (model: AlertModel) =>
  action(constants.ALERT_MODAL_DISPLAY, {
    type: 'error',
    model,
  });

export const alertModalHide = () => action(constants.ALERT_MODAL_HIDE);
