import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as constants from './constants';

export interface Standby {
  standbyList: object[];
  totalLists: number;
  activeListPage: number;
  activeTab: string;
  standbySort: any;
  standbySearch: string | undefined;
  activeStandbyId: any;
  editStandby: boolean;
  showInviteMessage: boolean;
  showInviteModal: boolean;
  selectedId: object[];
  activeList: object;
  redirectStandby: undefined;
}

export interface StandbyAdjusters {
  selectedAdjusters: object[];
}

export const createStandbyState: Standby = {
  standbyList: [],
  totalLists: 0,
  activeListPage: 1,
  activeTab: 'Invited',
  standbySort: 'createdAt,DESC',
  standbySearch: undefined,
  activeStandbyId: 0,
  editStandby: false,
  showInviteMessage: false,
  showInviteModal: false,
  selectedId: [],
  activeList: {},
  redirectStandby: undefined,
};

export const createStandbyAdjustersState: StandbyAdjusters = {
  selectedAdjusters: [],
};

export interface State {
  standby: Standby;
  adjusters: StandbyAdjusters;
}

export type Action = ActionType<typeof actions>;

export default combineReducers<State, Action>({
  standby: (state = createStandbyState, action) => {
    switch (action.type) {
      case constants.GET_STANDBY_LIST:
        return { ...state };
      case constants.SUBMIT_STANDBY_LIST:
        return { ...state, standbyList: action.payload.list, totalLists: action.payload.count };
      case constants.EDIT_STANDBY_LIST:
        return { ...state, editStandby: action.payload.edit };
      case constants.SET_STANDBY_TAB:
        return { ...state, activeTab: action.payload.tab };
      case constants.SET_STANDBY_SORT:
        return { ...state, standbySort: action.payload.sort };
      case constants.SET_STANDBY_SEARCH:
        return { ...state, standbySearch: action.payload.search };
      case constants.SET_ACTIVE_STANDBY:
        return { ...state, activeStandbyId: action.payload.id };
      case constants.SET_ACTIVE_STANDBY_RELOADED:
        return { ...state };
      case constants.SUBMIT_ACTIVE_STANDBY_RELOADED:
        return { ...state, activeList: action.payload.list };
      case constants.SHOW_INVITE_MESSAGE:
        return { ...state, showInviteMessage: action.payload.show };
      case constants.SHOW_INVITE_MODAL:
        return { ...state, showInviteModal: action.payload.show };
      case constants.SET_SELECTED_ID:
        return { ...state, selectedId: action.payload.arrayId };
      case constants.SET_ACTIVE_PAGE:
        return { ...state, activeListPage: action.payload.page };
      case constants.SET_REDIRECT_STANDBY:
        return { ...state, redirectStandby: action.payload.id };

      default:
        return state;
    }
  },
  adjusters: (state = createStandbyAdjustersState, action) => {
    switch (action.type) {
      case constants.GET_SELECTED_ADJUSTERS:
        return { ...state };

      default:
        return state;
    }
  },
});
