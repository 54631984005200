import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { Dimmer, Loader, Placeholder, Segment } from 'semantic-ui-react';

const largeListPlaceholder = ['finance-adjuster-list'];

export const LoadingSpiner = (props: any) => {
  const { promiseInProgress } = usePromiseTracker({ area: props.area });
  const index = largeListPlaceholder.findIndex(el => el === props.area);
  return (
    <div className="table-spinner">
      {promiseInProgress !== true ? null : index === -1 ? (
        <Dimmer active={true} inverted={true}>
          <Loader />
        </Dimmer>
      ) : (
        <Segment style={{ marginTop: '1rem' }}>
          <Dimmer active={true} inverted={true}>
            <Loader />
          </Dimmer>
          <Placeholder fluid={true}>
            <Placeholder.Header image={true}>
              <Placeholder.Line length="full" />
              <Placeholder.Line length="full" />
            </Placeholder.Header>
            <Placeholder.Header image={true}>
              <Placeholder.Line length="full" />
              <Placeholder.Line length="full" />
            </Placeholder.Header>
            <Placeholder.Header image={true}>
              <Placeholder.Line length="full" />
              <Placeholder.Line length="full" />
            </Placeholder.Header>
            <Placeholder.Header image={true}>
              <Placeholder.Line length="full" />
              <Placeholder.Line length="full" />
            </Placeholder.Header>
            <Placeholder.Header image={true}>
              <Placeholder.Line length="full" />
              <Placeholder.Line length="full" />
            </Placeholder.Header>
            <Placeholder.Header image={true}>
              <Placeholder.Line length="full" />
              <Placeholder.Line length="full" />
            </Placeholder.Header>
            <Placeholder.Header image={true}>
              <Placeholder.Line length="full" />
              <Placeholder.Line length="full" />
            </Placeholder.Header>
            <Placeholder.Header image={true}>
              <Placeholder.Line length="full" />
              <Placeholder.Line length="full" />
            </Placeholder.Header>
            <Placeholder.Header image={true}>
              <Placeholder.Line length="full" />
              <Placeholder.Line length="full" />
            </Placeholder.Header>
            <Placeholder.Header image={true}>
              <Placeholder.Line length="full" />
              <Placeholder.Line length="full" />
            </Placeholder.Header>
          </Placeholder>
        </Segment>
      )}
    </div>
  );
};
