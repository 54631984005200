import moment from 'moment';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Image, Pagination, Popup, Responsive, Table } from 'semantic-ui-react';
import downloadIcon from '../../assets/images/Blue/download.svg';
import noAvatar from '../../assets/images/no-avatar.svg';
import downloadIconW from '../../assets/images/White/download.svg';
import config from '../../config';
import { IUser } from '../../models';
import services from '../../services';
import * as helper from '../../utils/helper';
import { LoadingSpiner } from './../loading-spinner/LoadingSpinner';
import { NoResults } from './../no-results-placeholder/NoResults';
import CustomIconicButton from './../register-layout/CustomIconicButton';
import FinanceFilter from './../table-filters/FinanceFilter';

interface Props {
  getSubmitRef: (ref: any) => void;
  user: IUser;
  download: (file: any) => void;
}

interface State {
  finances: object[];
  initialFinances: object[];
  filterState: object;
  totalFinances: number;
  showFilters: boolean;
  activeId: number;
  activePage: number;
  page: number;
}

const initialFilter = {
  from: '',
  to: '',
  name: '',
  uploaderId: '',
  sort: 'finance.createdAt,desc',
};

class ProfileFinances extends React.Component<Props, State> {
  public state = {
    finances: [],
    initialFinances: [],
    filterState: {},
    totalFinances: 0,
    activeId: 0,
    showFilters: false,
    activePage: 1,
    page: 0,
  };

  public componentDidMount = () => {
    this.listFinances(initialFilter);
  };

  public listFinances = async (params?: any) => {
    if (params) {
      const dataModel = Object.assign({}, params);
      for (const key in dataModel) {
        if (dataModel[key] === '') {
          dataModel[key] = undefined;
        }
      }
      if (dataModel.from && !dataModel.to) {
        dataModel.to = moment()
          .add(1, 'd')
          .format();
      }
      params = dataModel;
    }
    const response = await services.api.user.getFilteredFinances(params);
    if (response.isSuccess) {
      this.setState({ finances: response.data.list, totalFinances: response.data.count });
    }
    const response2 = await services.api.user.getFilteredFinances({
      adjusterId: this.props.user.id,
      page: params ? params.page : 0,
    });
    if (response2.isSuccess) {
      this.setState({ initialFinances: response2.data.list });
    }
  };

  public handleExportList = async () => {
    const { filterState } = this.state;

    const dataModel = Object.assign({}, filterState);
    for (const key in dataModel) {
      if (dataModel[key] === '') {
        dataModel[key] = undefined;
      }
    }
    const params = dataModel;

    const response = await services.api.finance.exportFinanceList({
      adjusterId: this.props.user.id,
      ...params,
    });
    if (response.error) {
      console.log(response);
      toast.dismiss();
      toast.error(response.error ? response.error : `Error on download attempt.`);
    }
  };

  public handleFilterReset = () => {
    this.setState(
      {
        filterState: {
          ...initialFilter,
          page: 0,
        },
        activePage: 1,
        finances: [],
      },
      () => this.listFinances(this.state.filterState)
    );
  };

  public handleFilterChange = (data: any) => {
    this.setState(
      {
        filterState: {
          ...this.state.filterState,
          ...data,
          page: 0,
        },
        activePage: 1,
        finances: [],
      },
      () => this.listFinances(this.state.filterState)
    );
  };

  public handlePagination = (e: any, data: any) => {
    this.changePageAndRefresh(data.activePage);
  };

  public render() {
    const { finances, showFilters, activeId, totalFinances, initialFinances } = this.state;
    const totalPages = helper.getTotalPage(totalFinances, 20);
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%',
            marginBottom: '1rem',
          }}
        >
          <Popup
            trigger={
              <Button
                primary={true}
                className="adjuster-action-button"
                disabled={finances.length === 0}
                onClick={this.handleExportList}
              >
                <Image src={downloadIconW} />
              </Button>
            }
            basic={true}
            size="mini"
            content={'Export list'}
          />
          <Button
            primary={true}
            style={{ height: '32px' }}
            onClick={() => this.setState({ showFilters: !this.state.showFilters })}
          >
            Filter
          </Button>
        </div>
        {showFilters && (
          <FinanceFilter
            filterLogs={this.handleFilterChange}
            filter={this.state.filterState}
            reset={this.handleFilterReset}
            finances={initialFinances}
          />
        )}
        <LoadingSpiner area="finance-adjuster-list" />

        <Responsive maxWidth={767} className="finances-adjuster-page-responsive-container">
          {finances.length === 0 ? (
            <NoResults />
          ) : (
            <Table
              stackable={true}
              compact={true}
              striped={true}
              className="data-table adjusters-table logs-table"
              style={{ marginTop: '1rem' }}
            >
              <Table.Body>
                {finances.map((finance: any, i: number) => (
                  <Table.Row key={finance.id}>
                    <Table.Cell className="table-date">
                      {moment(finance.createdAt).format('MM/DD/YYYY, h:mA ')}
                    </Table.Cell>
                    <Table.Cell className="table-email" collapsing={true}>
                      {finance.file.name}
                    </Table.Cell>
                    <Table.Cell className="table-user" style={{ position: 'relative' }}>
                      <Image
                        circular={true}
                        src={
                          finance.uploader.avatar
                            ? `${config.uploads.s3BucketAddress}${finance.uploader.avatar}`
                            : noAvatar
                        }
                        avatar={true}
                      />
                      {finance.uploader.firstName} {finance.uploader.lastName}
                      <div className="finance-uploader-avatar">
                        <CustomIconicButton
                          onPress={() =>
                            this.setState({ activeId: i }, () =>
                              this.props.download(finances[activeId].file)
                            )
                          }
                          popup={'Download'}
                          image={downloadIcon}
                        />
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}
        </Responsive>

        <Responsive minWidth={768} className="finances-adjuster-page-responsive-container">
          {finances.length === 0 ? (
            <NoResults />
          ) : (
            <Table
              stackable={true}
              compact={true}
              striped={true}
              className="data-table adjusters-table logs-table"
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell className="table-date">Date</Table.HeaderCell>
                  <Table.HeaderCell id="table-email">File Name</Table.HeaderCell>
                  <Table.HeaderCell className="table-user">Uploaded by</Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {finances.map((finance: any, i: number) => (
                  <Table.Row key={finance.id}>
                    <Table.Cell className="table-date">
                      {moment(finance.createdAt).format('MM/DD/YYYY, h:mA ')}
                    </Table.Cell>
                    <Table.Cell className="table-email" collapsing={true}>
                      {finance.file.name}
                    </Table.Cell>
                    <Table.Cell className="table-user">
                      <Image
                        circular={true}
                        src={
                          finance.uploader.avatar
                            ? `${config.uploads.s3BucketAddress}${finance.uploader.avatar}`
                            : noAvatar
                        }
                        avatar={true}
                      />
                      {finance.uploader.firstName} {finance.uploader.lastName}
                    </Table.Cell>
                    <Table.Cell className="actions-table-row">
                      <div className="finance-uploader-avatar">
                        <CustomIconicButton
                          onPress={() =>
                            this.setState({ activeId: i }, () => this.props.download(finance.file))
                          }
                          popup={'Download'}
                          image={downloadIcon}
                        />
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}
        </Responsive>
        {totalPages > 1 && (
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Pagination
              totalPages={totalPages}
              boundaryRange={1}
              activePage={this.state.activePage}
              ellipsisItem={null}
              firstItem={null}
              lastItem={null}
              prevItem={
                this.state.activePage === 1
                  ? false
                  : {
                      'aria-label': 'Previous item',
                      content: 'PREVIOUS',
                    }
              }
              nextItem={
                this.state.activePage === totalPages
                  ? false
                  : {
                      'aria-label': 'Next item',

                      content: 'NEXT',
                    }
              }
              siblingRange={1}
              onPageChange={this.handlePagination}
            />
          </div>
        )}
      </>
    );
  }

  private changePageAndRefresh(page: number) {
    this.setState(
      {
        filterState: {
          ...this.state.filterState,
          page: page - 1,
        },
        activePage: page,
      },
      () => this.listFinances(this.state.filterState)
    );
  }
}

export default ProfileFinances;
