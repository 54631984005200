import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Pagination } from 'semantic-ui-react';
import { standbyActions } from '../../features/standby';
import * as helper from '../../utils/helper';
import { LoadingSpiner } from '../loading-spinner/LoadingSpinner';
import { NoResults } from '../no-results-placeholder/NoResults';
import SBListItem from './SBListItem';

interface Props {
  getStandbyList: typeof standbyActions.getStandbyList;
  setActiveStandby: typeof standbyActions.setActiveStandby;
  setActivePage: typeof standbyActions.setActivePage;
  standbyList: object[];
  totalLists: number;
  activePage: number;
}

interface State {
  standbyItems: any[];
  totalPages: number;
  pageSize: number;
  page: number;
}

class SBList extends React.Component<Props, State> {
  public state = {
    standbyItems: [],
    totalPages: 0,
    pageSize: 10,
    page: 1,
  };

  public getListItems = async () => {
    this.props.getStandbyList();
  };

  public componentDidUpdate = (prevProps: Props) => {
    if (prevProps.standbyList !== this.props.standbyList) {
      this.setState({ standbyItems: [] }, () =>
        this.setState({ standbyItems: this.props.standbyList })
      );
    }
    if (prevProps.totalLists !== this.props.totalLists) {
      this.setState({ totalPages: this.props.totalLists });
    }
  };

  public handlePagination = (e: any, data: any) => {
    this.props.setActivePage(data.activePage);
    this.props.getStandbyList();
  };

  public render() {
    const { standbyItems, pageSize, page } = this.state;
    const totalPages = helper.getTotalPage(this.props.totalLists, pageSize);
    return (
      <div className="standby-list-page">
        <LoadingSpiner area="standby-list" />
        {standbyItems.length === 0 ? (
          <NoResults />
        ) : (
          <div>
            {standbyItems.map((el: any, index: number) => {
              return (
                <SBListItem
                  setActive={this.props.setActiveStandby}
                  key={index}
                  item={el}
                  updateList={this.getListItems}
                />
              );
            })}

            {totalPages > 1 && (
              <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <Pagination
                  totalPages={totalPages}
                  boundaryRange={1}
                  activePage={this.props.activePage}
                  ellipsisItem={null}
                  firstItem={null}
                  lastItem={null}
                  prevItem={
                    this.props.activePage === 1
                      ? false
                      : {
                          'aria-label': 'Previous item',
                          content: 'PREVIOUS',
                        }
                  }
                  nextItem={
                    this.props.activePage === totalPages
                      ? false
                      : {
                          'aria-label': 'Next item',

                          content: 'NEXT',
                        }
                  }
                  siblingRange={1}
                  onPageChange={this.handlePagination}
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
  standbyList: state.standby.standby.standbyList,
  totalLists: state.standby.standby.totalLists,
  activePage: state.standby.standby.activeListPage,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getStandbyList: standbyActions.getStandbyList,
      setActiveStandby: standbyActions.setActiveStandby,
      setActivePage: standbyActions.setActivePage,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SBList);
