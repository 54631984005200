import * as Formsy from 'formsy-react';
import { Form } from 'formsy-semantic-ui-react';
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Grid, Icon, Responsive } from 'semantic-ui-react';
import { accountActions } from '../../features/account';
import { NewUser } from '../../models/newUser';
import services from '../../services';
import CheckboxGroup from '../checkbox-group/CheckboxGroup';
import ErrorLabel from '../error-label/ErrorLabel';
import ExperienceItem from './../register-layout/ExperienceItem';
import LossTypeItem from './../register-layout/LossTypeItem';

interface Props {
  getSubmitRef: (ref: any) => void;
  newUser: NewUser;
  progress: number;
  step: number;
  changeRegisterStore: typeof accountActions.changeRegisterStore;
  changeProgressValue: typeof accountActions.changeProgressValue;
}

interface State {
  isChecked: boolean;
  isChecked2: boolean;
  climbing: boolean;
  experiences: object[];
  skills: object[];
  losses: object[];
  languageOptions: object[];
  equipmentOptions: object[];
  selectedItems: string[];
}

class RegisterSkills extends React.Component<Props, State> {
  public state = {
    experiences: [{ id: 0 }],
    skills: [{ id: 0 }],
    losses: [
      {
        id: 0,
        type: '',
        commercialCATClaims: 0,
        residentialCATClaims: 0,
        commercialDailyClaims: 0,
        residentialDailyClaims: 0,
      },
    ],
    isChecked: false,
    isChecked2: false,
    climbing: true,
    languageOptions: [],
    equipmentOptions: [],
    selectedItems: [],
  };

  public sumbitRef = React.createRef();

  public componentDidMount = async () => {
    this.props.getSubmitRef(this.sumbitRef);

    const response = await services.api.account.getSettingsData('language');
    if (response.isSuccess) {
      const languageOptions = response.data.list.map((el: any) => ({
        label: el.name,
        checked: this.props.newUser.languages.includes(el.name),
      }));
      this.setState({ languageOptions });
    } else {
      console.log(response);
    }

    const response2 = await services.api.account.getSettingsData('equipment');
    if (response2.isSuccess) {
      const equipmentOptions = response2.data.list.map((el: any) => ({
        label: el.name,
        checked: this.props.newUser.equipments.includes(el.name),
      }));
      this.setState({ equipmentOptions });
    } else {
      console.log(response2);
    }

    this.setState({
      experiences:
        this.props.newUser.experiences.length === 0 ? [{ id: 0 }] : this.props.newUser.experiences,
      skills:
        this.props.newUser.softwareSkills.length === 0
          ? [{ id: 0 }]
          : this.props.newUser.softwareSkills,
      losses:
        this.props.newUser.expertise.length === 0
          ? [
              {
                id: 0,
                type: '',
                commercialCATClaims: 0,
                residentialCATClaims: 0,
                commercialDailyClaims: 0,
                residentialDailyClaims: 0,
              },
            ]
          : this.props.newUser.expertise,
      climbing: this.props.newUser.climbRoof,
    });
  };

  public editLoss = (id: number, item: any) => {
    const { losses } = this.state;
    const index = losses.findIndex(el => el.id === id);
    losses[index] = item;
    this.setState({ losses });
  };

  public editExperience = (id: number, name: any, value: any) => {
    const { experiences } = this.state;
    const index = experiences.findIndex(el => el.id === id);
    experiences[index][name] = value;
    this.setState({ experiences });
  };

  public editSkills = (id: number, name: any, value: any) => {
    const { skills } = this.state;
    const index = skills.findIndex(el => el.id === id);
    skills[index][name] = value;
    this.setState({ skills });
  };

  public addExperience = () => {
    const { experiences } = this.state;
    experiences.push({ id: 1000 * Math.random() });
    this.setState({ experiences });
  };

  public addLossItem = () => {
    const { losses } = this.state;
    losses.push({
      id: 1000 * Math.random(),
      type: '',
      commercialCATClaims: 0,
      residentialCATClaims: 0,
      commercialDailyClaims: 0,
      residentialDailyClaims: 0,
    });
    this.setState({ losses });
  };

  public addSkills = () => {
    const { skills } = this.state;
    skills.push({ id: 1000 * Math.random() });
    this.setState({ skills });
  };

  public deleteLoss = (id: number) => {
    const { losses } = this.state;
    const index = losses.findIndex(el => el.id === id);
    losses.splice(index, 1);
    this.setState({ losses });
  };

  public deleteExperience = (id: number) => {
    const { experiences } = this.state;
    const index = experiences.findIndex(el => el.id === id);
    experiences.splice(index, 1);
    this.setState({ experiences });
  };

  public deleteSkills = (id: number) => {
    const { skills } = this.state;
    const index = skills.findIndex(el => el.id === id);
    skills.splice(index, 1);
    this.setState({ skills });
  };

  public render() {
    Formsy.addValidationRule('isChecked2', () => {
      return this.state.isChecked2 === true;
    });

    const { newUser } = this.props;
    const errorLabel = <ErrorLabel />;
    return (
      <React.Fragment>
        <Grid stackable={true} style={{ width: '100%' }} className="skills-grid register-part-grid">
          <Responsive minWidth={769} style={{ width: '100%' }}>
            <Grid.Column width={16}>
              <Form
                onInvalidSubmit={() => {
                  const { losses } = this.state;
                  console.log(losses);
                }}
                onValidSubmit={(model: any) => {
                  const { losses, experiences, skills, climbing } = this.state;
                  console.log(losses);
                  const expArray = [];
                  for (let i = 0; i < experiences.length; i++) {
                    expArray[i] = {
                      name: model[`experience-field-${i}`],
                      years: Number(model[`experience-years-${i}`]),
                    };
                  }
                  const skillsArray = [];
                  for (let j = 0; j < skills.length; j++) {
                    skillsArray[j] = {
                      name: model[`skills-field-${j}`],
                      years: Number(model[`skills-years-${j}`]),
                    };
                  }
                  const obj = Object.assign(
                    {},
                    { experiences: expArray },
                    { languages: model.languages },
                    { softwareSkills: skillsArray },
                    { equipments: model.equipments },
                    { expertise: losses },
                    { climbRoof: climbing },
                    { milesRange: Number(model.milesRange) }
                  );

                  this.props.changeProgressValue(this.props.progress + 11);
                  this.props.changeRegisterStore(obj);
                }}
              >
                {this.state.experiences.map((el: any, index: number) => (
                  <div className="input-field-container" key={index}>
                    <div className="input-field-label">{index === 0 ? 'Experience' : null}</div>
                    <ExperienceItem
                      name="test"
                      id={index}
                      item={el}
                      field="experience"
                      delete={this.deleteExperience}
                      edit={this.editExperience}
                      allItems={this.state.experiences.map((item: any) => item.name)}
                    />
                  </div>
                ))}

                <div className="input-field-container">
                  <div className="input-field-label" />
                  <div className="add-question">
                    <Button type="button" onClick={() => this.addExperience()}>
                      + ADD
                    </Button>
                  </div>
                </div>
                <div className="input-field-container">
                  <div className="input-field-label">Languages Spoken</div>
                  <CheckboxGroup
                    name="languages"
                    items={this.state.languageOptions}
                    validations={{
                      isChecked: true,
                    }}
                    validationErrors={{
                      isChecked: '* Languages is required field.',
                    }}
                    value=""
                    errorLabel={errorLabel}
                  />
                </div>

                {this.state.skills.map((el: any, index: number) => (
                  <div className="input-field-container" key={index}>
                    <div className="input-field-label">
                      {index === 0 ? 'Software Skills' : null}
                    </div>
                    <ExperienceItem
                      name="test"
                      id={index}
                      item={el}
                      field="skills"
                      delete={this.deleteSkills}
                      edit={this.editSkills}
                      allItems={this.state.skills.map((item: any) => item.name)}
                    />
                  </div>
                ))}

                <div className="input-field-container">
                  <div className="input-field-label" />
                  <div className="add-question">
                    <Button type="button" onClick={() => this.addSkills()}>
                      + ADD
                    </Button>
                  </div>
                </div>
                <div className="input-field-container">
                  <div className="input-field-label">Equipment</div>
                  <CheckboxGroup
                    name="equipments"
                    items={this.state.equipmentOptions}
                    validations={{
                      isChecked: true,
                    }}
                    validationErrors={{
                      isChecked: '* Equipment is required field.',
                    }}
                    value=""
                    errorLabel={errorLabel}
                  />
                </div>
                {this.state.losses.map((el: any, index: number) => (
                  <div className="input-field-container expertise-loss-container" key={index}>
                    <div className="input-field-label">
                      {index === 0 ? 'Adjuster Expertise' : null}
                    </div>
                    <LossTypeItem
                      name="loss-item"
                      id={index}
                      index={index}
                      required={true}
                      item={el}
                      delete={this.deleteLoss}
                      edit={this.editLoss}
                      allItems={this.state.losses.map((item: any) => item.type)}
                      errorLabel={errorLabel}
                      validationErrors={{
                        isDefaultRequiredValue: `* Adjuster Expertise is required field.`,
                      }}
                    />
                  </div>
                ))}

                <div className="input-field-container">
                  <div className="input-field-label" />
                  <div className="add-question">
                    <Button type="button" onClick={() => this.addLossItem()}>
                      + ADD
                    </Button>
                  </div>
                </div>
                <div className="input-field-container">
                  <div className="input-field-label" style={{ paddingRight: '25px' }}>
                    Are you willing to climb roof greater that 8/12 pitch without a roof assist?
                  </div>
                  <div style={{ display: 'flex', width: '100%' }}>
                    <div className="toggle-block">
                      <Button
                        type="button"
                        className={this.state.climbing ? 'yes-btn' : 'no-btn'}
                        onClick={() => this.setState({ climbing: true })}
                      >
                        YES
                      </Button>
                      <Button
                        type="button"
                        className={!this.state.climbing ? 'yes-btn' : 'no-btn'}
                        onClick={() => this.setState({ climbing: false })}
                      >
                        NO
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="input-field-container">
                  <div className="input-field-label" style={{ paddingRight: '25px' }}>
                    How far away from your base location are you willing to accept claims (one way)
                    without billing for mileage?
                  </div>
                  <div className="miles-input">
                    <Form.Input
                      name="milesRange"
                      icon={<Icon>MILES</Icon>}
                      type="number"
                      min={0}
                      placeholder="..."
                      instantValidation={false}
                      className="form-input-years"
                      required={true}
                      value={newUser.milesRange}
                      validationErrors={{
                        isDefaultRequiredValue: `* Miles is required field.`,
                      }}
                      errorLabel={errorLabel}
                    />
                  </div>
                </div>
                <Button ref={this.sumbitRef} type="submit" style={{ display: 'none' }} />
              </Form>
            </Grid.Column>
          </Responsive>

          <Responsive maxWidth={768} style={{ width: '100%' }}>
            <Grid.Column width={16}>
              <Form
                onValidSubmit={(model: any) => {
                  const { losses, experiences, skills, climbing } = this.state;
                  const expArray = [];
                  for (let i = 0; i < experiences.length; i++) {
                    expArray[i] = {
                      name: model[`experience-field-${i}`],
                      years: Number(model[`experience-years-${i}`]),
                    };
                  }
                  const skillsArray = [];
                  for (let j = 0; j < skills.length; j++) {
                    skillsArray[j] = {
                      name: model[`skills-field-${j}`],
                      years: Number(model[`skills-years-${j}`]),
                    };
                  }

                  const obj = Object.assign(
                    {},
                    { experiences: expArray },
                    { languages: model.languages },
                    { softwareSkills: skillsArray },
                    { equipments: model.equipments },
                    { expertise: losses },
                    { climbRoof: climbing },
                    { milesRange: Number(model.milesRange) }
                  );

                  this.props.changeProgressValue(this.props.progress + 11);
                  this.props.changeRegisterStore(obj);
                }}
              >
                {this.state.experiences.map((el: any, index: number) => (
                  <div className="input-field-container" key={index}>
                    <div className="input-field-label">{index === 0 ? 'Experience' : null}</div>
                    <ExperienceItem
                      name="test"
                      id={index}
                      item={el}
                      field="experience"
                      delete={this.deleteExperience}
                      edit={this.editExperience}
                      allItems={this.state.experiences.map((item: any) => item.name)}
                    />
                  </div>
                ))}

                <div className="input-field-container">
                  <div className="input-field-label" />
                  <div className="add-question">
                    <Button type="button" onClick={() => this.addExperience()}>
                      + ADD
                    </Button>
                  </div>
                </div>
                <div className="input-field-container">
                  <div className="input-field-label">Languages Spoken</div>
                  <CheckboxGroup
                    name="languages"
                    items={this.state.languageOptions}
                    validations={{
                      isChecked: true,
                    }}
                    validationErrors={{
                      isChecked: '* Languages Spoken is required field.',
                    }}
                    value=""
                    errorLabel={errorLabel}
                  />
                </div>

                {this.state.skills.map((el: any, index: number) => (
                  <div className="input-field-container" key={index}>
                    <div className="input-field-label">
                      {index === 0 ? 'Software Skills' : null}
                    </div>
                    <ExperienceItem
                      name="test"
                      id={index}
                      item={el}
                      field="skills"
                      delete={this.deleteSkills}
                      edit={this.editSkills}
                      allItems={this.state.skills.map((item: any) => item.name)}
                    />
                  </div>
                ))}

                <div className="input-field-container">
                  <div className="input-field-label" />
                  <div className="add-question">
                    <Button type="button" onClick={() => this.addSkills()}>
                      + ADD
                    </Button>
                  </div>
                </div>
                <div className="input-field-container">
                  <div className="input-field-label">Equipment</div>
                  <CheckboxGroup
                    name="equipments"
                    items={this.state.equipmentOptions}
                    validations={{
                      isChecked: true,
                    }}
                    value=""
                    validationErrors={{
                      isChecked: '* Equipment is required field.',
                    }}
                    errorLabel={errorLabel}
                  />
                </div>
                {this.state.losses.map((el: any, index: number) => (
                  <div className="input-field-container" key={index}>
                    <div className="input-field-label" style={{ marginBottom: '7px' }}>
                      {index === 0 ? 'Adjuster Expertise' : null}
                    </div>
                    <LossTypeItem
                      name="loss-item"
                      id={index}
                      required={true}
                      item={el}
                      delete={this.deleteLoss}
                      edit={this.editLoss}
                      allItems={this.state.losses.map((item: any) => item.type)}
                      errorLabel={errorLabel}
                      validationErrors={{
                        isDefaultRequiredValue: `* Adjuster Expertise is required field.`,
                      }}
                    />
                  </div>
                ))}
                <div className="input-field-container">
                  <div className="input-field-label" />
                  <div className="add-question">
                    <Button type="button" onClick={() => this.addLossItem()}>
                      + ADD
                    </Button>
                  </div>
                </div>
                <div className="input-field-container">
                  <div className="input-field-label">
                    Are you willing to climb roof greater that 8/12 pitch without a roof assist?
                  </div>
                  <div className="climbing-toogle">
                    <div className="toggle-block">
                      <Button
                        type="button"
                        className={this.state.climbing ? 'yes-btn' : 'no-btn'}
                        onClick={() => this.setState({ climbing: true })}
                      >
                        YES
                      </Button>
                      <Button
                        type="button"
                        className={!this.state.climbing ? 'yes-btn' : 'no-btn'}
                        onClick={() => this.setState({ climbing: false })}
                      >
                        NO
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="input-field-container">
                  <div className="input-field-label">
                    How far away from your base location are you willing to accept claims (one way)
                    without billing for mileage?
                  </div>
                  <div className="miles-input">
                    <Form.Input
                      name="milesRange"
                      icon={<Icon>MILES</Icon>}
                      type="number"
                      min={0}
                      placeholder="..."
                      instantValidation={false}
                      className="form-input-miles"
                      required={true}
                      value={newUser.milesRange}
                      validationErrors={{
                        isDefaultRequiredValue: `* Miles is required field.`,
                      }}
                      errorLabel={errorLabel}
                    />
                  </div>
                </div>
                <Button ref={this.sumbitRef} type="submit" style={{ display: 'none' }} />
              </Form>
            </Grid.Column>
          </Responsive>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  newUser: state.account.register.newUser,
  progress: state.account.register.progress,
  step: state.account.register.step,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      changeRegisterStore: accountActions.changeRegisterStore,
      changeProgressValue: accountActions.changeProgressValue,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterSkills);
