import { push } from 'connected-react-router';
import store from '../store';

export const url = {
  homepage: '/',

  login: '/login',
  adminLogin: '/admin-login',
  register: '/create-free-account',

  standbyListQuickSignUp: '/standbylist/quick-sign-up/:publicId',

  phoneConfirmation: '/phone-confirmation',
  thankYou: '/thank-you',

  standby: '/stand-by-lists',
  // standby_questions: '/stand-by-lists/details',
  standby_questions: '/stand-by-lists/:id',

  training_center: '/training-center',

  resetPasswordPage: '/reset-password',

  emailVerification: '/account/adjuster/email-address-verify',

  profile: '/profile',

  terms_of_use: '/terms',
  privacy_policy: '/privacy-policy',
};

export const goto = (path: string) => {
  store.dispatch(push(path));
};
