import React from 'react';
import { Breadcrumb, Icon, Image, Menu, Responsive } from 'semantic-ui-react';
import dropdownIcon from '../../assets/images/btn-section-expand.svg';
import RegisterProgress from './RegisterProgress';

interface Props {
  step: number;
  progress: number;
}

interface State {
  activeItem: string;
  showSection: boolean;
}

class RegisterProfileHeader extends React.Component<Props, State> {
  public state = {
    activeItem: '',
    showSection: false,
  };

  public changeDotColor = () => {
    const dots = document.querySelectorAll('.register-profile-header-dots > .circle.icon');
    dots.forEach(item => {
      item.className = 'circle icon';
    });
    const dot = this.props.step - 3;
    dots.item(dot).className = 'circle icon progress-done';
  };

  public componentDidMount() {
    this.changeDotColor();
    this.checkActiveName();
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.step !== this.props.step) {
      this.changeDotColor();
      this.checkActiveName();
    }
  }

  public checkActiveName = () => {
    switch (this.props.step) {
      case 3:
        this.setState({ activeItem: 'Profile' });
        break;
      case 4:
        this.setState({ activeItem: 'Professional Skills' });
        break;
      case 5:
        this.setState({ activeItem: 'Licenses' });
        break;
      case 6:
        this.setState({ activeItem: 'Certifications' });
        break;
      case 7:
        this.setState({ activeItem: 'Resumes' });
        break;
    }
  };

  public render() {
    const { step } = this.props;
    const { activeItem } = this.state;
    return (
      <React.Fragment>
        <div className="register-profile-header">
          <div className="registration-header-content">
            <div>
              <h2>Registration</h2>
            </div>
            <div className="register-profile-header-dots">
              <Icon name="circle" />
              <Icon name="circle" />
              <Icon name="circle" />
              <Icon name="circle" />
              <Icon name="circle" />
            </div>
          </div>
          <RegisterProgress progress={this.props.progress} />
        </div>

        <Responsive maxWidth={768}>
          <div
            className="active-tabs-mobile-item"
            onClick={() => this.setState({ showSection: !this.state.showSection })}
          >
            {activeItem}
            <Image src={dropdownIcon} className={this.state.showSection ? 'rotated' : ''} />
          </div>

          <div
            className={
              this.state.showSection ? 'register-adjuster-tabs active' : 'register-adjuster-tabs'
            }
          >
            <Menu text={true} className="tabs-menu" stackable={true}>
              <Menu.Item name="Profile" active={step === 3}>
                Profile
              </Menu.Item>
              <Menu.Item name="Professional Skills" active={step === 4}>
                Professional Skills
              </Menu.Item>
              <Menu.Item name="Licenses" active={step === 5}>
                Licenses
              </Menu.Item>
              <Menu.Item name="Certifications" active={step === 6}>
                Certifications
              </Menu.Item>
              <Menu.Item name="Resumes" active={step === 7}>
                Resumes
              </Menu.Item>
            </Menu>
          </div>
        </Responsive>

        <Responsive minWidth={768}>
          <div className="register-adjuster-tabs">
            <Menu text={true} className="tabs-menu" stackable={true}>
              <Menu.Item name="Profile" active={step === 3}>
                Profile
              </Menu.Item>
              <Menu.Item name="Professional Skills" active={step === 4}>
                Professional Skills
              </Menu.Item>
              <Menu.Item name="Licenses" active={step === 5}>
                Licenses
              </Menu.Item>
              <Menu.Item name="Certifications" active={step === 6}>
                Certifications
              </Menu.Item>
              <Menu.Item name="Resumes" active={step === 7}>
                Resumes
              </Menu.Item>
            </Menu>
          </div>
        </Responsive>
      </React.Fragment>
    );
  }
}

export default RegisterProfileHeader;
