import { withFormsy } from 'formsy-react';
import React, { cloneElement } from 'react';
import InputMask from 'react-input-mask';

const PhoneInput = (props: any) => {
  const {
    isValid,
    isPristine,
    errorLabel,
    validationErrors,
    isFormSubmitted,
    getValue,
    setValue,
    value,
    readOnly,
    changeLabel,
    showLabel,
  } = props;
  const error = !isPristine() && !isValid() && isFormSubmitted();

  const handleChange = (e: any) => {
    // const val = parseInt(e.target.value.replace(/[^0-9]/g, ''), 10);
    // if (!isNaN(val)) {
    setValue(e.target.value);
    // } else {
    // setValue(undefined);
    // }
  };

  return (
    <div
      style={{ width: '100%', minWidth: '65%', position: 'relative' }}
      className="phone-input-custom-container"
    >
      <InputMask
        placeholder="Please Enter..."
        mask="(999) 999-9999"
        maskChar=""
        readOnly={readOnly}
        disabled={props.disabled}
        required={props.required}
        value={getValue()}
        className={`form-input ${props.className ? props.className : ''}`}
        onChange={handleChange}
      />
      {error && errorLabel && cloneElement(errorLabel, {}, validationErrors.isDefaultRequiredValue)}
      {showLabel && cloneElement(changeLabel)}
    </div>
  );
};

export default withFormsy(PhoneInput);
