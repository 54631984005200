import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { accountActions } from '../../features/account';

import '../../assets/styles/Login.css';
import services from '../../services';

interface Props {
  loginAttempt: typeof accountActions.adminLogin;
  error: string;
  isLoading: boolean;
}

interface State {}

class AdminLogin extends React.Component<Props, State> {
  public componentDidMount(): void {
    const stringArray = window.location.href.split('/');
    const token = stringArray[stringArray.length - 1];
    const tokenCall = services.localStorage.get('accessToken');
    if (tokenCall && tokenCall === token) {
      services.router.goto(services.router.url.homepage);
    } else {
      this.props.loginAttempt(token);
      location.reload();
    }
  }

  public render() {
    return <div className="login-page">-</div>;
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  error: state.account.login.error,
  isLoading: state.loading.loading.isVisible,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      loginAttempt: accountActions.adminLogin,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminLogin);
