import { ConnectedRouter } from 'connected-react-router';
import React, { Component } from 'react';
import { Provider, ReactReduxContext } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Slide, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/styles/Adjusters.css';
import './assets/styles/Dashboard.css';
import './assets/styles/DataTable.css';
import './assets/styles/Finances.css';
import './assets/styles/Layout.css';
import './assets/styles/Settings.css';
import './assets/styles/Sidebar.css';
import './assets/styles/Utils.css';
import ToasterCloseIcon from './components/system-toaster-message/ToasterCloseIcon';

import store, { history } from './store';

import { Layout } from './routes';
import Homepage from './routes/homepage/homepage';
import services from './services';

toast.configure({
  transition: Slide,
  hideProgressBar: true,
  autoClose: 5000,
  closeButton: <ToasterCloseIcon />,
});

declare global {
  interface Window {
    ENV: any;
  }
}

window.ENV = window.ENV || {};

class App extends Component {
  public componentWillMount() {
    this.ssoRedirect();
  }

  public ssoRedirect = () => {
    const params = new URLSearchParams(window.location.search as string);
    const redirect_url = params.get('redirect_url');
    const token = params.get('token');
    const accessToken = services.localStorage.get('accessToken');

    if (redirect_url && token && accessToken) {
      let url;

      if (redirect_url.includes('?')) {
          url = `${redirect_url}&token=${accessToken}`;
      } else {
          url = `${redirect_url}?token=${accessToken}`;
      }

      window.open(url, '_self');
    }
  }

  public render() {
    return (
      <Provider store={store} context={ReactReduxContext}>
        <ConnectedRouter history={history} context={ReactReduxContext}>
          <Switch>
            <Route exact={true} path="/" component={Homepage} />
            <Route exact={true} path={services.router.url.standby} component={Homepage} />
            <Route path={services.router.url.standby_questions} component={Homepage} />
            <Route path={services.router.url.training_center} component={Homepage} />
            <Route path={services.router.url.adminLogin} component={Layout} />
            <Route path={services.router.url.login} component={Layout} />
            <Route path={services.router.url.phoneConfirmation} component={Layout} />
            <Route path={services.router.url.thankYou} component={Layout} />
            <Route path={services.router.url.register} component={Layout} />
            <Route path={services.router.url.resetPasswordPage} component={Layout} />
            <Route path={services.router.url.emailVerification} component={Layout} />
            <Route path={services.router.url.standbyListQuickSignUp} component={Layout} />
            <Route render={() => <div>Miss</div>} />
          </Switch>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
