import { Form } from 'formsy-semantic-ui-react';
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Grid } from 'semantic-ui-react';
import { accountActions } from '../../features/account';
import { NewUser } from '../../models/newUser';
import services from '../../services';
import * as datesHelper from '../../utils/datesHelper';
import AddLicenseModal from './../register-layout/AddLicenseModal';
import EditLicenseModal from './../register-layout/EditLicenseModal';
import LicenseItem from './../register-layout/LicenseItem';

interface Props {
  getSubmitRef: (ref: any) => void;
  changeRegisterStore: typeof accountActions.changeRegisterStore;
  changeProgressValue: typeof accountActions.changeProgressValue;
  newUser: NewUser;
  progress: number;
}

interface State {
  certifications: object[];
  licenseId: number | undefined;
  showEditModal: boolean;
}

class RegisterCertifications extends React.Component<Props, State> {
  public state = {
    certifications: [],
    licenseId: 0,
    showEditModal: false,
  };

  public sumbitRef = React.createRef();

  public downloadLocalFile = (file: any) => {
    services.api.file.downloadLocalFile(file);
  };

  public getLicense = (item: object, id?: number) => {
    const { certifications } = this.state;

    item.issuedDate = datesHelper.parseDateToDateStringWithFormat(item.issuedDate);
    item.expirationDate = datesHelper.parseDateToDateStringWithFormat(item.expirationDate);

    if (id === undefined) {
      certifications.push(item);
    } else {
      const index = certifications.findIndex((el: any) => el.id === id);
      certifications[index] = item;
    }
    this.setState({ certifications });
  };

  public editLicenseData = (id: number, item: object) => {
    const certifications = [...this.state.certifications];
    const index = certifications.findIndex(el => el.id === id);
    certifications[index] = { ...item };
    this.setState({ certifications, showEditModal: false });
  };

  public deleteLicense = (id: number) => {
    const { certifications } = this.state;
    const index = certifications.findIndex((el: any) => el.id === id);
    certifications.splice(index, 1);
    this.setState({ certifications });
  };

  public editLicense = (show: boolean, id?: number) => {
    if (id !== null) {
      this.setState({ licenseId: id });
    }
    this.setState({ showEditModal: show });
  };

  public componentDidMount() {
    this.props.getSubmitRef(this.sumbitRef);
    this.setState({
      certifications: this.props.newUser.certifications,
    });
  }

  public render() {
    return (
      <Grid stackable={true} relaxed={true} style={{ width: '100%' }} className="licenses-grid">
        <Grid.Row columns={1} only="computer">
          <Grid.Column className="licenses-column">
            <Form
              onValidSubmit={(model: any) => {
                const { certifications } = this.state;
                const certificationsBuff = certifications.map((el: any) => {
                  delete el.id;
                  return el;
                });
                certifications.length === 0
                  ? this.props.changeProgressValue(this.props.progress)
                  : this.props.changeProgressValue(this.props.progress + 10);
                this.props.changeRegisterStore({ certifications: certificationsBuff });
              }}
            >
              {this.state.certifications.length === 0 ? (
                <Form.Input
                  name="certifications"
                  placeholder="Nothing Added"
                  instantValidation={false}
                  className="form-input readonly-input"
                  readOnly={true}
                />
              ) : (
                <div>
                  {this.state.certifications.map((el: any, index: number) => (
                    <LicenseItem
                      item={el}
                      key={index}
                      id={index}
                      editLicense={this.editLicense}
                      deleteLicense={this.deleteLicense}
                      mode="cert"
                      download={this.downloadLocalFile}
                    />
                  ))}
                </div>
              )}
              <Form.Input
                name="license_file"
                hidden={true}
                style={{ display: 'none' }}
                readOnly={true}
              />
              <Button ref={this.sumbitRef} type="submit" style={{ display: 'none' }} />
            </Form>
            <div style={{ width: '100%' }}>
              <AddLicenseModal getLicense={this.getLicense} mode="cert" />
            </div>
            <EditLicenseModal
              show={this.state.showEditModal}
              showModal={this.editLicense}
              editLicense={this.editLicenseData}
              item={this.state.certifications[this.state.licenseId]}
              mode="cert"
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={16} only="tablet mobile">
          <Grid.Column className="licenses-column">
            <Form
              onValidSubmit={(model: any) => {
                const { certifications, isReturned } = this.state;
                const certificationsBuff = certifications.map((el: any) => {
                  delete el.id;
                  return el;
                });
                certifications.length === 0
                  ? this.props.changeProgressValue(this.props.progress)
                  : this.props.changeProgressValue(this.props.progress + 10);
                this.props.changeRegisterStore({ certifications: certificationsBuff });
              }}
            >
              {this.state.certifications.length === 0 ? (
                <Form.Input
                  name="certifications"
                  placeholder="Nothing Added"
                  instantValidation={false}
                  className="form-input readonly-input"
                  readOnly={true}
                />
              ) : (
                <div>
                  {this.state.certifications.map((el: any, index: number) => (
                    <LicenseItem
                      item={el}
                      key={index}
                      id={index}
                      editLicense={this.editLicense}
                      deleteLicense={this.deleteLicense}
                      mode="cert"
                      download={this.downloadLocalFile}
                    />
                  ))}
                </div>
              )}
              <Form.Input
                name="license_file"
                hidden={true}
                style={{ display: 'none' }}
                readOnly={true}
              />
              <Button ref={this.sumbitRef} type="submit" style={{ display: 'none' }} />
            </Form>
            <div style={{ width: '100%' }}>
              <AddLicenseModal getLicense={this.getLicense} mode="cert" />
            </div>
            <EditLicenseModal
              show={this.state.showEditModal}
              showModal={this.editLicense}
              editLicense={this.editLicenseData}
              item={this.state.certifications[this.state.licenseId]}
              mode="cert"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  newUser: state.account.register.newUser,
  progress: state.account.register.progress,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      changeRegisterStore: accountActions.changeRegisterStore,
      changeProgressValue: accountActions.changeProgressValue,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterCertifications);
