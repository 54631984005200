import { Form } from 'formsy-semantic-ui-react';
import React from 'react';
import { Button, Grid, Header, Image } from 'semantic-ui-react';
import fbIcon from '../../assets/images//icn-f.svg';
import gIcon from '../../assets/images//icn-g.svg';
import inIcon from '../../assets/images//icn-in.svg';
import compassLogo from '../../assets/images/compass-logo-vector.svg';
import '../../assets/styles/Login.css';
import { api } from '../../config';
import ErrorLabel from '../error-label/ErrorLabel';

interface Props {
  isLoading: boolean;
  onSubmit: (model: object) => void;
  previousStep: () => void;
  socialRegister: (email: string) => void;
  errors: string[];
}

class RegisterForm extends React.Component<Props> {
  public form: any;

  public onValidSubmit = (model: any) => {
    const howDidYouHearAboutUs =
      model.howDidYouHearAboutUsOther || model.howDidYouHearAboutUs || '';
    const obj = {
      ...model,
      howDidYouHearAboutUs,
    };
    this.props.onSubmit(obj);
  };

  public handleSocial = (id: string) => {
    switch (id) {
      case 'google':
        window.location.href = `${api.endpoint}account/google-login`;
        break;
      case 'linkedIn':
        window.location.href = `${api.endpoint}account/linkedin-login`;
        break;
      case 'facebook':
        window.location.href = `${api.endpoint}account/facebook-login`;
        break;
    }
  };

  public componentDidMount() {
    if (window.location.search.includes('?email=')) {
      const socialEmail = window.location.search.slice(7);
      if (socialEmail !== 'undefined') {
        this.props.socialRegister(socialEmail);
      }
    }
  }

  public render() {
    const errorLabel = <ErrorLabel />;
    const { errors } = this.props;
    return (
      <div>
        <div className="logo-container">
          <Image src={compassLogo} />
          <h3>
            Create your account to start your work with Compass Adjusting Services. All information
            is handled as confidential by Compass Adjusting Services, Inc.
          </h3>
        </div>
        <Grid
          centered={true}
          style={{ height: '100%' }}
          verticalAlign="middle"
          className="register-email-grid"
        >
          <Grid.Column style={{ maxWidth: 300 }}>
            <div className="login-form-container">
              <Header
                as="h5"
                icon={true}
                textAlign="center"
                style={{ marginTop: '50px', fontWeight: '800', fontSize: '32px' }}
              >
                <Header.Content>Sign Up with</Header.Content>
              </Header>

              <div className="social-login-block">
                <Button
                  className="social-button fb-button"
                  onClick={() => this.handleSocial('facebook')}
                >
                  <Image src={fbIcon} />
                </Button>
                <Button
                  className="social-button g-button"
                  onClick={() => this.handleSocial('google')}
                >
                  <Image src={gIcon} />
                </Button>
                <Button
                  className="social-button in-button"
                  onClick={() => this.handleSocial('linkedIn')}
                >
                  <Image src={inIcon} />
                </Button>
              </div>

              <Header as="h4" icon={true} textAlign="center" style={{ color: '#535D6C' }}>
                <Header.Content>Or use your email</Header.Content>
              </Header>

              <Form
                onValidSubmit={this.onValidSubmit}
                ref={(ref: any) => (this.form = ref)}
                className="login-form"
              >
                <Form.Input
                  name="email"
                  autoCorrect="off"
                  autoCapitalize="none"
                  placeholder="Email"
                  instantValidation={false}
                  required={true}
                  validations="isEmail"
                  validationErrors={{
                    isDefaultRequiredValue: '* Email is required field.',
                    isEmail: '* Email format is invalid.',
                  }}
                  errorLabel={errorLabel}
                />

                <Button content="Next" primary={true} />
              </Form>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default RegisterForm;
