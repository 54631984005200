import * as Formsy from 'formsy-react';
import { Form, RadioGroup } from 'formsy-semantic-ui-react';
import moment from 'moment';
import React from 'react';
import { Button, Grid, Image, Popup, Radio } from 'semantic-ui-react';
import calendarIcon from '../../assets/images/Blue/ui-calendar.svg';
import infoIcon from '../../assets/images/Common/icn-info.svg';
import { NewUser } from '../../models/newUser';
import services from '../../services';
import fields from '../../utils/RegisterDetailsFields';
import statesUS from '../../utils/statesUS';
import CheckboxGroup from '../checkbox-group/CheckboxGroup';
import ErrorLabel from '../error-label/ErrorLabel';
import PhoneInput from '../layout-input/PhoneInput';
import DatePickerInput from './../datepicker-input/DatePickerInput';
import AddEducation from './../register-layout/AddEducation';
import AddReference from './../register-layout/AddReference';
import EducationItem from './../register-layout/EducationItem';
import ReferenceItem from './../register-layout/ReferenceItem';

interface Props {
  newUser: NewUser;
  getSubmitRef: (ref: any) => void;
  changeRegisterStore: (model: object) => void;
  changeProgressValue: (value: number) => void;
  avatar: any;
  progress: any;
}

interface State {
  positions: object[];
  isChecked: boolean;
  showOtherField: boolean;
  education: object[];
  references: object[];
  showCompanyName: boolean;
  isReturned: boolean;
  temporaryLocation: boolean;
}

const howDidYouHearOpt = [
  { text: 'Facebook', value: 'Facebook' },
  { text: 'Lindkedin', value: 'Lindkedin' },
  { text: 'Twitter', value: 'Twitter' },
  { text: 'Instagram', value: 'Instagram' },
  { text: 'Friend of College', value: 'Friend of College' },
  { text: 'Internet Search', value: 'Internet Search' },
  { text: 'Other', value: 'Other' },
];

Formsy.addValidationRule('isValidMax', (values: any, value: any, maxDate: any) => {
  if (moment(value) > maxDate) {
    return false;
  } else {
    return true;
  }
});

Formsy.addValidationRule('isValidMin', (values: any, value: any, minDate: any) => {
  if (moment(value) < minDate) {
    return false;
  } else {
    return true;
  }
});

Formsy.addValidationRule('isValidDate', (values: any, value: any, field?: string) => {
  if (value) {
    if (field === 'cat' && value.length === 0) {
      return true;
    }
    if (value.length < 10) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
});

Formsy.addValidationRule('isChecked', (values: any, value: any) => {
  return value !== undefined && value !== null && value !== '';
});

class RegisterDetailsForm extends React.Component<Props, State> {
  public state = {
    isChecked: false,
    showOtherField: false,
    positions: [],
    education: [],
    references: [],
    showCompanyName: false,
    isReturned: false,
    temporaryLocation: false,
  };

  public sumbitRef = React.createRef();

  public componentDidMount = async () => {
    this.props.getSubmitRef(this.sumbitRef);
    const response = await services.api.account.getSettingsData('position');
    if (response.isSuccess) {
      const positions = response.data.list.map((el: any) => ({
        label: el.name,
        checked: this.props.newUser.positions.includes(el.name),
      }));
      this.setState({ positions });
    } else {
      console.log(response);
    }

    this.setState({ temporaryLocation: !!this.props.newUser.catCity });

    if (
      this.props.newUser.howDidYouHearAboutUs !== '' &&
      howDidYouHearOpt.filter(el => el.text === this.props.newUser.howDidYouHearAboutUs).length ===
        0
    ) {
      this.setState({ showOtherField: true });
    }

    if (this.props.newUser.registrationType === 'business') {
      this.setState({ showCompanyName: true });
    } else {
      this.setState({ showCompanyName: false });
    }

    this.setState(
      {
        education: this.props.newUser.educations,
        references: this.props.newUser.references,
        isReturned: this.props.newUser.positions.length !== 0,
      },
      () => {
        if (this.state.isReturned) {
          this.props.changeProgressValue(5);
        }
      }
    );
  };

  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.temporaryLocation && !this.state.temporaryLocation) {
      this.props.newUser.catZip = '';
      this.props.newUser.catStateProvince = '';
      this.props.newUser.catCity = '';
      this.props.newUser.catExpirationDate = '';
    }
    if (prevState.positions !== this.state.positions) {
      const hasChecked = this.state.positions.filter(el => el.checked === true);
      if (hasChecked.length !== 0) {
        this.setState({ isChecked: true });
      } else {
        this.setState({ isChecked: false });
      }
    }
  }

  public getEducation = (item: object) => {
    const { education } = this.state;
    education.push(item);
    this.setState({ education });
  };

  public editEducation = (id: number, item: object) => {
    const { education } = this.state;
    education[id] = item;
    this.setState({ education });
  };

  public deleteEducation = (id: number) => {
    const { education } = this.state;
    education.splice(id, 1);
    this.setState({ education });
  };

  public getReference = (item: object) => {
    const { references } = this.state;
    references.push(item);
    this.setState({ references });
  };

  public editReference = (id: number, item: object) => {
    const { references } = this.state;
    references[id] = item;
    this.setState({ references });
  };

  public deleteReference = (id: number) => {
    const { references } = this.state;
    references.splice(id, 1);
    this.setState({ references });
  };

  public handleTemporaryLocationRadio = (e: any, { value }: any) => {
    this.setState({ temporaryLocation: value === 'true' });
  };

  public changeCheckbox = (event: any) => {
    const positionsBuffArray = this.state.positions.map(el => {
      if (el.label === event.currentTarget.childNodes[0].name) {
        el.checked = !event.currentTarget.childNodes[0].checked;
      }
      return el;
    });
    this.setState({ positions: positionsBuffArray });
  };

  public handleSourceDropdown = (e: any, { value }: any) => {
    if (value === 'Other') {
      this.setState({ showOtherField: true });
    } else {
      this.setState({ showOtherField: false });
    }
  };

  public handleTypeDropdown = (e: any, { value }: any) => {
    if (value === 'business') {
      this.setState({ showCompanyName: true });
    } else {
      this.setState({ showCompanyName: false });
    }
  };

  public render() {
    const { newUser } = this.props;
    const errorLabel = <ErrorLabel />;
    const catExpirationValue = newUser.catExpirationDate ? newUser.catExpirationDate : '';
    return (
      <Form
        ref={ref => (this.form = ref)}
        style={{ width: '100%' }}
        onValidSubmit={async (model: object) => {
          const { references, education } = this.state;
          const howDidYouHearAboutUs =
            model.howDidYouHearAboutUsOther || model.howDidYouHearAboutUs;
          const obj = Object.assign(
            {},
            {
              ...model,
              howDidYouHearAboutUs,
            },
            { references },
            { avatar: this.props.avatar },
            { educations: education }
          );
          let initialScore = 16.25;
          if (obj.references.length !== 0) {
            initialScore = initialScore + 1.25;
          }
          if (obj.educations.length !== 0) {
            initialScore = initialScore + 1.25;
          }
          if (obj.xactnetAddress !== '' && obj.xactnetAddress !== undefined) {
            initialScore = initialScore + 1.25;
          }
          if (this.props.avatar.file) {
            initialScore = initialScore + 5;
          }
          this.props.changeProgressValue(Math.round(initialScore));
          this.props.changeRegisterStore(obj);
        }}
      >
        <Grid stackable={true} className="details-grid">
          <Grid.Row columns="equal">
            <Grid.Column>
              <div className="input-field-container">
                <div className={'input-field-label'}>Registration Type</div>
                <div className="registration-type-dropdown">
                  <RadioGroup
                    name="registrationType"
                    required={true}
                    onChange={this.handleTypeDropdown}
                    className="registration-type-group"
                    value={newUser.registrationType}
                    validationErrors={{
                      isDefaultRequiredValue: `* Registration Type is required field.`,
                    }}
                    errorLabel={errorLabel}
                  >
                    <Radio label="Individual" value="individual" />
                    <Radio label="Business" value="business" />
                  </RadioGroup>
                </div>
              </div>
              {this.state.showCompanyName && (
                <div className="input-field-container">
                  <div className={'input-field-label registration-type-label'}>
                    Company Name
                    <Popup
                      trigger={<Image src={infoIcon} />}
                      size="mini"
                      basic={true}
                      content="We pay your company (LLC, sole proprietorship, partnership, limited liability partnership, corporation, etc) instead of compensating you directly."
                    />
                  </div>
                  <Form.Input
                    name="companyName"
                    type={'text'}
                    value={newUser.companyName}
                    placeholder="Please Enter..."
                    instantValidation={false}
                    required={true}
                    validationErrors={{
                      isDefaultRequiredValue: `* Company Name is required field.`,
                    }}
                    errorLabel={errorLabel}
                    className={'form-input'}
                  />
                </div>
              )}
              {fields.map(field => {
                if (field.name === 'positions') {
                  return (
                    <div className="input-field-container" key={field.name}>
                      <div
                        className={
                          field.required ? 'input-field-label' : 'input-field-label disabled'
                        }
                      >
                        {field.label}
                      </div>
                      <CheckboxGroup
                        name="positions"
                        items={this.state.positions}
                        validations={{
                          isChecked: true,
                        }}
                        value=""
                        validationErrors={{
                          isChecked: '* Positions is required field.',
                        }}
                        errorLabel={errorLabel}
                      />
                    </div>
                  );
                } else if (field.name === 'phone' || field.name === 'fax') {
                  return (
                    <div
                      className="input-field-container phone-field-custom-input"
                      key={field.name}
                    >
                      <div
                        className={
                          field.required ? 'input-field-label' : 'input-field-label disabled'
                        }
                      >
                        {field.label}
                      </div>
                      <PhoneInput
                        name={field.name}
                        instantValidation={false}
                        value={newUser[field.name]}
                        required={field.required}
                        disabled={field.disabled}
                        validations={field.validations}
                        validationErrors={{
                          isDefaultRequiredValue: `* ${field.label} is required props.`,
                          ...field.validationErrors,
                        }}
                        errorLabel={errorLabel}
                      />
                    </div>
                  );
                } else if (field.type === 'date') {
                  return (
                    <div className="input-field-container" key={field.name}>
                      <div
                        className={
                          field.required ? 'input-field-label' : 'input-field-label disabled'
                        }
                      >
                        {field.label}
                      </div>
                      <DatePickerInput
                        name={field.name}
                        required={true}
                        validations={{
                          isValidMax: moment(),
                          isValidDate: true,
                        }}
                        validationErrors={{
                          isDefaultRequiredValue: `* ${field.label} is required field.`,
                          isValidMax: `* Date should not be later than current date.`,
                          isValidDate: `* Date format is invalid.`,
                        }}
                        value={newUser[field.name] === 0 ? '' : newUser[field.name]}
                        errorLabel={errorLabel}
                      />
                    </div>
                  );
                } else if (field.type === 'dropdown') {
                  return (
                    <div className="input-field-container" key={field.name}>
                      <div className="input-field-label">{field.label}</div>
                      <div className="form-input dropdown">
                        <Form.Dropdown
                          search={true}
                          name={field.name}
                          options={field.options}
                          placeholder="Please Select"
                          instantValidation={false}
                          required={true}
                          validationErrors={{
                            isDefaultRequiredValue: `* ${field.label} is required field.`,
                          }}
                          className="form-input-short form-input-dropdown"
                          errorLabel={errorLabel}
                        />
                      </div>
                    </div>
                  )
                } else {
                  return (
                    <div className="input-field-container" key={field.name}>
                      <div
                        className={
                          field.required ? 'input-field-label' : 'input-field-label disabled'
                        }
                      >
                        {field.label}
                      </div>
                      <Form.Input
                        name={field.name}
                        type={field.type}
                        icon={
                          field.type === 'date' ? (
                            <i className={`icon date-icon`}>
                              <Image src={calendarIcon} />
                            </i>
                          ) : null
                        }
                        value={newUser[field.name]}
                        placeholder="Please Enter..."
                        instantValidation={false}
                        required={field.required}
                        disabled={field.disabled}
                        validations={field.validations}
                        validationErrors={{
                          isDefaultRequiredValue: `* ${field.label} is required field.`,
                          ...field.validationErrors,
                        }}
                        errorLabel={errorLabel}
                        onKeyDown={e => {
                          if (field.name === 'phone' || field.name === 'fax') {
                            const test = /^[+()\d]+$/.test(e.nativeEvent.key);
                            if (
                              !test &&
                              e.nativeEvent.which !== 46 &&
                              e.nativeEvent.which !== 18 &&
                              e.nativeEvent.which !== 8 &&
                              e.nativeEvent.which !== 9 &&
                              e.nativeEvent.which !== 16 &&
                              e.nativeEvent.which !== 36 &&
                              e.nativeEvent.which !== 37 &&
                              e.nativeEvent.which !== 39 &&
                              e.nativeEvent.which !== 35
                            ) {
                              e.preventDefault();
                            }
                          }
                        }}
                        className={field.className}
                      />
                    </div>
                  );
                }
              })}
              <div className="input-field-container">
                <div className="input-field-label">State/Province</div>
                <div className="zip-form-input">
                  <Form.Dropdown
                    search={true}
                    name="state"
                    selectOnBlur={false}
                    options={statesUS}
                    placeholder="Please Select"
                    instantValidation={false}
                    required={true}
                    value={newUser.state}
                    validationErrors={{
                      isDefaultRequiredValue: `* State is required field.`,
                    }}
                    className="form-input-short form-input-dropdown state-province-dropdown"
                    errorLabel={errorLabel}
                  />
                  <div style={{ display: 'flex' }} className="zip-code-input">
                    <div style={{ paddingTop: '8px', paddingRight: '5px' }}>ZIP</div>
                    <Form.Input
                      name="zip"
                      placeholder="Enter..."
                      instantValidation={false}
                      required={true}
                      value={newUser.zip}
                      validationErrors={{
                        isDefaultRequiredValue: `* Zip is required field.`,
                      }}
                      errorLabel={errorLabel}
                      className="form-input-short"
                    />
                  </div>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="input-field-container">
                <div className="input-field-label disabled">XACTNET Address</div>
                <Form.Input
                  name="xactnetAddress"
                  value={newUser.xactnetAddress}
                  placeholder="Please Enter..."
                  instantValidation={false}
                  className="form-input"
                />
              </div>

              <div className="input-field-container">
                <div className="input-field-label" />
                <div className="remark-label xactnet">
                  We highly recommend that you provide your XACTNET Address.{' '}
                </div>
              </div>

              <div className="input-field-container">
                <div className="input-field-label" />
                <div>
                  <br />
                </div>
              </div>
              <div className="input-field-container">
                <div className="remark-label">
                  CAT Address represents your temporary location. Do not populate it if it is same
                  as your residence address.
                </div>
                <div />
              </div>
              <div className="input-field-container temporary-location">
                <div className="input-field-label">
                  Do you have a CAT Address (Temporary Location)?
                </div>
                <RadioGroup
                  name="temporaryLocation"
                  required={true}
                  onChange={this.handleTemporaryLocationRadio}
                  className="registration-type-group"
                  value={newUser.temporaryLocation ? newUser.temporaryLocation.toString() : 'false'}
                  validationErrors={{
                    isDefaultRequiredValue: `* Temporary Location is required field.`,
                  }}
                  errorLabel={errorLabel}
                >
                  <Radio label="Yes" value={'true'} />
                  <Radio label="No" value={'false'} />
                </RadioGroup>
              </div>
              {this.state.temporaryLocation && (
                <>
                  <div className="input-field-container">
                    <div className="input-field-label">CAT Address City</div>
                    <Form.Input
                      name="catCity"
                      value={newUser.catCity}
                      required={true}
                      placeholder="Please Enter..."
                      instantValidation={false}
                      className="form-input"
                      validationErrors={{
                        isDefaultRequiredValue: `* Cat City is required field.`,
                      }}
                      errorLabel={errorLabel}
                    />
                  </div>
                  <div className="input-field-container">
                    <div className="input-field-label">CAT State/Province</div>
                    <Form.Dropdown
                      selectOnBlur={false}
                      search={true}
                      name="catStateProvince"
                      value={newUser.catStateProvince}
                      placeholder="Please Select"
                      options={[{ text: '', value: '' }, ...statesUS]}
                      instantValidation={false}
                      className="form-input form-input-dropdown long"
                      required={true}
                      validationErrors={{
                        isDefaultRequiredValue: `* CAT State/Province is required field.`,
                      }}
                      errorLabel={errorLabel}
                    />
                  </div>
                  <div className="input-field-container">
                    <div className="input-field-label">CAT Zip</div>
                    <Form.Input
                      name="catZip"
                      value={newUser.catZip}
                      placeholder="Please Enter..."
                      instantValidation={false}
                      className="form-input"
                      required={true}
                      validationErrors={{
                        isDefaultRequiredValue: `* Cat Zip is required field.`,
                      }}
                      errorLabel={errorLabel}
                    />
                  </div>
                  <div className="input-field-container">
                    <div className="input-field-label">CAT Expiration Date</div>
                    <DatePickerInput
                      name="catExpirationDate"
                      required={true}
                      instantValidation={false}
                      validations={{ isValidMin: moment(), isValidDate: 'cat' }}
                      validationErrors={{
                        isValidMin: `* Date should not be earlier than current date.`,
                        isValidDate: `* Date format is invalid.`,
                        isDefaultRequiredValue: `* Cat Expiration Date is required field.`,
                      }}
                      value={catExpirationValue}
                      errorLabel={errorLabel}
                    />
                  </div>
                </>
              )}

              {this.state.education.length === 0 ? (
                <div className="input-field-container">
                  <div className="input-field-label">Education</div>

                  <Form.Input
                    name="education"
                    type="text"
                    placeholder="Nothing Added"
                    instantValidation={false}
                    className="form-input readonly-input"
                    readOnly={true}
                  />
                </div>
              ) : (
                this.state.education.map((el, index) => (
                  <div className="input-field-container" key={index}>
                    <div className="input-field-label">{index === 0 ? 'Education' : null}</div>
                    <EducationItem
                      delete={this.deleteEducation}
                      edit={this.editEducation}
                      item={el}
                      id={index}
                    />
                  </div>
                ))
              )}

              <div className="input-field-container">
                <div className="input-field-label" />
                <AddEducation getEducation={this.getEducation} />
              </div>

              {this.state.references.length === 0 ? (
                <div className="input-field-container">
                  <div className="input-field-label">References</div>

                  <Form.Input
                    name="references"
                    type="text"
                    placeholder="Nothing Added"
                    instantValidation={false}
                    className="form-input readonly-input"
                    readOnly={true}
                  />
                </div>
              ) : (
                this.state.references.map((el, index) => (
                  <div className="input-field-container" key={index}>
                    <div className="input-field-label">{index === 0 ? 'References' : null}</div>
                    <ReferenceItem
                      edit={this.editReference}
                      delete={this.deleteReference}
                      item={el}
                      id={index}
                    />
                  </div>
                ))
              )}

              <div className="input-field-container">
                <div className="input-field-label" />
                <AddReference getReference={this.getReference} />
              </div>

              <div className="input-field-container">
                <div className="input-field-label">How did you hear about us?</div>
                <Form.Dropdown
                  search={true}
                  selectOnBlur={false}
                  name="howDidYouHearAboutUs"
                  placeholder="Please Select"
                  instantValidation={false}
                  options={howDidYouHearOpt}
                  value={
                    newUser.howDidYouHearAboutUs === ''
                      ? undefined
                      : howDidYouHearOpt.filter(el => el.text === newUser.howDidYouHearAboutUs)
                          .length === 0
                      ? 'Other'
                      : newUser.howDidYouHearAboutUs
                  }
                  className="form-input form-input-dropdown long"
                  required={!this.state.showOtherField}
                  onChange={this.handleSourceDropdown}
                  validationErrors={{
                    isDefaultRequiredValue: `* This question is required field.`,
                  }}
                  errorLabel={errorLabel}
                />
              </div>
              {this.state.showOtherField && (
                <div className="input-field-container">
                  <div className="input-field-label">Other source</div>
                  <Form.Input
                    name="howDidYouHearAboutUsOther"
                    value={newUser.howDidYouHearAboutUsOther}
                    placeholder="Please Enter..."
                    instantValidation={false}
                    className="form-input"
                    required={true}
                    validationErrors={{
                      isDefaultRequiredValue: `* How did you hear about us is required field.`,
                    }}
                    errorLabel={errorLabel}
                  />
                </div>
              )}
              <Button
                ref={this.sumbitRef}
                type="submit"
                formNoValidate={true}
                style={{ display: 'none' }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

export default RegisterDetailsForm;
