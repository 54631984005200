import React from 'react';
import { Icon } from 'semantic-ui-react';

export const NoResults = (props: any) => {
  return (
    <div className="no-results-container">
      <div>
        <Icon name="ban" /> No Results
      </div>
    </div>
  );
};
