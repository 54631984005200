import { trackPromise } from 'react-promise-tracker';
import { request } from './base';

export const getFolderList = (id: number) => {
  return trackPromise(
    request({
      method: 'get',
      url: `/adjuster/${id}/folders`,
    }),
    'folders'
  );
};
