import { Form } from 'formsy-semantic-ui-react';
import MyTypes from 'MyTypes';
import React from 'react';
import { trackPromise } from 'react-promise-tracker';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Grid } from 'semantic-ui-react';
import { accountActions } from '../../features/account';
import services from '../../services';
import { LoadingSpiner } from './../loading-spinner/LoadingSpinner';
import AddResumeModal from './../register-layout/AddResumeModal';
import EditResumeModal from './../register-layout/EditResumeModal';
import ResumeItem from './../register-layout/ResumeItem';

interface Props {
  checkAuthentication: typeof accountActions.checkAuthentication;
  getSubmitRef: (ref: any) => void;
  user: IUser;
  download: (file: any) => void;
}

interface State {
  resumes: object[];
  resumeId: number | undefined;
  showEditModal: boolean;
}

class ProfileResumes extends React.Component<Props, State> {
  public state = {
    resumes: [],
    resumeId: 0,
    showEditModal: false,
  };

  public sumbitRef = React.createRef();

  public getResume = async (item: object, id?: number) => {
    const resp = await trackPromise(
      services.api.account.getSignedUrl({
        name: item.file.fileName,
        type: item.file.fileType,
        size: item.file.fileSize,
        public: true,
      }),
      'resume-area'
    );

    if (resp.isSuccess) {
      const data = resp.data.url.fields;
      const obj = Object.assign({}, data, {
        file: item.file.fileInitial,
      });
      const formData = new FormData();
      for (const key in obj) {
        if (undefined === obj[key]) {
          continue;
        }
        formData.append(key, obj[key]);
      }
      const postFile = await services.api.file.postFile(resp.data.url.url, formData);

      if (postFile.status === 204) {
        await trackPromise(
          services.api.user.addResume({
            name: item.name,
            fileObjectKey: resp.data.url.fields.key,
          }),
          'resume-area'
        );
      }
    }

    await this.listResumes();
  };

  public deleteResume = async (id: number) => {
    await services.api.user.removeResume(id);
    const { resumes } = this.state;
    const index = resumes.findIndex((el: any) => el.id === id);
    resumes.splice(index, 1);
    this.setState({ resumes }, this.listResumes);
  };

  public editResume = async (id: any, item: any) => {
    const { resumes } = this.state;

    if (item.file.fileKey) {
      const updateResume = await trackPromise(
        services.api.user.updateResume(id, {
          name: item.name,
          fileObjectKey: item.file.fileKey,
        }),
        'resume-area'
      );
      if (updateResume.isSuccess) {
        this.showEditResume(false);
      }
    } else {
      const resp = await trackPromise(
        services.api.account.getSignedUrl({
          name: item.file.fileName,
          type: item.file.fileType,
          size: item.file.fileSize,
          public: true,
        }),
        'resume-area'
      );

      if (resp.isSuccess) {
        const data = resp.data.url.fields;
        const obj = Object.assign({}, data, {
          file: item.file.fileInitial,
        });
        const formData = new FormData();
        for (const key in obj) {
          if (undefined === obj[key]) {
            continue;
          }
          formData.append(key, obj[key]);
        }
        const postFile = await trackPromise(
          services.api.file.postFile(resp.data.url.url, formData),
          'resume-area'
        );

        if (postFile.status === 204) {
          const updateResume = await services.api.user.updateResume(id, {
            name: item.name,
            fileObjectKey: resp.data.url.fields.key,
          });
          if (updateResume.isSuccess) {
            this.showEditResume(false);
          }
        }
      }
    }
    this.listResumes();
  };

  public showEditResume = (show: boolean, id?: any) => {
    id !== null
      ? this.setState({ showEditModal: show, resumeId: id })
      : this.setState({ showEditModal: show });
  };

  public componentDidMount = () => {
    this.listResumes();
  };

  public listResumes = async () => {
    this.props.checkAuthentication();
    const response = await trackPromise(services.api.user.listUserResumes(), 'resume-area');

    if (response.isSuccess) {
      this.setState({
        resumes: response.data,
      });
    } else {
      console.log(response);
    }
  };

  public render() {
    return (
      <>
        <Grid stackable={true} style={{ width: '100%' }} className="licenses-grid">
          <LoadingSpiner area="resume-area" />
          <Grid.Row columns={1} only="computer">
            <Grid.Column className="licenses-column">
              <Form>
                {this.state.resumes.length === 0 ? (
                  <Form.Input
                    name="resumes"
                    placeholder="Nothing Added"
                    instantValidation={false}
                    className="form-input readonly-input"
                    readOnly={true}
                  />
                ) : (
                  <div>
                    {this.state.resumes.map((el: any, index: number) => (
                      <ResumeItem
                        item={el}
                        key={index}
                        id={index}
                        editResume={this.showEditResume}
                        deleteResume={this.deleteResume}
                        download={this.props.download}
                      />
                    ))}
                  </div>
                )}
                <Form.Input
                  name="resume_file"
                  hidden={true}
                  style={{ display: 'none' }}
                  readOnly={true}
                />
                <Button ref={this.sumbitRef} type="submit" style={{ display: 'none' }} />
              </Form>
              <div style={{ width: '100%' }}>
                <AddResumeModal getResume={this.getResume} />
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={16} only="tablet mobile">
            <Grid.Column className="licenses-column">
              <Form>
                {this.state.resumes.length === 0 ? (
                  <Form.Input
                    name="resumes"
                    placeholder="Nothing Added"
                    instantValidation={false}
                    className="form-input readonly-input"
                    readOnly={true}
                  />
                ) : (
                  <div>
                    {this.state.resumes.map((el: any, index: number) => (
                      <ResumeItem
                        item={el}
                        key={index}
                        id={index}
                        editResume={this.showEditResume}
                        deleteResume={this.deleteResume}
                        download={this.props.download}
                      />
                    ))}
                  </div>
                )}
                <Form.Input
                  name="resume_file"
                  hidden={true}
                  style={{ display: 'none' }}
                  readOnly={true}
                />
                <Button ref={this.sumbitRef} type="submit" style={{ display: 'none' }} />
              </Form>
              <div style={{ width: '100%' }}>
                <AddResumeModal getResume={this.getResume} />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <EditResumeModal
          show={this.state.showEditModal}
          editResume={this.editResume}
          showModal={this.showEditResume}
          item={this.state.resumes[this.state.resumeId]}
        />
      </>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      checkAuthentication: accountActions.checkAuthentication,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileResumes);
