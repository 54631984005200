import { withFormsy } from 'formsy-react';
import { Form } from 'formsy-semantic-ui-react';
import React, { cloneElement } from 'react';
import { Button, Responsive } from 'semantic-ui-react';
import ErrorLabel from '../error-label/ErrorLabel';

interface QuestionModel {
  id: number;
  text: string;
  answerType: string;
  createdAt: string;
  updatedAt: string;
  questionnaireId: number;
  order: number;
  answer: string;
}

interface Props {
  question: QuestionModel;
  answer: string;
  applied: boolean;
  isValid: any;
  isFormSubmitted: any;
  errorLabel: any;
  disabledQuestion: boolean;
  validationErrors: any;
}

interface State {
  id: number;
  text: string;
  answerType: string;
  createdAt: string;
  updatedAt: string;
  questionnaireId: number;
  order: number;
  answer: string;
}

class SBQuestion extends React.Component<Props, State> {
  public state = {
    id: 0,
    text: '',
    answerType: '',
    createdAt: '',
    updatedAt: '',
    questionnaireId: 0,
    order: 0,
    answer: '',
  };

  public componentDidMount() {
    this.setState({
      id: this.props.question.id,
      text: this.props.question.text,
      answerType: this.props.question.answerType,
      createdAt: this.props.question.createdAt,
      updatedAt: this.props.question.updatedAt,
      questionnaireId: this.props.question.questionnaireId,
      order: this.props.question.order,
      answer: this.props.answer,
    });

    this.props.setValue(undefined);
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.question !== this.props.question) {
      this.setState({
        id: this.props.question.id,
        text: this.props.question.text,
        answerType: this.props.question.answerType,
        createdAt: this.props.question.createdAt,
        updatedAt: this.props.question.updatedAt,
        questionnaireId: this.props.question.questionnaireId,
        order: this.props.question.order,
        answer: this.props.answer,
      });
    }

    if (prevProps.question.order !== this.props.question.order) {
      this.setState({
        order: this.props.question.order,
      });
    }

    if (prevProps.answer !== this.props.answer) {
      this.setState({ answer: this.props.answer });
    }
  }

  public getSnapshotBeforeUpdate(prevProps) {
    if (prevProps.answer !== this.props.answer) {
      if (this.props.answer) {
        this.props.setValue({
          text: this.props.answer,
          questionId: this.props.question.id,
        });
      } else {
        this.props.setValue(undefined);
      }

      return this.props.answer;
    }

    return null;
  }

  public handleValue = (e, data) => {
    const element = {
      text: data.value,
      questionId: this.state.id,
    };
    this.setState({ answer: data.value }, () => this.props.setValue(element));
  };

  public render() {
    const { text, answerType, order, answer } = this.state;
    const { isValid, errorLabel, validationErrors, isFormSubmitted, disabledQuestion } = this.props;
    const error = isFormSubmitted() && !isValid() && answerType !== 'Text';
    const errorLabelNew = <ErrorLabel />;
    return (
      <>
        <Responsive minWidth={926}>
          <div className="question-container standby-question-adjuster">
            <div className="question-order">{order + 1}</div>
            <div
              style={{ overflowWrap: 'break-word' }}
              className={`question-text ${answerType === 'Text' ? 'long-title' : ''}`}
            >
              {text}
            </div>
            {answerType === 'Text' ? (
              <div className="standby-question-input-block">
                <Form.Input
                  name="answer"
                  required={true}
                  disabled={disabledQuestion}
                  validationErrors={{
                    isDefaultRequiredValue: '* Answer is required field.',
                  }}
                  className="standby-question-input"
                  errorLabel={errorLabelNew}
                  onChange={this.handleValue}
                  value={this.state.answer}
                />
              </div>
            ) : (
              <div className="question-type">
                <div className="toggle-block">
                  <Button
                    type="button"
                    className={answer === 'Yes' ? 'yes-btn' : 'no-btn'}
                    disabled={disabledQuestion}
                    onClick={() => {
                      this.setState({ answer: answer === 'Yes' ? '' : 'Yes' }, () => {
                        if (this.state.answer !== '') {
                          const element = {
                            text: this.state.answer,
                            questionId: this.state.id,
                          };
                          this.props.setValue(element);
                        } else {
                          this.props.setValue(undefined);
                        }
                      });
                    }}
                  >
                    YES
                  </Button>
                  <Button
                    type="button"
                    disabled={disabledQuestion}
                    className={answer === 'No' ? 'yes-btn' : 'no-btn'}
                    onClick={() => {
                      this.setState({ answer: answer === 'No' ? '' : 'No' }, () => {
                        if (this.state.answer !== '') {
                          const element = {
                            text: this.state.answer,
                            questionId: this.state.id,
                          };
                          this.props.setValue(element);
                        } else {
                          this.props.setValue(undefined);
                        }
                      });
                    }}
                  >
                    NO
                  </Button>
                </div>
              </div>
            )}
            {error && errorLabel && cloneElement(errorLabel, {}, validationErrors.isChecked)}
          </div>
        </Responsive>

        <Responsive maxWidth={925}>
          <div className="question-container standby-question-adjuster mobile-question">
            <div className="question-order">{order + 1}</div>
            <div className="standby-adjuster-question-body">
              <div className={`question-text`}>{text}</div>
              {answerType === 'Text' ? (
                <div className="standby-question-input-block">
                  <Form.Input
                    name="answer"
                    required={true}
                    disabled={disabledQuestion}
                    validationErrors={{
                      isDefaultRequiredValue: '* Answer is required field.',
                    }}
                    className="standby-question-input"
                    errorLabel={errorLabelNew}
                    onChange={this.handleValue}
                    value={this.state.answer}
                  />
                </div>
              ) : (
                <div className="question-type">
                  <div className="toggle-block">
                    <Button
                      type="button"
                      disabled={disabledQuestion}
                      className={answer === 'Yes' ? 'yes-btn' : 'no-btn'}
                      onClick={() => {
                        this.setState({ answer: answer === 'Yes' ? '' : 'Yes' }, () => {
                          if (this.state.answer !== '') {
                            const element = {
                              text: this.state.answer,
                              questionId: this.state.id,
                            };
                            this.props.setValue(element);
                          } else {
                            this.props.setValue(undefined);
                          }
                        });
                      }}
                    >
                      YES
                    </Button>
                    <Button
                      type="button"
                      disabled={disabledQuestion}
                      className={answer === 'No' ? 'yes-btn' : 'no-btn'}
                      onClick={() => {
                        this.setState({ answer: answer === 'No' ? '' : 'No' }, () => {
                          if (this.state.answer !== '') {
                            const element = {
                              text: this.state.answer,
                              questionId: this.state.id,
                            };
                            this.props.setValue(element);
                          } else {
                            this.props.setValue(undefined);
                          }
                        });
                      }}
                    >
                      NO
                    </Button>
                  </div>
                </div>
              )}
              {error && errorLabel && cloneElement(errorLabel, {}, validationErrors.isChecked)}
            </div>
          </div>
        </Responsive>
      </>
    );
  }
}

export default withFormsy(SBQuestion);
