import '../../assets/styles/Login.css';
import '../../assets/styles/Register.css';
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators, Dispatch } from 'redux';
import { Image, Grid, Header, Button } from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';
import compassLogo from '../../assets/images/compass-logo-vector.svg';
import { accountActions } from '../../features/account';
import { NewUser } from '../../models/newUser';
import services from '../../services';
import { standbyActions } from '../../features/standby';
import ErrorLabel from './../../components/error-label/ErrorLabel';
import StandbyQuestions from '../standby/standbyQuestions';

interface Props {
  errors: string[];
  isLoading: boolean;
  newUser: NewUser;
  progress: number;
  setRedirectStandby: typeof standbyActions.setRedirectStandby;
  standbyListQuickSignUp: typeof accountActions.standbyListQuickSignUp;
}

interface State {
  activeStep: number;
  standbyList: object | null;
  canRegister: boolean;
  quicksignup?: number;
}

const errors = {
  isEmail: 'Please enter a valid email address',
  maxLength: 'You cannot type more than 255 characters',
};

class StandbyListQuickSignUp extends React.Component<Props, State> {
  state = {
    activeStep: 1,
    standbyList: null,
    canRegister: false,
    quicksignup: undefined,
  };

  public componentDidMount = () => {
    this.getStandbyList();

    const query = new URLSearchParams(window.location.search);

    if (query.get('submitted') && query.get('submitted') === 'true') {
      this.setState({ activeStep: 4 });
    } else {
      let quicksignup = query.get('quicksignup');

      if (quicksignup) {
        this.setState({
          activeStep: 3,
          quicksignup: Number(quicksignup),
        });
      }
    }
  };

  public getStandbyList = async () => {
    let response = await services.api.standby.getStandbyListForGuest(
      this.props.match.params.publicId
    );

    if (response.isSuccess) {
      this.setState({ standbyList: response.data }, () => {
        if (services.localStorage.get('accessToken') && this.state.standbyList) {
          const url = services.router.url.standby_questions.replace(
            ':id',
            this.state.standbyList.id
          );
          services.router.goto(url);
        }
      });
    } else {
      toast.error(`This standby list is no longer available.`);
      setTimeout(() => {
        services.router.goto(services.router.url.login);
      }, 2000);
    }
  };

  public toggleRegisterBtn = (canRegister: boolean) => {
    this.setState({ canRegister });
  };

  public handleRedirectToLogin = () => {
    if (!this.state.standbyList) return;

    this.props.setRedirectStandby(`/stand-by-lists/${this.state.standbyList.id}`);

    services.router.goto('/login');
  };

  public handleQuickSignUp = async () => {
    this.setState({ activeStep: 2 });
  };

  public handleSubmit = async (model: object) => {
    this.props.standbyListQuickSignUp({
      ...model,
      publicId: this.props.match.params.publicId,
    });
  };

  public answerSaved = () => {
    const url = window.location.pathname + `?submitted=true`;
    services.router.goto(url);
    this.setState({ activeStep: 4 });
  };

  public cancelSignup = () => {
    this.setState({ activeStep: 1 });
  };

  public renderStep1 = () => {
    return (
      <div>
        <div className="logo-container mb-5">
          <Image src={compassLogo} />
          <h3>Login or quick signup to start answering this standby list</h3>
        </div>
        <Grid
          centered={true}
          style={{ height: '100%' }}
          verticalAlign="middle"
          className="register-email-grid"
        >
          <Grid.Column style={{ maxWidth: 300 }}>
            <div className="login-form-container">
              <div className="flex justify-center items-center">
                <Button color="blue" onClick={this.handleRedirectToLogin}>
                  Login To Continue
                </Button>
              </div>

              <Header as="h4" icon={true} textAlign="center" style={{ color: '#535D6C' }}>
                <Header.Content>Or Register in a min</Header.Content>
              </Header>

              <div className="flex justify-center items-center">
                <Button color="teal" onClick={this.handleQuickSignUp}>
                  Quick Sign Up
                </Button>
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    );
  };

  public renderStep2 = () => {
    const { canRegister } = this.state;
    const errorLabel = <ErrorLabel />;

    return (
      <Grid className="register-grid">
        <Grid.Row className="header-row">
          <div className="logo-container mb-5">
            <Image src={compassLogo} />
            <h3>Register to join Standby List</h3>
          </div>
        </Grid.Row>
        <Grid.Row>
          <Form
            onValidSubmit={this.handleSubmit}
            onValid={() => this.toggleRegisterBtn(true)}
            onInvalid={() => this.toggleRegisterBtn(false)}
            style={{ width: '100%' }}
          >
            <Grid stackable={true} className="details-grid">
              <Grid.Row columns="equal">
                <Grid.Column>
                  <div className="input-field-container">
                    <div className="input-field-label">First Name</div>
                    <Form.Input
                      name="firstName"
                      type={'text'}
                      placeholder="Please Enter First Name"
                      required={true}
                      errorLabel={errorLabel}
                      validationErrors={{
                        isDefaultRequiredValue: `* First Name is required field`,
                        ...errors,
                      }}
                      validations={{
                        maxLength: 255,
                      }}
                      className={'form-input'}
                      value=""
                    />
                  </div>

                  <div className="input-field-container">
                    <div className="input-field-label">Middle Name</div>
                    <Form.Input
                      name="middleName"
                      type={'text'}
                      placeholder="Please Enter Middle Name"
                      required={false}
                      errorLabel={errorLabel}
                      validationErrors={{
                        isDefaultRequiredValue: `* Middle Name is required field`,
                        ...errors,
                      }}
                      validations={{
                        maxLength: 255,
                      }}
                      className={'form-input'}
                      value=""
                    />
                  </div>

                  <div className="input-field-container">
                    <div className="input-field-label">Last Name</div>
                    <Form.Input
                      name="lastName"
                      type={'text'}
                      placeholder="Please Enter Last Name"
                      required={true}
                      errorLabel={errorLabel}
                      validationErrors={{
                        isDefaultRequiredValue: `* Last Name is required field`,
                        ...errors,
                      }}
                      validations={{
                        maxLength: 255,
                      }}
                      className={'form-input'}
                      value=""
                    />
                  </div>

                  <div className="input-field-container">
                    <div className="input-field-label">Email</div>
                    <Form.Input
                      name="email"
                      type={'text'}
                      placeholder="Please Enter Email"
                      required={true}
                      errorLabel={errorLabel}
                      validationErrors={{
                        isDefaultRequiredValue: `*  Email is required field`,
                        ...errors,
                      }}
                      validations={{
                        isEmail: true,
                        maxLength: 255,
                      }}
                      className={'form-input'}
                      value=""
                    />
                  </div>

                  <div className="input-field-container">
                    <div className="input-field-label">Phone (Mobile)</div>
                    <Form.Input
                      name="phone"
                      type={'text'}
                      placeholder="Please Enter Phone"
                      required={true}
                      errorLabel={errorLabel}
                      validationErrors={{
                        isDefaultRequiredValue: `* Phone is required field`,
                        ...errors,
                      }}
                      validations={{
                        maxLength: 255,
                      }}
                      className={'form-input'}
                      value=""
                    />
                  </div>

                  <div className="input-field-container">
                    <div className="input-field-label">Address</div>
                    <div className="flex flex-grow">
                      <Form.Input
                        name="address"
                        type={'text'}
                        placeholder="Please Enter Address"
                        required={true}
                        errorLabel={errorLabel}
                        validationErrors={{
                          isDefaultRequiredValue: `* Address is required field`,
                          ...errors,
                        }}
                        validations={{
                          maxLength: 255,
                        }}
                        className={'form-input flex-grow min-width-unset'}
                        value=""
                      />
                    </div>
                  </div>

                  <div className="input-field-container">
                    <div className="input-field-label">Location</div>
                    <div className="flex flex-grow">
                      <Form.Input
                        name="city"
                        type={'text'}
                        placeholder="City"
                        required={true}
                        errorLabel={errorLabel}
                        validationErrors={{
                          isDefaultRequiredValue: `* City is required field`,
                          ...errors,
                        }}
                        validations={{
                          maxLength: 255,
                        }}
                        className={'form-input flex-grow min-width-unset mr-2'}
                        value=""
                      />

                      <Form.Input
                        name="state"
                        type={'text'}
                        placeholder="State"
                        required={true}
                        errorLabel={errorLabel}
                        validationErrors={{
                          isDefaultRequiredValue: `* State is required field`,
                          ...errors,
                        }}
                        validations={{
                          maxLength: 255,
                        }}
                        className={'form-input flex-grow min-width-unset'}
                        value=""
                      />
                    </div>
                  </div>

                  <div className="input-field-container justify-between mt-5">
                    <Button
                      color="red"
                      type="button"
                      onClick={this.cancelSignup}
                      formNoValidate={true}
                      className="step-button"
                    >
                      Cancel
                    </Button>

                    <Button
                      primary={true}
                      type="submit"
                      formNoValidate={true}
                      // disabled={!canRegister}
                      className="step-button"
                    >
                      Register
                    </Button>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Grid.Row>
      </Grid>
    );
  };

  public renderStep3 = () => {
    const showQuestions = this.state.standbyList && this.state.quicksignup;

    return showQuestions ? (
      <div className="guest-standby-list-page mt-5">
        <StandbyQuestions
          {...this.props}
          answerSaved={this.answerSaved}
          standbyListId={this.state.standbyList.id}
          quicksignup={this.state.quicksignup}
        />
      </div>
    ) : (
      ''
    );
  };

  public renderStep4 = () => {
    return (
      <div>
        <div className="logo-container">
          <Image src={compassLogo} />
          <h1>Your answers have been submitted</h1>
        </div>
      </div>
    );
  };

  public render() {
    const { activeStep } = this.state;

    switch (activeStep) {
      case 1:
        return this.renderStep1();
      case 2:
        return this.renderStep2();
      case 3:
        return this.renderStep3();
      case 4:
        return this.renderStep4();
    }
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  errors: state.account.register.errors,
  isLoading: state.loading.loading.isVisible,
  newUser: state.account.register.newUser,
  progress: state.account.register.progress,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setRedirectStandby: standbyActions.setRedirectStandby,
      standbyListQuickSignUp: accountActions.standbyListQuickSignUp,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StandbyListQuickSignUp);
